import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import URI from 'src/helper/uri';

export function usePermissions() {
  const [permission, setPermission] = useState<any>([]);
  const { user } = useSelector((state: RootState) => state.auth);

  const permissionData = async () => {
    try {
      const response = await axios.get(`${URI}/permissions/${user?.permissionId}`);

      
      setPermission(response.data.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.permissionId) {
      permissionData();
    }
  }, [user]);

  return permission;
}
