// Imports organized and grouped
import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import CountryPicker from 'src/components/shared/CountryPicker';
import Modal from 'src/components/shared/Modal';
import SmallLoader from 'src/components/shared/SmallLoader';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  TooltipProps
} from 'recharts';
import { getDatabase } from 'firebase/database';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { usePermissions } from 'src/hooks/usePeermissions';

// Utility function for date formatting
const formatDate = (timestampObj) => {
  const date = new Date(timestampObj._seconds * 1000);
  const day = date.getDate();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  return `${day} ${month} ${year}}`;
};
const formatTime = (timestampObj) => {
  const date = new Date(timestampObj._seconds * 1000);
  const day = date.getDate();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
};

// Extracted card component

interface NotificationStatisticsProps {
  data: any;

  title: string;
  color?: string;
  type?: 'bar' | 'line';
  view: boolean;
  onclose: any;
}

function NotificationStatistics({
  data,
  title,
  view,
  onclose,
  color
}: NotificationStatisticsProps) {
  const { width, height } = useWindowDimensions();
  const [isModal, setIsModal] = useState(view);
  const viewsValue = (data?.views?.Android || 0) + (data?.views?.iOS || 0);
  const clicksValue = (data?.clicks?.Android || 0) + (data?.clicks?.iOS || 0);
  const ctrValue = (data?.CTR?.Android?.count || 0) + (data?.CTR?.iOS?.count || 0);
  const CTA = (clicksValue / viewsValue) * 100; // تحسب نسبة الـ CTA بالنسبة المئوية
  const CTR = (ctrValue / clicksValue) * 100;
  const allData = [
    { name: 'Views', value: viewsValue },
    { name: 'Clicks', value: clicksValue },
    { name: 'CTA', value: CTA },
    { name: 'CTR', value: CTR }
  ];

  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
    allData: { name: string; value: number }[];
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, allData }) => {
    if (active && payload && payload.length) {
      const allData = payload[0].payload;
      return (
        <div
          className="p-5 bg-[#e5e1ec]  rounded-[10px]"
          style={{ boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)' }}
        >
          <p className="flex items-center justify-center gap-1 text-[16px] font-semibold">
            <span
              className="w-[8px] h-[8px] !rounded-full"
              style={{ backgroundColor: color, display: 'inline-block' }}
            ></span>
            {`${allData.name}: ${allData.value.toFixed(2)}${
              allData.name === 'CTR' || allData.name === 'CTA' ? '%' : ''
            }`}
          </p>

          {/* <p className="label text-[#656565]">{`${data.date}`}</p> */}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isModal ? (
        <div
          className="absolute top-0 right-0 bg-white z-20 flex flex-col items-start justify-center pt-4 pb-6 px-5 gap-4"
          style={{
            width: `${Responsive.w(386, width)}px`,
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
          }}
        >
          <div className="flex items-center justify-between w-full">
            <h1
              className="text-black "
              style={{
                fontSize: `${Responsive.sp(18, width)}px`
              }}
            >
              {title}
            </h1>
            <span
              onClick={() => onclose()}
              className="cursor-pointer"
            >
              <Icon
                icon="material-symbols:close"
                width={24}
                height={24}
                color={color}
              />
            </span>
          </div>

          <div className="flex justify-center w-full items-start">
            <ResponsiveContainer
              width="100%"
              height={300}
            >
              <BarChart
                data={allData}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5
                }}
              >
                <XAxis dataKey="name" />
                <YAxis dataKey="value" />
                <Tooltip content={<CustomTooltip allData={allData} />} />
                <Bar
                  dataKey="value"
                  barSize={20}
                  fill={color}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

// Extracted button component
const LoadMoreButton = ({ isLoading, onClick, isDisabled }) => (
  <div className="flex justify-center w-full">
    {isLoading ? (
      <SmallLoader />
    ) : (
      <button
        onClick={onClick}
        disabled={isDisabled}
        className="px-4 py-2 mt-4 bg-primary text-white rounded hover:bg-slate-600"
      >
        {useIntl().formatMessage({
          id: 'more',
          defaultMessage: 'more'
        })}
      </button>
    )}
  </div>
);

// Notification component
const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]);
  const [lastDocId, setLastDocId] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState('allNotifications');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const [isModalShow, setIsModalShow] = useState(true);
  const [isModalIndex, setIsModalIndex] = useState<any>();
  const [currentTab, setCurrentTab] = useState<string>('customize');
  const [count, setCount] = useState();
  const [pagination, setPagination] = useState<any>([]);
  const [perPage, setPerPage] = useState(15);
  const [sortValue, setSortValue] = useState<any>('');
  const [value, setValue] = useState(true);
  const [sortKey, setSortKey] = useState<any>('');
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { user } = useSelector((state: RootState) => state.auth);
  const Permission = usePermissions();
  const [userLoading, setUserLoading] = useState(false);
  const [userLang, setUserLang] = useState('');
  const keys = [
    'Notification Description',
    'Title',
    'Send to',
    'Language',
    'Selected Country',
    'Notification statistics',
    'Time',
    'Date'
  ];
  const [customizedKeys, setCustomizedKeys] = useState(keys);
  useEffect(() => {
    setUserLoading(true);
    if (user.Language) {
      setUserLang((prev) => {
        return user.Language.map((lang) => {
          return lang === 'English'
            ? 'en'
            : lang === 'Arabic'
            ? 'ar'
            : lang === 'French'
            ? 'fr'
            : 'ar';
        });
      });
    }
    setUserLoading(false);
  }, [user]);

  console.log('userLang', userLang);

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    }
    // { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const fetchNotifications = async (page = 1, sortValue, sortKey) => {
    setIsLoadingMore(true);
    try {
      const response = await axiosInstance.get(`/notifications`, {
        params: {
          page: page, // Start from the first page
          perPage: perPage,
          sortValue: sortValue || '',
          sortKey: sortKey || '', // Set the page size
          userLang: userLang
        }
      });
      setNotifications(() => response.data.result?.data);
      setPagination(response.data.result?.pagination);
      setCount(response.data.result.count);
      console.log(response.data.result);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
    setIsLoadingMore(false);
  };
  useEffect(() => {
    if (userLang.length > 0 && !userLoading) {
      fetchNotifications(pagination.currentPage, sortValue, sortKey);
    }
  }, [userLang,sortKey, sortValue]);

  // useEffect(() => {
  //   if (sortKey || sortValue) {
  //     fetchNotifications(pagination.currentPage, sortValue, sortKey);
  //   }
  // }, [sortKey, sortValue]);

  const onNextClick = () => {
    fetchNotifications((pagination.currentPage || 1) + 1, sortValue, sortKey);
  };

  const onPreviousClick = () => {
    fetchNotifications(pagination.currentPage - 1, sortValue, sortKey);
  };

  const applySorting = (key: string) => {
    setSortValue(value === true ? 'asc' : 'desc');
    setValue(!value);
    setSortKey(key);
  };
  const allNotificationsMessage = 'allNotifications';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);
  const resetValues = () => {
    setSortKey('');
    setSortValue('');
    setStart(undefined);
    setEnd(undefined);
  };
  const handleDateOptionClick = async (option, title) => {
    setIsLoadingMore(true);
    try {
      setTitle(title);
      if (option === 'All notifications') {
        fetchNotifications(1, '', '');
        resetValues();
        return; // Exit the function early
      }

      const currentDate = new Date();
      let startDate, endDate;

      switch (option) {
        case 'Today':
          startDate = new Date(currentDate);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(currentDate);
          // endDate.setHours(23, 59, 59, 999);
          break;
        case 'Last 7 days':
          startDate = new Date(currentDate);
          startDate.setDate(startDate.getDate() - 7);
          endDate = new Date(currentDate);
          break;
        case 'Last 30 days':
          startDate = new Date(currentDate);
          startDate.setDate(startDate.getDate() - 30);
          endDate = new Date(currentDate);
          break;

        case 'Date range':
          if (start && end) {
            startDate = new Date(start);
            endDate = new Date(end);
          }
          break;

        default:
          startDate = null;
          endDate = null;
          break;
      }

      const response = await axiosInstance.get(`/notifications`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          page: 1, // Start from the first page
          pageSize: perPage,
          sortValue: sortValue || '',
          sortKey: sortKey || '',
          userLang: userLang
        }
      });

      setNotifications(response.data.result.data);
      setPagination(response.data.result?.pagination);
      setCount(response.data.result.count);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoadingMore(false);

    // Apply date filtering if startDate and endDate are defined
  };
  useEffect(() => {
    fetchNotifications(1, '', '');
    console.log('notifications===========', notifications);
  }, []);
  const ChangeCustomizeKey = (isChecked, field) => {
    setCustomizedKeys((prevCustomizedKeys) => {
      if (isChecked) {
        // إذا تم التحقق من الخانة، نحتاج إلى إضافة الحقل إلى القائمة المخصصة في المكان المناسب
        const index = keys.indexOf(field); // العثور على موضع الحقل في keys
        const newIndex = prevCustomizedKeys.findIndex((key) => key === field); // التحقق مما إذا كان الحقل موجودًا بالفعل في customizedKeys
        if (newIndex === -1) {
          // إذا لم يتم العثور على الحقل في القائمة المخصصة، فقط قم بإضافته في المكان الصحيح
          return [...prevCustomizedKeys.slice(0, index), field, ...prevCustomizedKeys.slice(index)];
        } else {
          // إذا وجدنا الحقل بالفعل في القائمة المخصصة، فقم بإعادة ترتيبه إلى الموضع الصحيح
          return [
            ...prevCustomizedKeys.slice(0, index),
            ...prevCustomizedKeys.slice(newIndex, newIndex + 1), // النقطة التي تحتوي على الحقل الحالي
            ...prevCustomizedKeys.slice(index, newIndex),
            ...prevCustomizedKeys.slice(newIndex + 1)
          ];
        }
      } else {
        // إذا تم إلغاء التحقق من الخانة، قم بإزالة الحقل من القائمة المخصصة
        return prevCustomizedKeys.filter((key) => key !== field);
      }
    });
  };

  const handleViewModal = () => {
    setIsModal(true);
  };
  const handleCloseModal = () => {
    setIsModal(false);
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const hasEditOrDeletePermission = (item) => {
    return item?.permission?.includes('Edit') || item?.permission?.includes('Delete');
  };
  const hasDeletePermission = (item) => {
    return item?.permission?.includes('Delete');
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  return (
    <div className="flex flex-col space-y-4 items-center justify-center w-full">
      <Table
        loading={isLoadingMore}
        isEmpty={!notifications.length}
        searchAble={false}
        count={count}
        title={title}
        buttonAble={false}
        notificationAble={Permission.some((item) => hasEditPermission(item))}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-40"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard === 'dream'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : currentDashboard === 'nikah'
                                      ? '!text-[#cf0036] border-b-[#cf0036]'
                                      : '!text-[#7b6c96] border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customizedKeys.includes(field)}
                                onChange={(e) => ChangeCustomizeKey(e.target.checked, field)}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                <FormattedMessage
                                  id={`${field.replaceAll(' ', '')}`}
                                  defaultMessage={`${field}`}
                                />
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All notifications', allNotificationsMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allNotificationsMessage}
                      defaultMessage={allNotificationsMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff]"
              style={{ height: `${Responsive.h(24, height)}px`, backgroundColor: dashColor }}
            >
              #
            </th>
            {customizedKeys.map((field, index) => (
              <th
                key={index}
                className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor,
                  display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                }}
                data-label={field}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    justifyContent: 'center',
                    flexDirection: 'row-reverse'
                  }}
                >
                  <FormattedMessage
                    id={`${field.replaceAll(' ', '')}`}
                    defaultMessage={`${field}`}
                  />

                  {field === 'Date' && (
                    <button
                      className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                      style={{
                        backgroundColor: dashColor
                      }}
                      onClick={() => {
                        applySorting('notificationTimestamp');
                      }}
                      type="button"
                    >
                      <Icon
                        icon="fa6-solid:sort"
                        width="12"
                        height="12"
                        className="text-gray-600"
                      />
                    </button>
                  )}
                </div>
              </th>
            ))}
          </>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {notifications.map((notification: any, index) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
                className="hover:bg-[#EAE0EB]"
              >
                <td
                  className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch"
                  data-label="#"
                >
                  {index + 1}
                </td>
                {customizedKeys.map((field, fieldIndex) => (
                  <td
                    key={fieldIndex}
                    className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch"
                    data-label={field}
                    style={{
                      display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                    }}
                  >
                    {field === 'Notification Description' && notification.body}
                    {field === 'Title' && notification.title}
                    {field === 'Send to' && notification.notificationType}
                    {field === 'Language' && notification.notificationLang}
                    {field === 'Selected Country' && (notification.notificationCountry || 'N/A')}
                    {field === 'Notification statistics' && (
                      <div className="relative">
                        <button
                          onClick={() => {
                            setIsModalIndex(index);
                            handleViewModal();
                          }}
                        >
                          <p
                            className="flex items-center py-1 font-semibold px-4 gap-1 rounded-1 justify-center border border-solid border-[#7b6c96]  bg-[#f5f0ff]"
                            data-label="Notification Title"
                            style={{
                              color: dashColor,
                              borderColor: dashColor
                            }}
                          >
                            {useIntl().formatMessage({
                              id: 'view',
                              defaultMessage: 'view'
                            })}
                          </p>
                        </button>

                        {isModalIndex === index && (
                          <NotificationStatistics
                            data={notification}
                            title="NotificationStatistics"
                            view={isModal}
                            onclose={handleCloseModal}
                            color={dashColor}
                          />
                        )}
                      </div>
                    )}
                    {field === 'Time' && formatTime(notification.notificationTimestamp)}
                    {field === 'Date' && formatDate(notification.notificationTimestamp)}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
        pagination={{ ...pagination }}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return setPerPage(e.target?.value);
        }}
        onNextClick={() => {
          onNextClick();
        }}
        onPreviousClick={() => {
          onPreviousClick();
        }}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        onPromoClick={() => setIsModalOpen(true)}
      />
      <NotificationForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

const NotificationForm = ({ isModalOpen, setIsModalOpen }) => {
  // State for the form fields
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [image, setImage] = useState('');
const [loading , setLoading] = useState(false)
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [language, setLanguage] = useState('');
  const [country, setCountry] = useState('');
  const [selectUser, setSelectUser] = useState('');
  const [specificScreen, setSpecificScreen] = useState('');
  const [consultId, setConsultId] = useState('');
  const [type, setType] = useState('');
  const [link, setLink] = useState('');
  const { width, height } = useWindowDimensions();
  const [isloading, setIsloading] = useState(false);
  const [users, setUsers] = useState([]);
  // State for validation messages
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [selectUserError, setSelectUserError] = useState('');
  const [sendToError, setSendToError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [specificScreenError, setSpecificScreenError] = useState('');
  const [consultIdError, setConsultIdError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [countryError, setCountryError] = useState('');

  const fileInputRef = useRef<any>(null);
  const intl = useIntl();
  console.log('consultId', consultId);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let initialQueries: any = [
    ['userType', '==', 'CONSULTANT'],
    ['accountStatus', '==', 'Active']
  ];
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleCountryChange = (selectedCountry) => {
    // setCountry(selectedCountry ? `${selectedCountry.dial_code} - ${selectedCountry.name}` : '');
    setCountry(selectedCountry ? `${selectedCountry.dial_code}` : '');
  };

  const resetValues = () => {
    setTitle('');
    setDescription('');
    setSendTo('');
    setCountry('');
    setSelectUser('');
    setLanguage('');
    setLink('');
    setImage('');
    setTitleError('');
    setDescriptionError('');
    setSendToError('');
    setLanguageError('');
    setCountryError('');
    setType('');
    setSpecificScreen('');
    setConsultId('');
  };

  const handleClose = () => {
    setIsModalOpen(false);
    resetValues();
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const validateForm = () => {
    let isValid = true;
    if (!title) {
      const errMsg = intl.formatMessage({
        id: 'titleRequired',
        defaultMessage: 'Title is required'
      });
      setTitleError(errMsg);
      isValid = false;
    } else {
      setTitleError('');
    }

    if (!description) {
      const errMsg = intl.formatMessage({
        id: 'descriptionIsRequired',
        defaultMessage: 'Description is required'
      });
      setDescriptionError(errMsg);
      isValid = false;
    } else {
      setDescriptionError('');
    }

    if (!sendTo) {
      const errMsg = intl.formatMessage({
        id: 'RecipientIsRequired',
        defaultMessage: 'Recipient is required'
      });
      setSendToError(errMsg);
      isValid = false;
    } else {
      setSendToError('');
    }
    if (sendTo === 'selectUser' && !selectUser) {
      const errMsg = intl.formatMessage({
        id: 'SelectUserIsRequired',
        defaultMessage: 'Select User is required'
      });
      setSelectUserError(errMsg);
      isValid = false;
    } else {
      setSelectUserError('');
    }

    if (!language) {
      const errMsg = intl.formatMessage({
        id: 'languageIsRequired',
        defaultMessage: 'Language is required'
      });
      setLanguageError(errMsg);
      isValid = false;
    } else {
      setLanguageError('');
    }

    if (type === 'Screen' && !specificScreen) {
      const errMsg = intl.formatMessage({
        id: 'specificScreenIsRequired',
        defaultMessage: 'specificScreen is required'
      });
      setSpecificScreenError(errMsg);
      isValid = false;
    } else {
      setSpecificScreenError('');
    }
    if (type === 'Consultant' && !consultId) {
      const errMsg = intl.formatMessage({
        id: 'consult IsRequired',
        defaultMessage: 'consult is required'
      });
      setConsultIdError(errMsg);
      isValid = false;
    } else {
      setConsultIdError('');
    }
    if (!type) {
      const errMsg = intl.formatMessage({
        id: 'typeIsRequired',
        defaultMessage: 'Type is required'
      });
      setTypeError(errMsg);
      isValid = false;
    } else {
      setTypeError('');
    }

    // if (!country) {
    //   setCountryError('Country is required');
    //   isValid = false;
    // } else {
    //   setCountryError('');
    // }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const confirmationMsg = intl.formatMessage({
      id: 'sendNotificationConfirmation',
      defaultMessage: 'Are you sure you want to send notification to all users?'
    });
    showConfirmation(confirmationMsg, async () => {
      let uploadedImageUrl = image;
      try {
        setLoading(true)
        // uploading image to storage
        if (selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile);

          const response = await axiosInstance.post('/uploadNotificationImg', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          uploadedImageUrl = response.data.result.data.imgUrl || 'noImage';
          setImage(uploadedImageUrl);
        }

        const reqBody = {
          title: title,
          body: description,
          imageUrl: uploadedImageUrl || 'noImage',
          link: link || 'noLink',
          notificationCountry: country,
          notificationLang: language,
          notificationType: sendTo,
          consultId: consultId,
          SpecificScreen: specificScreen,
          type: type,
          selectUser: selectUser
        };
        //submitting patch data to doc in firestore
        let notificationResponse;
        console.log('body', reqBody);
        // Adding a new noti
        notificationResponse = await axiosInstance.post('/notifications/', reqBody);

        //CAUTION: THIS SENDS NOTIFICATION TO ALL USERS!!
        await axiosInstance.post('/sendNewNotification/', reqBody);

        console.log('patch res', notificationResponse);
        setIsModalOpen(false);
        resetValues();

        const successMsg = intl.formatMessage({
          id: 'notificationSent',
          defaultMessage: 'Notification sent successfully.'
        });

        toast.success(successMsg);
        setLoading(false)
      } catch (error) {
        console.error('There is an error!:', error);
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });
  };

  const handleRestoreClick = (event: any) => {
    event.preventDefault();
    setImage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const showConfirmation = useConfirmationDialog();

  async function getUsers() {
    try {
      setIsloading(true);
      const res = await axiosInstance.get(`/consultants`, {
        params: {
          queries: initialQueries
        }
      });
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('users not found');
    }
    setIsloading(false);
  }
  useEffect(() => {
    if (type) {
      if (type === 'Consultant') {
        setSpecificScreen('');
      } else if (type === 'Screen') {
        setConsultId('');
      } else if (type === 'link') {
        setSpecificScreen('');
        setConsultId('');
      }
    }
  }, [type]);
  useEffect(() => {
    getUsers();
  }, []);

  const hasEditOrDeletePermission = (item) => {
    return item?.permission?.includes('Edit') || item?.permission?.includes('Delete');
  };
  const hasDeletePermission = (item) => {
    return item?.permission?.includes('Delete');
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  return (
    <Modal
      visible={isModalOpen}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'sendNotifications',
        defaultMessage: 'Send notifications'
      })}
    >
      <form
        className="flex flex-col space-y-4 items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-col space-y-4 w-full">
        {loading && <Spinner/> }
          {/* Image Upload */}
          <div
            className="flex flex-row justify-between items-center p-0 flex-grow-0 "
            style={{
              width: `${Responsive.w(748, width)}px`,
              height: `${Responsive.h(63, height)}px`
            }}
          >
         
            <label
              htmlFor="file-upload"
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(175, width)}px`,
                height: `${Responsive.h(31, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`,
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'uploadImage',
                defaultMessage: 'Upload Image'
              })}
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="flex items-center  text-[#fff] text-left outline-none justify-center gap-[10px] flex-grow-0 p-[10px]  rounded-[10px]"
              style={{
                width: `${Responsive.w(220, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              ref={fileInputRef}
            />
            {image && image != 'noImage' && (
              <>
                <img
                  src={image}
                  alt="Banner"
                  width={700}
                  height={350}
                  id="imagePreview"
                  className="w-120 h-40 object-stretch mt-2"
                />
                <button
                  type="button"
                  className=" text-white font-semibold mt-2 hover:bg-slate-600 hover:cursor-pointer  p-2 text-center bg-blue-950 rounded"
                  onClick={handleRestoreClick}
                >
                  {intl.formatMessage({
                    id: 'removeImage',
                    defaultMessage: 'Remove image'
                  })}
                </button>
              </>
            )}
          </div>

          {/* Title */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'title',
                defaultMessage: 'title'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={useIntl().formatMessage({
                id: 'titleOfNotification',
                defaultMessage: 'Enter title of notification'
              })}
            />
          </div>
          {!title && titleError && <p className="text-red-500">{titleError}</p>}

          {/* Description */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'description',
                defaultMessage: 'description'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter notification description"
            />
          </div>
          {!description && descriptionError && <p className="text-red-500">{descriptionError}</p>}

          {/* Send To */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'sendTo',
                defaultMessage: 'send to'
              })}
            </label>
            <select
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              value={sendTo}
              onChange={(e) => setSendTo(e.target.value)}
            >
              <option value="">
                {useIntl().formatMessage({
                  id: 'selectRecipient',
                  defaultMessage: 'Select recipient'
                })}
              </option>
              <option value="CONSULTANT">
                {useIntl().formatMessage({
                  id: 'consultants',
                  defaultMessage: 'consultants'
                })}
              </option>
              <option value="USER">
                {useIntl().formatMessage({
                  id: 'clients',
                  defaultMessage: 'clients'
                })}
              </option>
              <option value="SUPPORT">
                {useIntl().formatMessage({
                  id: 'technicalSupport',
                  defaultMessage: 'technical support'
                })}
              </option>
              {
                <option value="selectUser">
                  {useIntl().formatMessage({
                    id: 'selectUser',
                    defaultMessage: 'select user'
                  })}
                </option>
              }
            </select>
          </div>
          {!sendTo && sendToError && <p className="text-red-500">{sendToError}</p>}
          {/* select User */}

          {sendTo === 'selectUser' && (
            <div className="flex justify-between items-center">
              <label
                className="w-1/3  font-[Montserrat] font-semibold text-left"
                style={{
                  color: dashColor
                }}
              >
                {intl.formatMessage({
                  id: 'selectUser',
                  defaultMessage: 'select User'
                })}
              </label>
              <input
                className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  borderRadius: `${Responsive.sp(8, width)}px`
                  // height: `${Responsive.h(44, height)}px`
                }}
                value={selectUser}
                onChange={(e) => setSelectUser(e.target.value)}
                placeholder={useIntl().formatMessage({
                  id: 'selectUserNotification',
                  defaultMessage: 'Enter uid or Phone number'
                })}
              />
            </div>
          )}
          {!selectUser && sendTo === 'selectUser' && selectUserError && (
            <p className="text-red-500">{selectUserError}</p>
          )}
          {/* Language */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'selectLanguage',
                defaultMessage: 'select language'
              })}
            </label>
            <select
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="">
                {useIntl().formatMessage({
                  id: 'selectLanguage',
                  defaultMessage: 'Select language'
                })}
              </option>
              <option value="en">
                {useIntl().formatMessage({
                  id: 'en',
                  defaultMessage: 'English'
                })}
              </option>
              <option value="ar">
                {useIntl().formatMessage({
                  id: 'ar',
                  defaultMessage: 'العربية'
                })}
              </option>
              <option value="fr">
                {useIntl().formatMessage({
                  id: 'fr',
                  defaultMessage: 'français'
                })}
              </option>
              <option value="id">
                {useIntl().formatMessage({
                  id: 'id',
                  defaultMessage: 'bahasa Indonesiacode'
                })}
              </option>
            </select>
          </div>
          {!language && languageError && <p className="text-red-500">{languageError}</p>}
          {/* type */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'selectType',
                defaultMessage: 'select Type'
              })}
            </label>
            <select
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
                // height: `${Responsive.h(44, height)}px`
              }}
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">
                {useIntl().formatMessage({
                  id: 'selectType',
                  defaultMessage: 'Select type'
                })}
              </option>
              <option value="Consultant">
                {useIntl().formatMessage({
                  id: 'consultant',
                  defaultMessage: 'Consultant'
                })}
              </option>
              <option value="Screen">
                {useIntl().formatMessage({
                  id: 'screen',
                  defaultMessage: 'Screen'
                })}
              </option>
              <option value="link">
                {useIntl().formatMessage({
                  id: 'link',
                  defaultMessage: 'link'
                })}
              </option>
            </select>
          </div>
          {!type && typeError && <p className="text-red-500">{typeError}</p>}
          {/* Consultant */}
          {type === 'Consultant' && (
            <div className="flex justify-between items-center">
              <label
                className="w-1/3  font-[Montserrat] font-semibold text-left"
                style={{
                  color: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'selectConsultant',
                  defaultMessage: 'select Consultant'
                })}
              </label>
              <select
                className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  borderRadius: `${Responsive.sp(8, width)}px`
                  // height: `${Responsive.h(44, height)}px`
                }}
                value={consultId}
                onChange={(e) => setConsultId(e.target.value)}
              >
                <option value="">
                  {useIntl().formatMessage({
                    id: 'selectConsultant',
                    defaultMessage: 'select Consultant'
                  })}
                </option>
                {users.map((user: any, index) => {
                  return (
                    <option
                      key={index}
                      value={user.uid}
                    >
                      {user.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {type === 'Consultant' && !consultId && consultIdError && (
            <p className="text-red-500">{consultIdError}</p>
          )}

          {/* Screen */}
          {type === 'Screen' && (
            <div className="flex justify-between items-center">
              <label
                className="w-1/3  font-[Montserrat] font-semibold text-left"
                style={{
                  color: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'selectScreen',
                  defaultMessage: 'select Screen'
                })}
              </label>
              <select
                className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  borderRadius: `${Responsive.sp(8, width)}px`
                  // height: `${Responsive.h(44, height)}px`
                }}
                value={specificScreen}
                onChange={(e) => setSpecificScreen(e.target.value)}
              >
                <option value="">
                  {' '}
                  {useIntl().formatMessage({
                    id: 'selectScreen',
                    defaultMessage: 'select Screen'
                  })}
                </option>
                <option value="home">
                  {useIntl().formatMessage({
                    id: 'home',
                    defaultMessage: 'home'
                  })}
                </option>
                <option value="support">
                  {useIntl().formatMessage({
                    id: 'support',
                    defaultMessage: 'support'
                  })}
                </option>
                <option value="account">
                  {useIntl().formatMessage({
                    id: 'account',
                    defaultMessage: 'account'
                  })}
                </option>
                <option value="appointment">
                  {useIntl().formatMessage({
                    id: 'appointment',
                    defaultMessage: 'appointment'
                  })}
                </option>
              </select>
            </div>
          )}
          {type === 'Screen' && !specificScreen && specificScreenError && (
            <p className="text-red-500">{specificScreenError}</p>
          )}
          {/* Link */}
          {type === 'link' && (
            <div className="flex justify-between items-center">
              <label
                className="w-1/3  font-[Montserrat] font-semibold text-left"
                style={{
                  color: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'link',
                  defaultMessage: 'link'
                })}
              </label>
              <input
                className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  borderRadius: `${Responsive.sp(8, width)}px`
                  // height: `${Responsive.h(44, height)}px`
                }}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder={intl.formatMessage({
                  id: 'enterLink',
                  defaultMessage: 'enter link (optional)'
                })}
              />
            </div>
          )}
          {/* Country Picker */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'selectCountry',
                defaultMessage: 'select country'
              })}
            </label>
            <div className="w-2/3">
              <CountryPicker onCountryChange={handleCountryChange} />
            </div>
          </div>
          {!country && countryError && <p className="text-red-500">{countryError}</p>}

          {/* Submit Button */}
          <button
            type="submit"
            className="px-4 py-2 mt-4  text-white rounded "
            style={{
              backgroundColor: dashColor
            }}
          >
            {useIntl().formatMessage({
              id: 'sendNotification',
              defaultMessage: 'Send Notification'
            })}
          </button>
        </div>
      </form>
    </Modal>
  );
};

// Main component
export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="flex-col min-h-screen p-12 items-center justify-center ">
      <NotificationComponent />
    </div>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
