import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from 'src/helper/AxiosInstance';
import { Icon } from '@iconify/react';
import PickDate from 'src/components/shared/PickDate';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import PickDateTwo from 'src/components/shared/PickDateTwo';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import SummaryPickDateTwo from 'src/components/shared/summaryPickDate';

export default function index() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const { width, height } = useWindowDimensions();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  async function ExportXlsx(exportBy) {
    setIsLoading(true); // Start loading

    try {
      const params = {
        from: startDate.getTime(),
        to: endDate.getTime()
      };
      let fetchUrl = '';
      if (exportBy == 'byDay') fetchUrl = '/summaryByDay';
      else if (exportBy == 'byConsult') fetchUrl = '/summaryByConsult';
      else if (exportBy == 'byAppointment') fetchUrl = '/summaryByAppointment';
      else fetchUrl = '/summaryByForAppointment';

      const { data } = await axiosInstance.post(fetchUrl, {}, { params, responseType: 'blob' });
      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  }

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div
        className="p-8  flex min-h-screen  justify-center bg-[#fafafa] "
        style={{
          width: `${Responsive.w(1580, width)}px`,

          gap: `${Responsive.sp(36, width)}px`
        }}
      >
        <div
          className="flex flex-col justify-start items-center  "
          style={{
            width: `${Responsive.w(1560, width)}px`,

            gap: `${Responsive.sp(36, width)}px`
          }}
        >
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <div
            className="flex justify-center items-center "
            style={{
              width: `${Responsive.w(1560, width)}px`,
              height: `${Responsive.h(196, height)}px`,
              gap: `${Responsive.sp(36, width)}px`
            }}
          >
            <div
              className="grid grid-cols-3 p-0 "
              style={{
                width: `${Responsive.w(1560, width)}px`,
                height: `${Responsive.h(104, height)}px`,
                gap: `${Responsive.sp(29, width)}px`
              }}
            >
              {isLoading ? (
                <div className="shrink-0 flex flex-col justify-center items-center">
                  <Icon
                    icon="svg-spinners:3-dots-fade"
                    width={50}
                    className="text-[#7b6c96]"
                  />
                </div>
              ) : (
                <>
                  <button
                    className="flex justify-between items-center rounded-lg bg-white text-[#000] text-left"
                    onClick={() => ExportXlsx('byDay')}
                    style={{
                      width: `${Responsive.w(492, width)}px `,
                      fontSize: `${Responsive.sp(22, width)}px`,
                      paddingBlock: `${Responsive.sp(24, width)}px`,
                      paddingInline: `${Responsive.sp(32, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'exportByDay',
                      defaultMessage: 'Export By Day'
                    })}
                    <div
                      className="flex justify-center items-center text-white"
                      style={{
                        backgroundColor: dashColor,
                        width: `59px`,
                        height: ' 56px',
                        borderRadius: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <Icon
                        icon={'pajamas:download'}
                        width={24}
                        className="text-white"
                        height={24}
                      />
                    </div>
                  </button>
                  <button
                    className="flex justify-between items-center rounded-lg bg-white text-[#000] text-left"
                    onClick={() => ExportXlsx('byConsult')}
                    style={{
                      width: `${Responsive.w(492, width)}px `,
                      fontSize: `${Responsive.sp(22, width)}px`,
                      paddingBlock: `${Responsive.sp(24, width)}px`,
                      paddingInline: `${Responsive.sp(32, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'exportByConsult',
                      defaultMessage: 'Export By Consult'
                    })}
                    <div
                      className="flex justify-center items-center text-white"
                      style={{
                        backgroundColor: dashColor,
                        width: `59px`,
                        height: ' 56px',
                        borderRadius: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <Icon
                        icon={'pajamas:download'}
                        width={24}
                        className="text-white"
                        height={24}
                      />
                    </div>
                  </button>
                  <button
                    className="flex justify-between items-center rounded-lg bg-white text-[#000] text-left"
                    onClick={() => ExportXlsx('byAppointment')}
                    style={{
                      width: `${Responsive.w(492, width)}px `,
                      fontSize: `${Responsive.sp(22, width)}px`,
                      paddingBlock: `${Responsive.sp(24, width)}px`,
                      paddingInline: `${Responsive.sp(32, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'getAppointment',
                      defaultMessage: 'get Appointment'
                    })}
                    <div
                      className="flex justify-center items-center text-white"
                      style={{
                        backgroundColor: dashColor,
                        width: `59px`,
                        height: ' 56px',
                        borderRadius: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <Icon
                        icon={'pajamas:download'}
                        width={24}
                        className="text-white"
                        height={24}
                      />
                    </div>
                  </button>
                  <button
                    className="flex justify-between items-center rounded-lg bg-white text-[#000] text-left"
                    onClick={() => ExportXlsx('byForAppointment')}
                    style={{
                      width: `${Responsive.w(492, width)}px `,
                      fontSize: `${Responsive.sp(22, width)}px`,
                      paddingBlock: `${Responsive.sp(24, width)}px`,
                      paddingInline: `${Responsive.sp(32, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'getForAppointment',
                      defaultMessage: 'get For Ever Appointment'
                    })}
                    <div
                      className="flex justify-center items-center text-white"
                      style={{
                        backgroundColor: dashColor,
                        width: `59px`,
                        height: ' 56px',
                        borderRadius: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <Icon
                        icon={'pajamas:download'}
                        width={24}
                        className="text-white"
                        height={24}
                      />
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const DateRange = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  const locale = useSelector((state: RootState) => state.Locale.value);
  const directionClass = locale === 'ar' ? 'flex-row-reverse' : '';
  const { width, height } = useWindowDimensions();
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className={`flex ${directionClass} justify-center items-center `}
      style={{
        width: `${Responsive.w(552, width)}px`,
        height: `${Responsive.h(56, height)}px`,
        color: dashColor,
        gap: `${Responsive.sp(16, width)}px`
      }}
    >
      <div
        className={`flex justify-start ${directionClass} p-0 items-center flex-grow-0`}
        style={{
          width: `${Responsive.w(140, width)}px`,
          height: `${Responsive.h(24, height)}px`,
          gap: `${Responsive.sp(16, width)}px`,
          color: dashColor
        }}
      >
        <Icon
          icon="ri:filter-off-fill"
          className="w-[24px] h-[24px]"
          style={{
            width: `${Responsive.w(24, width)}px`,
            height: `${Responsive.h(24, height)}px`,
            color: dashColor
          }}
        />

        <div
          className={`flex ${directionClass}  justify-center items-center`}
          style={{
            width: `${Responsive.w(100, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(4, width)}px`
          }}
        >
          <span className="text-[#262626]">
            <Icon
              icon="material-symbols-light:date-range-outline"
              className="w-[20px] h-[20px]"
              style={{
                width: `${Responsive.w(20, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                color: dashColor
              }}
            />
          </span>
          <span
            className="nowrap"
            style={{
              fontSize: `${Responsive.sp(14, width)}px`,
              borderRadius: `${Responsive.sp(3, width)}px`,
              gap: `${Responsive.sp(10, width)}px`,
              color: '#262626'
            }}
          >
            {useIntl().formatMessage({
              id: 'dateRange',
              defaultMessage: 'Date Range'
            })}
          </span>
        </div>
      </div>

      <div
        className={`flex ${directionClass} items-center justify-between bg-[#f5f3f8] px-2 py-1`}
        style={{
          width: `${Responsive.w(200, width)}px`,

          borderRadius: `${Responsive.sp(4, width)}px`
        }}
      >
        <label
          className="mx-2 px-1 py-0 text-white flex justify-center items-center mb-0"
          style={{
            width: `${Responsive.w(49, width)}px`,

            borderRadius: `${Responsive.sp(3, width)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            backgroundColor: dashColor
          }}
        >
          {useIntl().formatMessage({
            id: 'from',
            defaultMessage: 'from'
          })}
        </label>

        <SummaryPickDateTwo
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
      </div>
      <div
        className={`flex ${directionClass} items-center justify-between bg-[#f5f3f8] px-2 py-1`}
        style={{
          width: `${Responsive.w(200, width)}px`,

          borderRadius: `${Responsive.sp(4, width)}px`
        }}
      >
        <label
          className="mx-2 px-1 py-0 text-white flex justify-center items-center mb-0"
          style={{
            width: `${Responsive.w(49, width)}px`,

            borderRadius: `${Responsive.sp(3, width)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            backgroundColor: dashColor
          }}
        >
          {useIntl().formatMessage({
            id: 'to',
            defaultMessage: 'to'
          })}
        </label>

        <SummaryPickDateTwo
          selectedDate={endDate}
          setSelectedDate={setEndDate}
        />
      </div>
    </div>
  );
};

