import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import axios, { AxiosError } from 'axios';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { saveUserInfo } from 'src/reducers/auth';
import { RootState } from 'src/store';
import Alert from 'src/components/shared/Alert';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { width, height } = useWindowDimensions();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const [profile, setProfile] = useState<any>(user);
  const GetProfile = React.useCallback(async function () {
    try {
      const { data } = await axiosInstance.get('profile');
      dispatch(saveUserInfo(data.result));
    } catch (error) {
      console.log('error while getting user info\n', error);
    }
  }, [profile]);

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  return (
    <React.Fragment>
      <div className="p-8 pt-12 space-y-4">
        <div
          className=" relative h-[346px] flex justify-start  bg-white items-start gap-[24px] p-0"
          style={{ width: `${Responsive.w(1560, width)}px` }}
        >
          <UpdateFormBody reFetching={GetProfile} schema={profile} color={dashColor} />
        </div>
      </div>
    </React.Fragment>
  );
}

interface FormInterface {
  name: string | undefined;
  email: string | undefined;
  image: string | undefined;
  phoneNumber: string | undefined;
  password: string | undefined;
  c_password: string | undefined;
}

const UpdateFormBody = ({
  schema,
  reFetching,
  color
}: {
  reFetching: any;
  schema: FormInterface & { id: string };
  color: any;
}): JSX.Element => {
  const [initialValues, setInitialValues] = useState(schema);
  const [errors, setErrors] = useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  console.log('initialValues', initialValues.image);
  
  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
  
      let uploadedImageUrl : any = initialValues.image ;
  
  
      // تحميل الصورة إذا كانت موجودة
      if (selectedFile === null) {
        uploadedImageUrl = null; // Explicitly set to null
      } else if (selectedFile) {
        // Upload image if a new file is selected
        const formData = new FormData();
        formData.append('file', selectedFile);
  
        const response = await axiosInstance.post('/uploadProfileImg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        uploadedImageUrl = response.data.result.data.imgUrl || initialValues.image;
      }
  
      // تحديث القيم في النموذج
      const updatedValues = { ...values, image: uploadedImageUrl };
  console.log('updatedValues===============>>>>', updatedValues);
  
       await axiosInstance.patch('profile/', updatedValues);
      toast.success('Profile updated successfully');
      await reFetching();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {

         const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating..');
        }
        return;
      }
      setErrors('Something went wrong while updating..');
      toast.error('Error while updating the profile');
    } finally {
      setDisabled(false);
    }
  }, [selectedFile, initialValues.image , reFetching]);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
console.log(initialValues , 'initialValues');

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          image: e.target.result,
        }));
        formik.setFieldValue('image', file);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };
  const handleRemoveFile = async () => {
    setSelectedFile(null);
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      image: null // تعيين الصورة كقيمة null أو وضع صورة افتراضية
    }));

    formik.setFieldValue('image', null);
  

    
  };


  return (
    <div
      className=" relative h-[346px] flex bg-white  gap-[24px] p-0 "
      style={{ width: `${Responsive.w(1560, width)}px` }}
    >
      <div
        className="h-[346px] flex flex-col items-stretch gap-[16px] p-[16px] rounded-[16px] bg-white justify-center"
        style={{ width: `${Responsive.w(504, width)}px` }}
      >
        <img
          className="rounded-[50%]"
          src={initialValues.image || '/images/placeholder.png'}
          alt=""
          style={{
            width: `${Responsive.w(472, width)}px`,
            height: `${Responsive.h(243, width)}px`
          }}
        />
        <div className="flex items-center justify-center gap-[24px]">
          <button
            className={`flex items-center justify-center px-4 gap-[5px] py-2 bg-[${color}] border-solid border-[1px]  rounded w-[134px] font-[Montserrat]`}
            onClick={() => document.getElementById('fileInput')?.click()}
            style={{
              borderColor: color,
              color: 'white',
            }}
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Icon
              icon={'iconamoon:edit-fill'}
              className="object-contain p-0  w-[14px] h-[14px]"
              style={{
                color: 'white'
              }}
            />
            <span>
              {<FormattedMessage id='edit' defaultMessage='Edit' />}
            </span>
          </button>
          <button onClick={handleRemoveFile} className="flex items-center justify-center gap-[5px] px-4 py-2 bg-[#ffdbe3] border-solid border-[1px] border-[#f93c65] text-[#f93c65] rounded w-[134px] font-[Montserrat]">
            <img
              src={img_1}
              srcSet={`${img_1_2}, ${img_1_3}`}
              className="object-contain p-0  w-[14px] h-[14px]"
              alt="Description of the image"
            />
            <span>
              {<FormattedMessage id='delete' defaultMessage='Delete' />}
            </span>
          </button>
        </div>
      </div>
      <form
        className="h-[346px] flex flex-col items-stretch gap-[16px] p-[16px] rounded-[16px] bg-white justify-center"
        onSubmit={formik.handleSubmit}
        style={{ width: `${Responsive.w(1032, width)}px` }}
      >
        {errors ? (
          <Alert
            type="error"
            content={errors}
          />
        ) : null}

        <div className="form-group">
          <label className="form-label nowrap  font-semibold w-[50px] h-[22px] font-[Montserrat] text-[16px] text-left text-[#262626] ">
            {useIntl().formatMessage({
              id: 'name',
              defaultMessage: 'name'
            })}
          </label>
          <input
            type="text"
            required
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.name}
            onChange={(e) => handleChange('name', e)}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label nowrap w-[50px] h-[22px] font-[Montserrat] text-[16px] text-left text-[#262626] font-semibold">
            {useIntl().formatMessage({
              id: 'email address',
              defaultMessage: 'E-mail address'
            })}
          </label>
          <input
            type="email"
            required
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.email}
            onChange={(e) => handleChange('email', e)}
          />
          {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label nowrap w-[50px] h-[22px] font-[Montserrat] text-[16px] text-left text-[#262626] font-semibold">
            {useIntl().formatMessage({
              id: 'phoneNumber',
              defaultMessage: 'phoneNumber'
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.phoneNumber}
            onChange={(e) => handleChange('phoneNumber', e)}
          />
          {errors?.phoneNumber ? <span className="form-error">{errors?.phoneNumber}</span> : null}
        </div>

        <div className="flex justify-start mt-4 rounded[5px] py-[5x] px-[10x] ">
          <button
            type="submit"
            style={{
              backgroundColor: color
            }}
            className="px-4 py-2  text-white rounded hover:bg-slate-600 w-[134px] font-[Montserrat]"
          >
            {useIntl().formatMessage({
              id: 'update',
              defaultMessage: 'Update'
            })}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
