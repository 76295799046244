import { Icon } from '@iconify/react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import Modal from 'src/components/shared/Modal';
import { toast } from 'react-toastify';
import useFetch from 'src/hooks/useFetch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactStars from 'react-rating-stars-component';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import DownloadInvoicePDF from 'src/components/InvoicePDF/PDFGenerator';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import InvoicePdfDoc from 'src/components/shared/InvoicePdfDoc';
import { FormattedMessage, useIntl } from 'react-intl';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';

import vector from '../images/Vector+.png';
import { set } from 'firebase/database';
import Table from 'src/components/shared/tables';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/import.png';
import img_2_2 from '../images/import@2x.png';
import img_2_3 from '../images/import@3x.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import SortButton from 'src/components/shared/SortButton';
import SharedTime from 'src/components/shared/SharedTime';
import { Fields, IFilters } from 'src/types/types';
import { ChangeCustomizeKeys, UpdateFilter, UpdateResult } from 'src/reducers/appointments';

import * as xlsx from 'exceljs';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { getDates } from 'src/helper/DateUtils';
import usePeriods from 'src/hooks/usePeriods';

export const AppointmentsClosed = () => {
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const dispatch = useDispatch();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState<any>('allOrders');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  let initialQueries: any = [['consultId', '==', id]];
  const [filters, setFilters] = useState<IFilters>({
    page: 1,
    perPage: 13,
    sorts: [],
    queries: initialQueries,
    search: undefined
  });
  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 14,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }

      setFilters({ ...result });
      dispatch(UpdateFilter({ ...result }));
    },
    [filters, initialQueries, dispatch]
  );

  const appointmentsForeverData = async (params?: Partial<IFilters>) => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(`/appointments-forever`, {
        params: {
          ...params,
          ...filters
        }
      });
      console.log('Data', data.result.data);
      dispatch(UpdateResult(data.result));
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoading(false);
  };

  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Appointments
  );
  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  const allAppointmentsMessage = 'allAppointments';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);
  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    // Start with initial queries

    // Check if the option is 'All User'
    if (option === 'All Appointments') {
      // If 'All Appointments', no date filtering is applied
      ChangeFilter(null, 'clear');
      return; // Exit the function early
    }

    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['createdDateValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };
  useEffect(() => {
    appointmentsForeverData();
  }, [filters]);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  async function ExportAllDataXlsx() {
    try {
      setExportDisabled(true);

      // طلب جميع البيانات بدون تطبيق `perPage`
      const res = await axiosInstance.get('orders', {
        params: {
          ...filters,
          page: 1,
          perPage: count // طلب جميع البيانات
        }
      });

      // If userCreationDate is included in customize_keys, fetch it for each order

      // Fetch userCreationDate for all orders in parallel
      const processedData = await Promise.all(
        res.data.result.data.map(async (order) => {
          try {
            const userCreationRes = await axiosInstance.get(`/userCreationDate/${order.user.uid}`, {
              timeout: 240000 // Set timeout to 120 seconds if needed
            });
            const userCreationDate = userCreationRes.data.result.data?.utcTime || 'N/A';
            return { ...order, userCreationDate };
          } catch (error) {
            console.error('Error fetching user creation date:', error);
            return { ...order, userCreationDate: 'Error fetching date' };
          }
        })
      );

      const finalData = processedData.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key] === 'object' && newItem[key] !== null) {
            newItem[key] = JSON.stringify(newItem[key]);
          }
        });
        return newItem;
      });
      // تحديد المفاتيح المطلوبة فقط بناءً على `customize_keys`
      const columnsToExport = customize_keys.map((key: string) => ({
        header: key,
        key: key,
        width: key === 'orderTimeValue' ? 10 : 20 // تقليل عرض orderTimeValue
      }));

      // إنشاء ملف Excel من البيانات المعالجة
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('All Data');

      // إضافة الأعمدة بناءً على المفاتيح في `customize_keys`
      worksheet.columns = columnsToExport;

      // إضافة الصفوف من البيانات المعالجة
      finalData.forEach((item: any) => {
        console.log('item?.userCreationDate', item?.userCreationDate);

        // إنشاء صف فقط مع المفاتيح التي تتوافق مع `customize_keys`
        const rowData = customize_keys.reduce((obj: any, key: string) => {
          if (key === 'userName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);
              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCreationDate') {
            const createdDate = item?.userCreationDate ? new Date(item.userCreationDate) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderDate' || key === 'date') {
            // تحويل utcTime إلى تاريخ والتأكد من أن له قيمة صالحة
            const createdDate = item?.utcTime ? new Date(item.utcTime) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderId') {
            obj[key] = item?.orderId || 'N/A';
          } else if (key === 'answeredCallNum') {
            obj[key] =
              item?.answeredCallNum || item?.answeredCallNum === 0 ? item?.answeredCallNum : 'N/A';
          } else if (key === 'remainingCallNum') {
            obj[key] =
              item?.remainingCallNum || item?.remainingCallNum === 0
                ? item?.remainingCallNum
                : 'N/A';
          } else {
            obj[key] = item[key] || 'N/A'; // إضافة القيم المطلوبة فقط
          }
          return obj;
        }, {});
        worksheet.addRow(rowData);
      });

      // إرسال الملف كـ Excel
      const filename = new Date().getTime() + '_all_data.xlsx';
      await workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/orders/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  function formatDate(closedDate) {
    if (!closedDate || !closedDate.day || !closedDate.month || !closedDate.year) {
      return 'N/A'; // إذا كانت البيانات غير متاحة
    }
  }
  return (
    <div className="h-full">
      <Table
        loading={isLoading}
        searchAble={false}
        isEmpty={data.length === 0}
        count={count}
        customizeAble={true}
        title={title}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px]  overflow-hidden"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '!text-[#7b6c96] border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customize_keys.some((key) => key === field)}
                                onChange={ChangeCustomizeKey}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                {useIntl().formatMessage({
                                  id: field,
                                  defaultMessage: field
                                })}
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <FilterFields
                        onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                        filters={filters}
                        onReset={() => ChangeFilter([...initialQueries], 'queries')}
                        dashColor={dashColor}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] z-50 ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All Appointments', allAppointmentsMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allAppointmentsMessage}
                      defaultMessage={allAppointmentsMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                onClick={(e) => {
                  const selection = window.getSelection();
                }}
                style={{ backgroundColor: getRowColor(index) }}
                className="cursor-pointer"
              >
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {index + 1}
                </td>
                {isIncluded('appointmentId') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.appointmentId || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantUid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.uid || 'N/A'}
                  </td>
                )}

                {isIncluded('consultantPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div className='flex items-center justify-center'>
                      <img
                        src={item?.consult?.image || '/images/placeholder.png'}
                        alt={item?.consult?.name}
                        className="w-8 h-8 rounded-full object-cover"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}
                {isIncluded('consultantName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('consultNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('userUid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.uid || 'N/A'}
                  </td>
                )}
                {isIncluded('userPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div>
                      <img
                        src={item?.user?.image || '/images/placeholder.png'}
                        alt={item?.user?.name}
                        className="w-8 h-8 rounded-full object-cover"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}

                {isIncluded('userName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('userNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('userCountry') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.countryISOCode || 'N/A'}
                  </td>
                )}
                {currentDashboard == 'dream' && isIncluded('closedDate') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.appointmentStatus === 'closed' ? formatDate(item?.closedDate) : 'N/A'}
                  </td>
                )}
                {isIncluded('closedDate') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <SharedTime
                      date={new Date(item?.utcTime)}
                      // format="MMMM Do YYYY, h:mm:ss a"
                    />
                  </td>
                )}
                {isIncluded('appointmentStatus') && (
                  <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch rounded-[6px]">
                    <p
                      className="flex items-center justify-center"
                      style={{
                        height: '30px',
                        border:
                          item?.appointmentStatus === 'closed'
                            ? 'solid 1px #e84a4d'
                            : item?.appointmentStatus === 'open'
                            ? 'solid 1px #28c794'
                            : item?.appointmentStatus === 'cancel'
                            ? `solid 1px ${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : '',
                        backgroundColor:
                          item?.appointmentStatus === 'closed'
                            ? '#ffe2e2'
                            : item?.appointmentStatus === 'open'
                            ? '#ebfeef'
                            : item?.appointmentStatus === 'cancel'
                            ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                            : '',
                        color:
                          item?.appointmentStatus === 'closed'
                            ? ' #d91c1f'
                            : item?.appointmentStatus === 'open'
                            ? '#228176'
                            : item?.appointmentStatus === 'cancel'
                            ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : ''
                      }}
                    >
                      {item?.appointmentStatus || 'N/A'}
                    </p>
                  </td>
                )}
                {isIncluded('appointmentPrice') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.appointmentPrice || item?.callPrice || 0) || 'N/A'}
                  </td>
                )}
                {isIncluded('isUtc') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.isUtc || 'N/A'}
                  </td>
                )}
                {isIncluded('type') && (
                  <td
                    className="text-center nowrap text-[14px] font-medium text-[#515151]
                  self-stretch"
                  >
                    {' '}
                    {item?.type || 'N/A'}
                  </td>
                )}
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: dashColor
              }}
            >
              #
            </th>
            {isIncluded('appointmentId') && (
              <th className="capitalize">
                <SortButton
                  filters={filters}
                  sortKey="appointmentId"
                  text={useIntl().formatMessage({
                    id: 'uid',
                    defaultMessage: 'ID'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantUid') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantUid"
                  text={useIntl().formatMessage({
                    id: 'consultantUid',
                    defaultMessage: 'consultant id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantPhoto"
                  text={useIntl().formatMessage({
                    id: 'consultantPhoto',
                    defaultMessage: 'consultant photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.name"
                  text={useIntl().formatMessage({
                    id: 'consultantName',
                    defaultMessage: 'consultant name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('consultNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.phone"
                  text={useIntl().formatMessage({
                    id: 'consultantNumber',
                    defaultMessage: 'consultant number'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userUid') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userUid"
                  text={useIntl().formatMessage({
                    id: 'userUid',
                    defaultMessage: 'user id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userPhoto"
                  text={useIntl().formatMessage({
                    id: 'userPhoto',
                    defaultMessage: 'user photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.name"
                  text={useIntl().formatMessage({
                    id: 'userName',
                    defaultMessage: 'user name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userNumber"
                  text={useIntl().formatMessage({
                    id: 'userNumber',
                    defaultMessage: 'user number'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userCountry') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.countryISOCode"
                  text={useIntl().formatMessage({
                    id: 'userCountry',
                    defaultMessage: 'user country'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {currentDashboard == 'dream' && isIncluded('closedDate') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="closedDate"
                  text={useIntl().formatMessage({
                    id: 'AppointmentClose',
                    defaultMessage: 'appointment close'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('closedDate') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="closedDate"
                  text={useIntl().formatMessage({
                    id: 'AppointmentClose',
                    defaultMessage: 'appointment close'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('appointmentStatus') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="appointmentStatus"
                  text={useIntl().formatMessage({
                    id: 'appointmentStatus',
                    defaultMessage: 'appointment status'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('appointmentPrice') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="appointmentPrice"
                  text={useIntl().formatMessage({
                    id: 'appointmentPrice',
                    defaultMessage: 'appointment price'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('isUtc') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="isUtc"
                  text={useIntl().formatMessage({
                    id: 'isUtc',
                    defaultMessage: 'is utc time'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('type') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="type"
                  text={useIntl().formatMessage({
                    id: 'type',
                    defaultMessage: 'type'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {/* {isIncluded('join') && currentDashboard === 'jeras' && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  join
                </th> )}*/}
          </>
        )}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        buttonExport={() => ExportXlsx(data, customize_keys)}
        buttonExportAll={() => ExportAllDataXlsx()}
        pagination={{ ...pagination, search_key: filters.search }}
        onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
        onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
        }}
      />
    </div>
  );
};

function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor
}: any) {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<any>(values.queries || []);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'price':
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;

          case 'appointmentStatus':
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;

          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'timeValue', '==', from + '-' + to);

            break;
          default:
            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }

  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }
  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);
  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();

        onSubmit(query);
      }}
    >
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="price"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('price')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="price-amount"
          >
            {useIntl().formatMessage({
              id: 'price',
              defaultMessage: 'price'
            })}
          </label>
          <input
            type="number"
            className="form-input"
            name="price-amount"
            id="price-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('price')}
            defaultValue={findValue('price')}
            disabled={!selectedFields.includes('price')}
            readOnly={!selectedFields.includes('price')}
          />
        </div>
      </div>

      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="appointmentStatus"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('appointmentStatus')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="order-status"
          >
            {useIntl().formatMessage({
              id: 'appointmentStatus',
              defaultMessage: 'Appointment status'
            })}
          </label>
          <select
            name="order-status"
            id="order-status"
            className="form-select"
            onChange={ChangeFilters('appointmentStatus')}
            defaultValue={findValue('appointmentStatus') || ''}
            disabled={!selectedFields.includes('appointmentStatus')}
          >
            <optgroup label="Status">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'status',
                  defaultMessage: 'status'
                })}
              </option>

              <option value="open">
                {useIntl().formatMessage({
                  id: 'open',
                  defaultMessage: 'open'
                })}
              </option>
              <option value="cancel">
                {useIntl().formatMessage({
                  id: 'cancel',
                  defaultMessage: 'cancel'
                })}
              </option>
              <option value="closed">
                {useIntl().formatMessage({
                  id: 'closed',
                  defaultMessage: 'closed'
                })}
              </option>
              <option value="completed">
                {useIntl().formatMessage({
                  id: 'completed',
                  defaultMessage: 'completed'
                })}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="date"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('date')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="date"
          >
            {useIntl().formatMessage({
              id: 'date',
              defaultMessage: 'date'
            })}
          </label>
          <select
            name="date"
            id="date"
            className="form-select"
            onChange={ChangeFilters('date')}
            defaultValue={findValue('date') || ''}
            disabled={!selectedFields.includes('date')}
          >
            <optgroup label="Periods">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'selectPeriod',
                  defaultMessage: 'select period'
                })}
              </option>

              {usePeriods().map((period: any, index: number) => (
                <option value={period.value}>{period.label}</option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      {/* <div className="flex gap-2 flex-wrap justify-end">
          <button
            className="btn-with-icon bg-gray-200 !text-gray-600"
            type="reset"
            onClick={onReset}
          >
            <span>
              {useIntl().formatMessage({
                id: 'clear',
                defaultMessage: 'clear'
              })}
            </span>
          </button>
          <button
            className="btn-with-icon bg-gray-800"
            type="submit"
          >
            <span>
              {useIntl().formatMessage({
                id: 'apply',
                defaultMessage: 'apply'
              })}
            </span>
          </button>
        </div> */}
      <div className="flex   justify-between items-start">
        <button
          className="btn-with-icon  font-semibold nowrap "
          style={{
            backgroundColor: dashColor
          }}
          type="submit"
        >
          <span>
            <FormattedMessage
              id="applyfilter"
              defaultMessage={'Apply Filter'}
            />
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={onReset}
        >
          <span>
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
      </div>
    </form>
  );
}
