import { Navigate, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'src/components/shared/ProtectedRoute';
import UsersTypes from '../screens/usersType/Index';
import Survey from '../screens/survey/index';
import Supervisor from '../screens/supervisor/index';
import Courses from '../screens/courses/index';
import CoursesDetailsPage from '../screens/courses/coursesDetailsPage';
import CustomerService from '../screens/customerService/index';
import Website from '../screens/website/index';
import OldStatistics from '../screens/oldStatistics/Index';
import ZegoCloud from '../components/zegoCloud';
import Webinar from '../screens/academy/webinar';
import React, { Suspense } from 'react';
import {
  AllAdmins,
  AppSettings,
  Appointments,
  Banners,
  Calls,
  ConsultantDetailsPage,
  MenDetailsPage,
  WomenDetailsPage,
  Consultants,
  Dashboard,
  DuesPayment,
  ErrorPage,
  ForgotPasswordPage,
  Login,
  MyAccount,
  Notifications,
  OrderDetailsPage,
  OrderStats,
  Profile,
  PromoCodes,
  ResetPasswordPage,
  ServiceProviders,
  Statistics,
  Suggestions,
  Summary,
  Support,
  TapCharges,
  Temp,
  TodayOverview,
  Users,
  UsersRefactored,
  WelcomePage,
  Campaigns,
  Registrations,
  Purchases,
  Lessons,
  LessonDetails,
  Jobs,
  Coach,
  AcademyCourses,
  AcademyCoursesDetails,
  AcademyCoursesVideo,
  AcademyQA,
  Permissions,
  CurrentCalls,
  AppointmentsForever,
  Messages
} from 'src/screens';

const Orders = React.lazy(() => import('../screens/orders/index'));
const globalRoutes: RouteObject[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: RouteObject[] = [
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />
  },

  ...globalRoutes
];

const routes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/users',
    element: <Users />
  },
  {
    path: '/supervisor',
    element: <Supervisor />
  },
  {
    path: '/consultants',
    element: <Consultants />
  },
  {
    path: '/consultants/:id',
    element: <ConsultantDetailsPage />
  },
  {
    path: '/men/:id',
    element: <MenDetailsPage />
  },
  {
    path: '/women/:id',
    element: <WomenDetailsPage />
  },
  {
    path: '/courses',
    element: <Courses />
  },
  {
    path: '/courses/:id',
    element: <CoursesDetailsPage />
  },
  {
    path: '/academy/courses',
    element: <AcademyCourses />
  },
  {
    path: '/academy/courses/:id',
    element: <AcademyCoursesDetails />
  },
  {
    path: '/academy/courses/video/:id',
    element: <AcademyCoursesVideo />
  },
  {
    path: '/academy/courses/video',
    element: <AcademyCoursesVideo />
  },
  {
    path: '/academy/courses/addCourse',
    element: <AcademyCoursesDetails />
  },
  {
    path: '/courses/addCourse',
    element: <CoursesDetailsPage />
  },
  {
    path: '/users/:id',
    element: <ConsultantDetailsPage />
  },
  {
    path: '/orders',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Orders />
      </Suspense>
    )
  },
  {
    path: '/orders/:id',
    element: <OrderDetailsPage />
  },
  {
    path: '/appointments',
    element: <Appointments />
  },
  {
    path: '/payments/tap',
    element: <TapCharges />
  },
  {
    path: '/account/profile',
    element: <Profile />
  },
  {
    path: '/promocodes',
    element: <PromoCodes />
  },
  {
    path: '/support',
    element: <Support />
  },
  {
    path: '/summary',
    element: <Summary />
  },
  {
    path: '/banners',
    element: <Banners />
  },
  {
    path: '/dues',
    element: <DuesPayment />
  },
  {
    path: '/notifications',
    element: <Notifications />
  },
  {
    path: '/notifications',
    element: <Notifications />
  },
  {
    path: '/orderstats',
    element: <OrderStats />
  },
  {
    path: '/suggestions',
    element: <Suggestions />
  },
  {
    path: '/myaccount',
    element: <MyAccount />
  },
  {
    path: '/admins',
    element: <AllAdmins />
  },
  {
    path: '/app-settings',
    element: <AppSettings />
  },
  {
    path: '/temp',
    element: <Temp />
  },
  {
    path: '/usersre',
    element: <UsersRefactored />
  },
  {
    path: '/welcome',
    element: <WelcomePage />
  },
  {
    path: '/calls',
    element: <Calls />
  },
  {
    path: '/today-overview',
    element: <TodayOverview />
  },
  {
    path: '/type-signIn',
    element: <UsersTypes />
  },
  {
    path: '/service-providers',
    element: <ServiceProviders />
  },
  {
    path: '/statistics',
    element: <Statistics />
  },
  {
    path: '/oldStatistics',
    element: <OldStatistics />
  },
  {
    path: '/customerservice',
    element: <CustomerService />
  },
  {
    path: '/website',
    element: <Website />
  },
  {
    path: '/ad-campaigns',
    element: <Campaigns />
  },
  {
    path: '/registrations/:promoId',
    element: <Registrations />
  },
  {
    path: '/purchases/:promoId',
    element: <Purchases />
  },
  {
    path: '/survey',
    element: <Survey />
  },
  {
    path: '/zego-cloud',
    element: <ZegoCloud />
  },
  {
    path: '/lessons/:appointmentId/:orderId',
    element: <Lessons />
  },
  {
    path: '/lessonDetails/:appointmentId/:key',
    element: <LessonDetails />
  },
  {
    path: '/webinar',
    element: <Webinar />
  },
  {
    path: '/academy/qa',
    element: <AcademyQA />
  },
  {
    path: '/jobs',
    element: <Jobs />
  },
  {
    path: '/coach',
    element: <Coach />
  },
  {
    path: '/permissions',
    element: <Permissions />
  },
  {
    path: '/currentcalls',
    element: <CurrentCalls />
  },
  {
    path: '/appointmentsForever',
    element: <AppointmentsForever />
  },
  {
    path: '/messages/:appointmentId',
    element: <Messages />
  },
  {
    path: '*',
    element: (
      <Navigate
        to="/welcome"
        replace
      />
    )
  },
  ...globalRoutes
];

const names: any[] = [];

export { globalRoutes, authenticationRoutes, routes, names };
