import React, { useEffect, useRef, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from 'src/helper/firebase';
import axiosInstance from 'src/helper/AxiosInstance';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export default function Index() {
  const [appointment, setAppointment] = useState<any>();
  const [userCalls, setUserCalls] = useState<any[]>([]);
  const database = getDatabase(app);
  const { appointmentId } = useParams();
  const { width } = useWindowDimensions();
  const bottomRef = useRef<HTMLDivElement>(null); // مرجع للتمرير التلقائي

  const fetchAppointmentData = async () => {
    try {
      const data: any = await axiosInstance.get(`/appointment/${appointmentId}`);
      setAppointment(data.data.result.data);
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  useEffect(() => {
    const userCallsRef = ref(database, `/TestChatMessages/${appointmentId}`);

    const unsubscribe = onValue(
      userCallsRef,
      (snapshot) => {
        const fetchedData: any[] = [];
        snapshot.forEach((childSnapshot: any) => {
          fetchedData.push({ key: childSnapshot.key, ...childSnapshot.val() });
        });

        const sortedData = fetchedData.sort((a, b) => {
          const dateA = new Date(a.timeSent).getTime();
          const dateB = new Date(b.timeSent).getTime();
          return dateA - dateB;
        });

        setUserCalls(sortedData);
      },
      (error) => {
        console.error('Error fetching user calls:', error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [database, appointment]);

  // التمرير التلقائي إلى الأسفل عند تحديث الرسائل
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [userCalls]);

  const getStatusIcon = (status: string) => {
    if (status === 'viewed') {
      return <Icon icon="mdi:check-all" className="text-blue-500 text-sm" />;
    } else if (status === 'sent') {
      return <Icon icon="mdi:check" className="text-gray-500 text-sm" />;
    } else {
      return null;
    }
  };

  return (
    <div
      className="p-8 pt-12 space-y-4"
      style={{
        width: `${Responsive.w(1584, width)}px`,
      }}
    >
      <div className="space-y-4">
        {userCalls.length === 0 ? (
          <p className="text-center text-gray-500">No messages yet</p>
        ) : (
          userCalls.map((item, index) => (
            <div
              key={index}
              className={`flex ${
                item.senderId === appointment?.user?.uid ? 'justify-start' : 'justify-end'
              } items-center gap-2`}
            >
              <img
                src={
                  item.senderId === appointment?.user?.uid
                    ? appointment?.user?.image || '/images/placeholder.png'
                    : appointment?.consult?.image || '/images/placeholder.png'
                }
                alt="User"
                className="w-10 h-10 rounded-full"
              />
              <div
                className={`${
                  item.senderId === appointment?.user?.uid
                    ? 'bg-gray-300 text-black'
                    : 'bg-gray-300 text-black'
                } p-4 rounded-lg max-w-xs relative`}
              >
                <p className="text-start">{item.message}</p>
                <div className="flex items-center justify-between mt-2 gap-3">
                  {getStatusIcon(item.status)}
                </div>
              </div>
            </div>
          ))
        )}
        {/* العنصر المرجعي للتمرير التلقائي */}
        <div ref={bottomRef}></div>
      </div>
    </div>
  );
}
