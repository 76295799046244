import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import UploadImage from 'src/components/shared/UploadImage';
import axiosInstance from 'src/helper/AxiosInstance';
import useFetch from 'src/hooks/useFetch';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import vector from '../images/Vector+.png';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/Edit.png';
import img_2_2 from '../images/Edit@2x.png';
import img_2_3 from '../images/Edit@3x.png';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { usePermissions } from 'src/hooks/usePeermissions';
export default function index() {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [active , setActive] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [search , setSearch] = useState(null);
  const [selectedTab, setSelectedTab] = useState('');
  const navigate = useNavigate();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const permission = usePermissions();
  const toggleDropdown = () => setIsOpen(!isOpen);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const allCoursesMessage = useIntl().formatMessage({
    id: 'allCourses',
    defaultMessage: 'All Courses'
  });

  const allCoursesActiveMessage = useIntl().formatMessage({
    id: 'allCoursesActive',
    defaultMessage: 'All Courses Active'
  });

  const  allCoursesNotActiveMessage= useIntl().formatMessage({
    id: 'allCoursesNotActive',
    defaultMessage: 'All Courses Not Active'
  });
  const handleAllcourses = () => {
    setActive(undefined); 
    setSelectedTab('');
  }
  const handleAllcoursesActive = () => {
    setActive(true); 
    setSelectedTab('true');
  }
  const handleAllcoursesNotActive = () => {
    setActive(false); 
    setSelectedTab('false');
  }
  useEffect(() => {
    if (selectedTab === 'false' || selectedTab === 'true' || selectedTab === '') {
      const ActiveMap = {
        '': allCoursesMessage,
        false: allCoursesNotActiveMessage,
        true: allCoursesActiveMessage
      };
      setTitle(ActiveMap[selectedTab]);
    }
  }, [selectedTab, title]);
  return (
    <div
      className="flex flex-col justify-center  min-h-screen p-[8px] bg-[#fff] mt-3"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
      className='flex justify-between items-center mb-3 '
      >


{permission.some((item) => item?.permission?.includes('Edit')) && (
        <button
          className={`cursor-pointer mb-3  bg-white border-[1px] border-solid border-[${dashColor}]  h-[56px] inline-flex items-center  py-[5px] pr-[16px] rounded-[8px] `}
          onClick={() => navigate('/courses/addCourse')}
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(56, height)}px`
          }}
        >
          <p
            className="shrink-0 mx-auto font-medium font-[Montserrat] text-[#262626] flex items-center justify-center "
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(56, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
            <Icon
              icon={'tabler:plus'}
              style={{
                color: dashColor,
                width: '16px',
                height: '16px'
              }}
            />

            <span>
              {useIntl().formatMessage({
                id: 'addCourses',
                defaultMessage: 'Add Courses'
              })}
            </span>
          </p>
        </button>
      )}




       <div className="flex items-center justify-center gap-4">
       <button
            className="rounded-[8px] h-[54px]  p-3  hover:cursor-pointer text-[#fff]"
            style={{
              backgroundColor:dashColor
            }}
            onClick={()=>setSearch(null)}
          >
            clear Search
          </button>
       <div className="w-full sm:w-auto bg-neutral-50 h-[56px] inline-flex items-center ml-auto p-3 rounded-lg border border-neutral-200">
              <span className="shrink-0 inline text-neutral-500">
                <Icon
                  icon="ri:search-line"
                  width={20}
                />
              </span>

              <input
                type="text"
                className="form-input !bg-transparent text-[16px] font-[Montserrat] border-none py-0"
                placeholder={useIntl().formatMessage({
                  id: 'Search',
                  defaultMessage: 'Search'
                })}
                autoComplete="on"
                onKeyDown={(e: any) =>{ if(e.key === 'Enter' && e.target.value) setSearch(e.target.value)}}
              />
            </div>
       <div
          className=" border-solid border-[1px] border-[#d9d9d9]  z-10 bg-white  ml-[20px] flex items-center justify-between rounded-md w-[266px] h-[56px] cursor-pointer wrap py-[10px] px-[24px]"
          style={{
            color: dashColor
          }}
          onClick={toggleDropdown}
        >
          <p>{title}</p>
          {isOpen ? (
            <div className="up transition ease-in-out delay-150">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="transition ease-in-out delay-150"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <div className="down transition ease-in-out delay-150">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="transition ease-in-out delay-150"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          )}
          {isOpen && (
            <div
              id="dropdown"
              className="z-8 mt-8 bg-white text-left  divide-y divide-gray-100 rounded-lg shadow w-[266px]   "
              style={{
                position: 'absolute',
                marginLeft: '-24px',
                marginTop: '230px',
                boxShadow: ' 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)'
              }}
            >
              <ul
                className="py-2 text-sm text-[#202224]"
                aria-labelledby="dropdownDefaultButton"
              >
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium "
                    onClick={handleAllcourses}
                  >
                    {allCoursesMessage}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium border-solid "
                    onClick={handleAllcoursesActive}
                  >
                    {allCoursesActiveMessage}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={handleAllcoursesNotActive}
                  >
                    {allCoursesNotActiveMessage}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        </div> 
      
      </div>
     
      <div
        className="grid grid-cols-4  p-0 "
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        <CourseCard
        active={active}
        search={search}
          dashColor={dashColor}
          permission={permission}
        />
      </div>
    </div>
  );
}

function CourseCard({ dashColor, permission , active  , search}) {
  const { width, height } = useWindowDimensions();
  const [courses, setCourses] = useState<any>([]);

  const [isloading, setIsloading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [userLang, setUserLang] = useState('');
  const [containerHeight, setContainerHeight] = useState<any>(75);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setUserLoading(true);
    if (user.Language) {
      setUserLang((prev) => {
        return user.Language.map((lang) => {
          return lang === 'English'
            ? 'en'
            : lang === 'Arabic'
            ? 'ar'
            : lang === 'French'
            ? 'fr'
            : 'ar';
        });
      });
    }
    setUserLoading(false);
  }, [user]);
~
  console.log('userLang', userLang);

  async function fetchAllCourses() {
    setIsloading(true);
    try {
      const responsCourses = await axiosInstance.get(`/courses/`, {
        params: {
          userLang: userLang,
          Active: active !== null ? active : undefined, // أرسل undefined إذا لم يكن هناك قيمة
          search: search || undefined, // أرسل undefined إذا لم يكن هناك قيمة
        },
      });
      setCourses(responsCourses.data.result.data);
    } catch (error) {
      console.error('Error fetching couses:', error);
    }
    setIsloading(false);
  }
  const showConfirmation = useConfirmationDialog();

  async function fetchDeletCourses(id) {
    showConfirmation('Are you sure you want to delete this course?', async () => {
      try {
        await axiosInstance.delete(`/courses/${id}`);
        setCourses((prevData) => prevData.filter((course) => course.courseId !== id));
        toast.success('Deleted courses successfully');
      } catch (error) {
        toast.error('Error deleting courses');
        console.error('Error fetching couses:', error);
      }
    });
  }
  console.log('courses', courses);

  useEffect(() => {
    if (userLang.length > 0 && !userLoading) {
      fetchAllCourses();
    }
  }, [userLang , active , search]);

  const truncateDesc = (desc) => {
    if (!desc) return ''; // تحقق من أن النص موجود
    const maxLength = 50;
    if (desc.length <= maxLength) return desc;
    const lastSpaceIndex = desc.lastIndexOf(' ', maxLength);
    return desc.slice(0, lastSpaceIndex) + '...';
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ''; // تحقق من أن النص موجود
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const hasEditOrDeletePermission = (item) => {
    return item?.permission?.includes('Edit') || item?.permission?.includes('Delete');
  };
  const hasDeletePermission = (item) => {
    return item?.permission?.includes('Delete');
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  return (
    <>
      {isloading ? (
        <LoadingComponent />
      ) : (
        <>
          {courses?.map((course, index) => {
            return (
              <div
                className="flex flex-col justify-start items-stretch fle-grow-0 rounded-md "
                key={index}
                style={{
                  width: `${Responsive.w(372, width)}px`,
                  height: `${Responsive.h(460, height)}px`,
                  paddingBottom: `${Responsive.sp(20, width)}px`,
                  gap: `${Responsive.sp(16, width)}px`,
                  boxShadow: ' 2px 2px 8px 0 rgba(0, 0, 0, 0.15)'
                }}
              >
                {/* course image */}
                <div
                  className="flex-grow-0 self-stretch rounded-md"
                  style={{
                    width: `${Responsive.w(372, width)}px`,
                    height: `${Responsive.h(209, height)}px`
                  }}
                >
                  <img
                    src={
                      currentDashboard === 'jeras'
                        ? course?.backgroundImage || '/images/placeholder.png'
                        : currentDashboard === 'nikah'
                        ? course?.image || '/images/placeholder.png'
                        : '/images/placeholder.png'
                    }
                    className="rounded-md"
                    alt={''}
                    style={{
                      width: `${Responsive.w(372, width)}px`,
                      height: `${Responsive.h(209, height)}px`
                    }}
                  />
                </div>
                {/* course name */}
                <div
                  className="flex justify-start items-center self-stretch  "
                  style={{
                    width: `${Responsive.w(356, width)}px`,
                    height: `${Responsive.h(31, height)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <h1
                    className={`text-right font-[Montserrat] font-bold text-[${dashColor}] flex-grow-0 nowrap w-auto`}
                    style={{
                      height: `${Responsive.h(31, height)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {truncateText(course?.name || 'courseName', 30)}
                  </h1>
                </div>
                {/* course description */}
                <div
                  className="flex flex-col justify-start items-stretch self-stretch "
                  style={{
                    width: `${Responsive.w(372, width)}px`,
                    height: `${Responsive.h(142, height)}px`,
                    gap: `${Responsive.sp(8, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <div
                    className="flex justify-start items-enter self-stretch p-0 flex-grow-0 gap-6"
                    style={{
                      width: `${Responsive.w(356, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                  >
                    <div
                      className="flex justify-center items-center self-stretch p-0 w-auto"
                      style={{
                        // width: `${Responsive.w(101, width)}px`,

                        gap: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <div className="flex items-center justify-center  flex-grow-0 my-auto h-[20px]">
                        <Icon
                          icon="ci:layer"
                          style={{
                            width: `${Responsive.w(20, width)}px`,
                            height: `${Responsive.h(20, height)}px`,
                            color: dashColor,
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                      <p
                        className={`font-[Ithra] font-light text-[${dashColor}] text-center w-auto`}
                        style={{
                          // width: `${Responsive.w(73, width)}px`,
                          height: `${Responsive.h(20, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      >
                        lessons :{' '}
                        <span className="text-[#535353]">{course?.lessonNum || 'N/A'}</span>
                      </p>
                    </div>

                    <div
                      className="flex justify-end items-center self-stretch p-0 w-auto ml-auto mr-4"
                      style={{
                        // width: `${Responsive.w(200, width)}px`,
                        gap: `${Responsive.sp(8, width)}px`
                      }}
                    >
                      <div className="flex items-center justify-center  flex-grow-0 my-auto h-[20px]">
                        <Icon
                          icon="bxs:user-check"
                          style={{
                            width: `${Responsive.w(20, width)}px`,
                            height: `${Responsive.h(20, height)}px`,
                            objectFit: 'contain',
                            color: dashColor
                          }}
                        />
                      </div>

                      <p
                        className={`font-[Ithra] font-light text-[${dashColor}] text-center nowrap`}
                        style={{
                          height: `${Responsive.h(20, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      >
                        <span className="text-[#535353]">{course?.age || 'N/A'}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="flex justify-start items-start self-stretch p-0 flex-grow-0 "
                  style={{
                    width: `${Responsive.w(356, width)}px`,
                    height: `${Responsive.h(75, height)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <p
                    className="text-left text-[#535353] font-medium flex-grow leading-[1.9] h-auto"
                    style={{
                      width: `${Responsive.w(356, width)}px`,
                      height: `${Responsive.h(75, height)}px`,
                      fontSize: `${Responsive.sp(14, width)}px`
                    }}
                  >
                    {truncateDesc(course.desc)}
                    <button
                      onClick={() => {
                        navigate(`/courses/${course?.courseId}`);
                      }}
                      className={`text-[${dashColor}]  font-semibold nowrap ml-4`}
                      style={{
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {'Read More'}
                    </button>
                  </p>
                </div>
                {permission.some(hasEditOrDeletePermission) && (
                  <div
                    className="flex justify-center items-enter self-stretch  flex-grow-0 py-0 px-[16px] text-center"
                    style={{
                      width: `${Responsive.w(372, width)}px`,
                      height: `${Responsive.h(39, height)}px`,
                      gap: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    {permission.some(hasEditPermission) && (
                      <button
                        className={`text-white py-[5px] px-4  font-semibold bg-[${dashColor}] border border-solid border-[${dashColor}] flex justify-center items-center gap-2`}
                        style={{
                          width: `${Responsive.w(162, width)}px`,

                          borderRadius: `${Responsive.sp(8, width)}px`
                        }}
                        onClick={() => {
                          navigate(`/courses/${course?.courseId}`);
                        }}
                      >
                        <Icon
                          icon={'iconamoon:edit-fill'}
                          className="object-contain p-0 w-[14px] h-[14px] "
                          style={{
                            width: `${Responsive.w(18.3, width)}px`,
                            height: `${Responsive.h(18.3, height)}px`
                          }}
                        />
                        <p
                          className="leading-[1.6] text-center font-semibold font-[Montserrat] "
                          style={{
                            width: `${Responsive.w(55, width)}px`,

                            fontSize: `${Responsive.sp(16, width)}px`
                          }}
                        >
                          {useIntl().formatMessage({
                            id: 'edit',
                            defaultMessage: 'Edit'
                          })}
                        </p>
                      </button>
                    )}
                    {permission.some(hasDeletePermission) && (
                      <button
                        className={`text-[${dashColor}] py-[5px] px-4  font-semibold bg-white border border-solid border-[${dashColor}] flex justify-center items-center gap-2`}
                        style={{
                          width: `${Responsive.w(162, width)}px`,

                          borderRadius: `${Responsive.sp(8, width)}px`
                        }}
                        onClick={() => {
                          fetchDeletCourses(course?.courseId);
                        }}
                      >
                        <Icon
                          icon={'material-symbols:delete'}
                          className="object-contain p-0 w-[14px] h-[14px] "
                          style={{
                            width: `${Responsive.w(18.3, width)}px`,
                            height: `${Responsive.h(18.3, height)}px`,
                            color: dashColor
                          }}
                        />

                        <p
                          className="leading-[1.6] text-center font-semibold font-[Montserrat] "
                          style={{
                            width: `${Responsive.w(55, width)}px`,

                            fontSize: `${Responsive.sp(16, width)}px`
                          }}
                        >
                          {useIntl().formatMessage({
                            id: 'delete',
                            defaultMessage: 'Delete'
                          })}
                        </p>
                      </button>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
    </>
  );
}

