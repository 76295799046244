import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import SideModal from 'src/components/shared/SideModal';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import SharedTime from 'src/components/shared/SharedTime';
import SortButton from 'src/components/shared/SortButton';
import { ChangeCustomizeKeys, UpdateFilter, UpdateResult } from 'src/reducers/orders';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getDates } from 'src/helper/DateUtils';
import RangePicker from 'src/components/shared/RangePicker';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { FormattedMessage, useIntl } from 'react-intl';
import usePeriods from 'src/hooks/usePeriods';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import Card from 'src/components/shared/Card';

import img_1 from '../images/Frame totals.png';
import img_1_2 from '../images/Frame totals@2x.png';
import img_1_3 from '../images/Frame totals@3x.png';
import img_2 from '../images/Frame totalEarn.png';
import img_2_2 from '../images/Frame totalEarn@2x.png';
import img_2_3 from '../images/Frame totalEarn@3x.png';
import img_3 from '../images/Union.png';
import img_3_2 from '../images/Union@2x.png';
import img_3_3 from '../images/Union@3x.png';
import img_4 from '../images/Vector.png';
import img_4_2 from '../images/Vector@2x.png';
import img_4_3 from '../images/Vector@3x.png';
import img_5 from '../images/calendar.png';
import img_5_2 from '../images/calendar (2).png';
import img_5_3 from '../images/calendar (3).png';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { toast } from 'react-toastify';
import Modal from 'src/components/shared/Modal';
import * as xlsx from 'exceljs';
interface IFilters {
  page: number;
  perPage: number;
  sorts: any[];
  queries: any[];
  search: string | undefined;
}

function useHooks() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let rerender = true;
  const { filters: userFilters } = useSelector((state: RootState) => state.Users);
  const { customize_keys } = useSelector((state: RootState) => state.Orders);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<any>();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  let initialQueries: any = [];
  const date = searchParams.get('date');
  const { from, to } = getDates('today');
  const initialFilters = date
    ? [...initialQueries, ['orderTimeValue', '==', `${from}-${to}`]]
    : initialQueries;
  const [filters, setFilters] = useState<IFilters>({
    ...userFilters,
    page: 1,
    perPage: 13,
    sorts: [],
    queries: initialFilters,
    search: undefined
  });

  useEffect(() => {
    GetOrders();
  }, [filters, searchParams, customize_keys]);

  const [isDataEmpty, setIsDataEmpty] = useState(false);
  console.log('isDataEmpty', isDataEmpty);

  async function GetOrders(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get('orders', {
        params: {
          ...filters,
          ...params,
          sorts: isModalOpen ? [] : filters.sorts
        }
      });

      if (customize_keys.includes('userCreationDate')) {
        console.log('we are in ');
        let ordersResult = data.result;

        const modifiedOrders = await Promise.all(
          ordersResult.data.map(async (order) => {
            try {
              const res = await axiosInstance.get(`userCreationDate/${order.user.uid}`);
              const userCreationDate = res.data.result.data?.utcTime || 'N/A';
              return { ...order, userCreationDate };
            } catch (error) {
              console.error('Error fetching user creation date:', error);
              return { ...order, userCreationDate: 'Error fetching date' };
            }
          })
        );

        data.result.data = modifiedOrders;
      }
      console.log('data.result.data', data.result.data);
      setIsDataEmpty(data.result.data.length === 0);
      dispatch(UpdateResult(data.result));
      return data.result.data; // Return the fetched data
    } catch (error: AxiosError | any) {
      console.log(error);
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 14,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }
      const sorts = isModalOpen ? [] : result.sorts;

      setFilters({ ...result, sorts });
      dispatch(UpdateFilter({ ...result, sorts }));
    },
    [filters, initialQueries, isModalOpen, dispatch]
  );

  const performSearch = async (searchValue) => {
    const cleanedSearchValue = searchValue.replace(/\s+/g, '');
    const isPhoneNumber = /^[+]?[0-9]+$/.test(cleanedSearchValue);

    const searchQueries = async (primaryField, secondaryField, value) => {
      await ChangeFilter([[primaryField, '>=', value + '~']], 'queries');
      if (!isDataEmpty) {
        await ChangeFilter([[secondaryField, '>=', value + '~']], 'queries');
      }
    };

    if (isPhoneNumber) {
      // Search in 'user.phone' first, then in 'consult.phone'
      await searchQueries('user.phone', 'consult.phone', cleanedSearchValue);
    } else {
      // Search in 'user.name' first, then in 'consult.name'
      await searchQueries('user.name', 'consult.name', searchValue);
    }
  };

  return {
    isLoading,
    GetOrders,
    ChangeFilter,
    filters,
    setIsModalOpen,
    initialQueries,
    isDataEmpty,
    performSearch
  };
}

export default function index() {
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState<any>('allOrders');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any | null>(null);
  const [newData, setNewData] = useState<any>({
    totalOrdersCount: { data: 0, loading: true },
    totalEarnCount: { data: 0, loading: true },
    totalCompletedCount: { data: 0, loading: true },
    totalCanceledCount: { data: 0, loading: true },
    totalCloseCount: { data: 0, loading: true }
  });
  const location = useLocation();

  // Create a URLSearchParams object to parse query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the consultid parameter
  const consultId = queryParams.get('consultid');
  console.log('consultId', consultId);

  const showConfirmation = useConfirmationDialog();
  useMemo(() => {
    fetchTotalOrders();
    fetchTotalEarn();
    fetchTotalCompleted();
    fetchTotalCanceled();
    fetchTotalClose();
  }, []);

  
  const openFormModal = (item) => {
    setIsFormModalOpen(true);
    setCurrentData(item);
  };

  async function fetchTotalOrders() {
    try {
      const totalOrders = await axiosInstance.get('dashboard/orders');

      setNewData((prevData) => ({
        ...prevData,
        totalOrdersCount: {
          data: totalOrders.data.result.data.totalOrders,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }
  async function fetchTotalEarn() {
    try {
      const totalEarn = await axiosInstance.get('dashboard/totalEarn');

      setNewData((prevData) => ({
        ...prevData,
        totalEarnCount: {
          data: totalEarn.data.result.data.totalEarn,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }
  async function fetchTotalCompleted() {
    try {
      const totalCompleted = await axiosInstance.get('/dashboard/completed');

      setNewData((prevData) => ({
        ...prevData,
        totalCompletedCount: {
          data: totalCompleted.data.result.data.totalCompleted,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }

  async function fetchTotalCanceled() {
    try {
      const totalCanceled = await axiosInstance.get('/dashboard/canceled');

      setNewData((prevData) => ({
        ...prevData,
        totalCanceledCount: {
          data: totalCanceled.data.result.data.totalCanceled,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }
  async function fetchTotalClose() {
    try {
      const totalClose = await axiosInstance.get('/dashboard/close');

      setNewData((prevData) => ({
        ...prevData,
        totalCloseCount: {
          data: totalClose.data.result.data.totalClose,
          loading: false
        }
      }));
     
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }
  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/orders/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  const {
    isLoading,
    GetOrders,
    ChangeFilter,
    filters,
    setIsModalOpen,
    initialQueries,
    isDataEmpty,
    performSearch
  } = useHooks();
  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Orders
  );

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  console.log('pagination', pagination);

  const navigate = useNavigate();

  const allOrdersMessage = 'allOrders';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    let filterQueries = [...initialQueries]; // Start with initial queries

    // Check if the option is 'All User'
    if (option === 'All Orders') {
      // If 'All Orders', no date filtering is applied
      //ChangeFilter('clearSorts', 'sorts');

      ChangeFilter(null, 'clear');
      // setIsModalOpen(true);
      return; // Exit the function early
    }
    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['orderTimeValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };
  const dispatch = useDispatch();
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  console.log(newData.totalCompletedCount.data);
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const handelChengeStutas = (orderId) => {
    const confirmationMessage = 'Are you sure you want to canceled this order?';
    showConfirmation(confirmationMessage, async () => {
      try {
        await axiosInstance.patch(`/dashboard/orders-Status/${orderId}`, {
          orderStatus: 'cancel',
          appointmentStatus: 'cancel'
        });

        const successMessage = useIntl().formatMessage({
          id: 'orderCanceled',
          defaultMessage: 'Order canceled successfully'
        });
        toast.success(successMessage);
      } catch (error) {
        console.log(error);
      } finally {
        ChangeFilter(null, 'clear');
      }
    });
  };
  const handelAddAppointment = (orderId) => {
    const confirmationMessage = 'Are you sure you want to add an appointment?';
    showConfirmation(confirmationMessage, async () => {
      try {
        await axiosInstance.patch(`/dashboard/add-appointment/${orderId}`);

        const successMessage = useIntl().formatMessage({
          id: 'appointmentAdded',
          defaultMessage: 'Appointment added successfully'
        });
        toast.success(successMessage);
      } catch (error) {
        console.log(error);
      } finally {
        ChangeFilter(null, 'clear');
      }
    });
  };
  async function ExportAllDataXlsx() {
    try {
      setExportDisabled(true);

      // طلب جميع البيانات بدون تطبيق `perPage`
      const res = await axiosInstance.get('orders', {
        params: {
          ...filters,
          page: 1,
          perPage: count // طلب جميع البيانات
        }
      });

      // If userCreationDate is included in customize_keys, fetch it for each order

      // Fetch userCreationDate for all orders in parallel
      const processedData = await Promise.all(
        res.data.result.data.map(async (order) => {
          try {
            const userCreationRes = await axiosInstance.get(`/userCreationDate/${order.user.uid}`, {
              timeout: 240000 // Set timeout to 120 seconds if needed
            });
            const userCreationDate = userCreationRes.data.result.data?.utcTime || 'N/A';
            return { ...order, userCreationDate };
          } catch (error) {
            console.error('Error fetching user creation date:', error);
            return { ...order, userCreationDate: 'Error fetching date' };
          }
        })
      );

      const finalData = processedData.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key] === 'object' && newItem[key] !== null) {
            newItem[key] = JSON.stringify(newItem[key]);
          }
        });
        return newItem;
      });
      // تحديد المفاتيح المطلوبة فقط بناءً على `customize_keys`
      const columnsToExport = customize_keys.map((key: string) => ({
        header: key,
        key: key,
        width: key === 'orderTimeValue' ? 10 : 20 // تقليل عرض orderTimeValue
      }));

      // إنشاء ملف Excel من البيانات المعالجة
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('All Data');

      // إضافة الأعمدة بناءً على المفاتيح في `customize_keys`
      worksheet.columns = columnsToExport;

      // إضافة الصفوف من البيانات المعالجة
      finalData.forEach((item: any) => {
        console.log('item?.userCreationDate', item?.userCreationDate);

        // إنشاء صف فقط مع المفاتيح التي تتوافق مع `customize_keys`
        const rowData = customize_keys.reduce((obj: any, key: string) => {
          if (key === 'userName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);
              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCreationDate') {
            const createdDate = item?.userCreationDate ? new Date(item.userCreationDate) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderDate' || key === 'date') {
            // تحويل utcTime إلى تاريخ والتأكد من أن له قيمة صالحة
            const createdDate = item?.utcTime ? new Date(item.utcTime) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderId') {
            obj[key] = item?.orderId || 'N/A';
          } else if (key === 'answeredCallNum') {
            obj[key] =
              item?.answeredCallNum || item?.answeredCallNum === 0 ? item?.answeredCallNum : 'N/A';
          } else if (key === 'remainingCallNum') {
            obj[key] =
              item?.remainingCallNum || item?.remainingCallNum === 0
                ? item?.remainingCallNum
                : 'N/A';
          } else {
            obj[key] = item[key] || 'N/A'; // إضافة القيم المطلوبة فقط
          }
          return obj;
        }, {});
        worksheet.addRow(rowData);
      });

      // إرسال الملف كـ Excel
      const filename = new Date().getTime() + '_all_data.xlsx';
      await workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  console.log(data);
  useMemo(() => {
    if (consultId != null) {
      ChangeFilter(
        [['consultId', '==', consultId], ...initialQueries], // استخدم 'in' للبحث عن مجموعة من القيم
        'queries'
      );
    } else {
      ChangeFilter(null, 'clear');
    }
  },[consultId])
  return (
    <div
      className="p-[3px] space-y-6 bg-[#fff] min-h-screen  overflow-x-hidden"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      {/* header */}
      <div
        className="flex flex-row justify-start items-start  ml-[24px]  mt-[24px] mr-0 mb-[32px]  p-0 pr-[20px]"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          height: `${Responsive.h(110, height)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        <Card
          className="flex  justify-between items-center   flex-grow-[1px] ] bg-white border-solid border-b-4 border-b-[#4ad991] "
          style={{
            width: `${Responsive.w(296, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            paddingInline: `${Responsive.sp(16, width)}px`,
            paddingBlock: `${Responsive.sp(24, width)}px`,
            borderBottomColor: dashColor
          }}
        >
          <div
            className=" flex-grow-0 ] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <p
              className="nowrap opacity-70   font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`,
                lineHeight: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="totalOrders"
                defaultMessage="total orders"
              />
            </p>
            <p
              className="tracking-[1px]    font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {newData?.totalOrdersCount?.loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.totalOrdersCount?.data
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-center items-center "
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`,
              gap: `${Responsive.sp(8, width)}px`,
              borderRadius: `${Responsive.sp(10, width)}px`

            }}
          >
            <Icon
              icon={'lucide:shopping-bag'}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`,
                color: dashColor
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center   flex-grow-[1px]  bg-white border-solid border-b-4 border-b-[#e6bc59] "
          style={{
            width: `${Responsive.w(296, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            paddingInline: `${Responsive.sp(16, width)}px`,
            paddingBlock: `${Responsive.sp(24, width)}px`,
          }}
        >
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <p
              className="nowrap opacity-70    font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`,
                lineHeight: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="totalEarn"
                defaultMessage="total Earn"
              />
            </p>
            <p
              className="tracking-[1px]     font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {newData.totalEarnCount.loading ? <Spinner /> : '$' + newData?.totalEarnCount?.data}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#fff3d6]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_2}
              srcSet={`${img_2_2}, ${img_2_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center flex-grow-[1px]  bg-white border-solid border-b-4 border-b-[#4ad991] "
          style={{
            width: `${Responsive.w(296, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            paddingInline: `${Responsive.sp(16, width)}px`,
            paddingBlock: `${Responsive.sp(24, width)}px`,
          }}
        >
          <div
            className=" flex-grow-0 p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <p
              className="nowrap opacity-70    font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`,
                lineHeight: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="completedorders"
                defaultMessage="Completed orders"
              />
            </p>
            <p
              className="tracking-[1px]     font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {newData?.totalCompletedCount?.loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.totalCompletedCount?.data
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-center items-center  bg-[#d9f7e8]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`,
              borderRadius: `${Responsive.sp(10, width)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <img
              src={img_3}
              srcSet={`${img_3_2}, ${img_3_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(30, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center   flex-grow-[1px]  bg-white border-solid border-b-4 border-b-[#2980ba] "
          style={{
            width: `${Responsive.w(296, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            paddingInline: `${Responsive.sp(16, width)}px`,
            paddingBlock: `${Responsive.sp(24, width)}px`,
          }}
        >
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <p
              className="nowrap opacity-70 font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`,
                lineHeight: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="closeOrders"
                defaultMessage="Close orders"
              />
            </p>
            <p
              className="tracking-[1px]    t font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`,
                lineHeight: `${Responsive.sp(34, width)}px`
              }}
            >
              {newData?.totalCloseCount?.loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.totalCloseCount?.data
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-center items-center bg-[#edf0f2]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`,
              borderRadius: `${Responsive.sp(10, width)}px`,
              gap: `${Responsive.sp(8, width)}px`
            }}
          >
            <img
              src={img_4}
              srcSet={`${img_4_2}, ${img_4_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center   flex-grow-[1px]  bg-white border-solid border-b-4 border-b-[#f93c65] "
          style={{
            width: `${Responsive.w(296, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            paddingInline: `${Responsive.sp(16, width)}px`,
            paddingBlock: `${Responsive.sp(24, width)}px`,
          }}
        >
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`,
              gap: `${Responsive.sp(8, width)}px`

            }}
          >
            <p
              className="nowrap opacity-70    font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(95, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`,
                lineHeight: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="canceledOrders"
                defaultMessage="Canceled orders"
              />
            </p>
            <p
              className="tracking-[1px]     font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {newData?.totalCanceledCount?.loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.totalCanceledCount?.data
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0  p-0 flex flex-col justify-center items-center bg-[#ffebeb]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px` ,
              gap : `${Responsive.sp(8, width)}px`,
              borderRadius: `${Responsive.sp(10, width)}px`
            }}
          >
            <img
              src={img_5}
              srcSet={`${img_5_2}, ${img_5_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>
      </div>

      <div className="p-8  space-y-4">
        {/* <TableCustomizeFilterModal
          SendPrams={(val: any) => ChangeFilter(val, 'queries')}
          params={filters}
          setIsModalOpen={setIsModalOpen}
          initialQueries={initialQueries}
        /> */}
        <Table
          loading={isLoading || exportDisabled}
          isEmpty={!data.length}
          count={count}
          customizeAble={true}
          title={title}
          CustomizeTable={() => (
            <>
              {
                <div
                  className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px]  overflow-hidden"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(760, height)}px`,
                    borderRadius: `${Responsive.sp(16, width)}px`,
                    boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                  }}
                >
                  <div
                    className="space-y-4 flex flex-col z-[50]"
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    <div className="grid">
                      <ul
                        className="flex items-start justify-center p-0 flex-row"
                        style={{
                          width: `${Responsive.w(335, width)}px`,
                          height: `${Responsive.h(40, height)}px`,
                          gap: `${Responsive.sp(24, width)}px`
                        }}
                      >
                        {tabs.map((tab: any, index: number) => (
                          <li key={index}>
                            <button
                              className={[
                                'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                                currentTab === tab.key
                                  ? ` ${
                                      currentDashboard !== 'jeras'
                                        ? '!text-[#9c3981] border-b-[#9c3981]'
                                        : '!text-[#7b6c96] border-b-[#7b6c96]'
                                    } border-b-2 `
                                  : '!text-gray-800'
                              ].join(' ')}
                              onClick={() => setCurrentTab(tab.key)}
                            >
                              <span>{tab.name}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="flex items-start justify-start flex-col overflow-y-auto "
                      style={{
                        width: `${Responsive.w(369, width)}px`,
                        height: `${Responsive.h(710, height)}px`
                      }}
                    >
                      {currentTab === 'customize' ? (
                        <ul
                          className="space-y-2 overflow-y-auto"
                          style={{
                            width: `${Responsive.w(369, width)}px`,
                            height: `${Responsive.h(710, height)}px`
                          }}
                        >
                          {keys.map((field: string, index: number) => (
                            <li
                              className="flex flex-row items-start justify-start py-0 px-4 "
                              key={index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(12, width)}px`
                              }}
                            >
                              <label
                                htmlFor={field + '-' + index}
                                style={{
                                  width: `${Responsive.w(345, width)}px`,
                                  height: `${Responsive.h(40, height)}px`,
                                  gap: `${Responsive.sp(8, width)}px`
                                }}
                                className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                              >
                                <input
                                  type="checkbox"
                                  name={field + '-' + index}
                                  id={field + '-' + index}
                                  className="form-checkbox shrink-0"
                                  style={{
                                    width: `${Responsive.w(20, width)}px`,
                                    height: `${Responsive.h(20, height)}px`
                                  }}
                                  value={field}
                                  checked={customize_keys.some((key) => key === field)}
                                  onChange={ChangeCustomizeKey}
                                />

                                <p
                                  className=" font-medium capitalize flex justify-center items-center"
                                  style={{
                                    fontSize: `${Responsive.sp(14, width)}px`
                                  }}
                                >
                                  {useIntl().formatMessage({
                                    id: field,
                                    defaultMessage: field
                                  })}
                                </p>
                              </label>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <FilterFields
                          onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                          filters={filters}
                          onReset={() => ChangeFilter([...initialQueries], 'queries')}
                          dashColor={dashColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }
            </>
          )}
          TimeTable={(props) => (
            <div
              // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
              className={`absolute top-[35px] ${
                title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
              } bg-[#f5f3f8] p-[12px]`}
              style={{
                width: `${Responsive.w(572, width)}px`,
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
              }}
            >
              <ul
                className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
                aria-labelledby="dropdownDefaultButton"
                // style={{
                //   width: `${Responsive.w(572, width)}px`,
                // }}
              >
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full"
                    onClick={() => {
                      handleDateOptionClick('All Orders', allOrdersMessage);
                    }}
                  >
                    {
                      <FormattedMessage
                        id={allOrdersMessage}
                        defaultMessage={allOrdersMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full "
                    onClick={() => handleDateOptionClick('Today', todayMessage)}
                  >
                    {
                      <FormattedMessage
                        id={todayMessage}
                        defaultMessage={todayMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                    onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                  >
                    {
                      <FormattedMessage
                        id={last7DaysMessage}
                        defaultMessage={last7DaysMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                  >
                    {
                      <FormattedMessage
                        id={last30DaysMessage}
                        defaultMessage={last30DaysMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => {
                      handleDateOptionClick('Date range', dateRangeMessage);
                    }}
                  >
                    {
                      <FormattedMessage
                        id={dateRangeMessage}
                        defaultMessage={dateRangeMessage}
                      />
                    }
                  </a>
                </li>
              </ul>
            </div>
          )}
          RenderBody={({ getRowColor }) => (
            <>
              {data?.map((item: any, index: number) => (
                <tr
                  key={index}
                  onClick={(e) => {
                    const selection = window.getSelection();
                    if (selection && selection.toString()) {
                      // Prevent navigation if text is selected
                      e.stopPropagation();
                    } else {
                      const element = e.target as HTMLElement;
                      if (element.closest('.canceled-button')) {
                        e.stopPropagation();
                      } else {
                        // Navigate if no text is selected
                        navigate(`/orders/${item.orderId}`);
                      }
                    }
                  }}
                  className="cursor-pointer hover:bg-[#EAE0EB]"
                  style={{ backgroundColor: getRowColor(index) }}
                >
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {index + 1}
                  </td>
                  {isIncluded('orderId') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.orderId || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultantUid') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consult?.uid || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultantPhoto') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      <div>
                        <img
                          src={item?.consult?.image || '/images/placeholder.png'}
                          // onError={(e) => (e.target.src = '/images/placeholder.png')}
                          alt={item?.consult?.name}
                          className="w-8 h-8 rounded-full object-cover mx-auto"
                          width={512}
                          height={512}
                        />
                      </div>
                    </td>
                  )}
                  {isIncluded('consultantName') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consult?.name || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultNumber') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consult?.phone || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultantCountry') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consult?.countryCode || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultantISOCode') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consult?.countryISOCode || 'N/A'}
                    </td>
                  )}

                  {isIncluded('userUid') && <td>{item?.user?.uid || 'N/A'}</td>}
                  {isIncluded('userPhoto') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      <div>
                        <img
                          src={item?.user?.image || '/images/placeholder.png'}
                          // onError={(e) => (e.target.src = '/images/placeholder.png')}
                          alt={item?.user?.name}
                          className="w-8 h-8 rounded-full object-cover mx-auto"
                          width={512}
                          height={512}
                        />
                      </div>
                    </td>
                  )}
                  {isIncluded('userName') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.user?.name || 'N/A'}
                    </td>
                  )}
                  {isIncluded('userNumber') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.user?.phone || 'N/A'}
                    </td>
                  )}
                  {isIncluded('userCountry') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.user?.countryCode || 'N/A'}
                    </td>
                  )}
                  {isIncluded('userISOCode') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.user?.countryISOCode || 'N/A'}
                    </td>
                  )}

                  <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch rounded-[6px]">
                    <p
                      className="flex items-center justify-center"
                      style={{
                        height: '30px',
                        border:
                          item?.orderStatus === 'cancel'
                            ? 'solid 1px #e84a4d'
                            : item?.orderStatus === 'completed'
                            ? 'solid 1px #28c794'
                            : item?.orderStatus === 'closed'
                            ? `solid 1px ${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : 'solid 1px #1e1e1e',
                        backgroundColor:
                          item?.orderStatus === 'cancel'
                            ? '#ffe2e2'
                            : item?.orderStatus === 'completed'
                            ? '#ebfeef'
                            : item?.orderStatus === 'closed'
                            ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                            : '',
                        color:
                          item?.orderStatus === 'cancel'
                            ? ' #d91c1f'
                            : item?.orderStatus === 'completed'
                            ? '#228176'
                            : item?.orderStatus === 'closed'
                            ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : '#1e1e1e'
                      }}
                    >
                      {item?.orderStatus || 'N/A'}
                    </p>
                  </td>

                  {isIncluded('price') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {CurrencyFormatter(item?.price || 0) || 'N/A'}
                    </td>
                  )}
                  {isIncluded('callPrice') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {CurrencyFormatter(item?.callPrice || 0) || 'N/A'}
                    </td>
                  )}
                  {currentDashboard == 'jeras' && (
                    <th>
                      <td
                        className="canceled-button text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch"
                        onClick={() => openFormModal(item)}
                      >
                        <>
                          <button className=" flex items-center justify-center   bg-[#d5cae9] w-[70px] px-1 py-2 rounded-[4px]">
                            <Icon
                              className="text-[#7b6c96]"
                              icon="mingcute:transformation-fill"
                              width={29.5}
                              height={29.5}
                            />
                          </button>
                        </>
                      </td>
                    </th>
                  )}
                  {isIncluded('payWith') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.payWith || 'N/A'}
                    </td>
                  )}
                  {isIncluded('platform') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.platform || 'N/A'}
                    </td>
                  )}
                  {isIncluded('consultType') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.consultType || 'N/A'}
                    </td>
                  )}
                  {isIncluded('promoCodeId') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.promoCodeId || 'N/A'}
                    </td>
                  )}
                  {isIncluded('packageCallNum') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.packageCallNum || 'N/A'}
                    </td>
                  )}
                  {isIncluded('packageId') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.packageId || 'N/A'}
                    </td>
                  )}
                  {isIncluded('remainingCallNum') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.remainingCallNum || item?.remainingCallNum === 0
                        ? item?.remainingCallNum
                        : 'N/A'}
                    </td>
                  )}
                  {isIncluded('answeredCallNum') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {item?.answeredCallNum || item?.answeredCallNum === 0
                        ? item?.answeredCallNum
                        : 'N/A'}
                    </td>
                  )}

                  {isIncluded('date') && (
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      <SharedTime date={new Date(item?.utcTime)} />
                    </td>
                  )}
                  {isIncluded('userCreationDate') && (
                    <th>
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        <SharedTime date={new Date(item?.userCreationDate)} />
                      </td>
                    </th>
                  )}
                  {currentDashboard == 'jeras' && (
                    <th>
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch flex items-center justify-center">
                        <>
                          {item?.orderStatus !== 'cancel' && item?.orderStatus !== 'closed' ? (
                            <button
                              className="canceled-button flex items-center justify-center   bg-[#f7e1e1] w-[50px] px-1 py-2 rounded-[4px]"
                              onClick={() => handelChengeStutas(item?.orderId)}
                            >
                              <Icon
                                className="text-[#c43636]"
                                icon="material-symbols:close"
                                width={29.5}
                                height={29.5}
                              />
                            </button>
                          ) : (
                            'not changed'
                          )}
                        </>
                      </td>
                    </th>
                  )}
                  {currentDashboard == 'jeras' && (
                    <th>
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch flex items-center justify-center">
                        <>
                          {item?.activeApp === false ? (
                            <button
                              className="canceled-button flex items-center justify-center   bg-[#efecf3] w-[50px] px-1 py-2 rounded-[4px]"
                              onClick={() => handelAddAppointment(item?.orderId)}
                            >
                              <Icon
                                className=""
                                icon="solar:move-to-folder-bold"
                                width={29.5}
                                height={29.5}
                              />
                            </button>
                          ) : (
                            'not changed'
                          )}
                        </>
                      </td>
                    </th>
                  )}
                </tr>
              ))}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                #
              </th>
              {isIncluded('orderId') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="orderId"
                    text={useIntl().formatMessage({
                      id: 'orderId',
                      defaultMessage: 'order ID'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}

              {isIncluded('consultantUid') && (
                <th
                  className="text-center nowrap   text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consultantUid"
                    text={useIntl().formatMessage({
                      id: 'consultantUid',
                      defaultMessage: 'consultant id'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('consultantPhoto') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consultantPhoto"
                    text={useIntl().formatMessage({
                      id: 'TeacherPhoto',
                      defaultMessage: 'Teacher photo'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('consultantName') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consult.name"
                    text={useIntl().formatMessage({
                      id: 'TeacherName',
                      defaultMessage: 'Teacher Name'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('consultNumber') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consult.number"
                    text={useIntl().formatMessage({
                      id: 'consultNumber',
                      defaultMessage: 'consultant number'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('consultantCountry') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consult.countryCode"
                    text={useIntl().formatMessage({
                      id: 'consultantCountry',
                      defaultMessage: 'consultant Country'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('consultantISOCode') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consult.countryISOCode"
                    text={useIntl().formatMessage({
                      id: 'consultantISOCode',
                      defaultMessage: 'consultant ISOCode'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('userUid') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userUid"
                    text={useIntl().formatMessage({
                      id: 'userUid',
                      defaultMessage: 'user id'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('userPhoto') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userPhoto"
                    text={useIntl().formatMessage({
                      id: 'userPhoto',
                      defaultMessage: 'user photo'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('userName') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="user.name"
                    text={useIntl().formatMessage({
                      id: 'userName',
                      defaultMessage: 'user name'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('userNumber') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userNumber"
                    text={useIntl().formatMessage({
                      id: 'userNumber',
                      defaultMessage: 'user number'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('userCountry') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="user.countryCode"
                    text={useIntl().formatMessage({
                      id: 'userCountry',
                      defaultMessage: 'user country'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('userISOCode') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="user.countryISOCode"
                    text={useIntl().formatMessage({
                      id: 'userISOCode',
                      defaultMessage: 'user ISOCode'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('orderStatus') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="orderStatus"
                    text={useIntl().formatMessage({
                      id: 'orderStatus',
                      defaultMessage: 'order status'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('price') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="price"
                    text={useIntl().formatMessage({
                      id: 'price',
                      defaultMessage: 'price'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('callPrice') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="callPrice"
                    text={useIntl().formatMessage({
                      id: 'callPrice',
                      defaultMessage: 'call Price'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {currentDashboard === 'jeras' && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userCreationDate"
                    text={useIntl().formatMessage({
                      id: 'transformation',
                      defaultMessage: 'transformation'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('payWith') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="payWith"
                    text={useIntl().formatMessage({
                      id: 'paymentmethos',
                      defaultMessage: 'payment methos'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('platform') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="platform"
                    text={useIntl().formatMessage({
                      id: 'platform',
                      defaultMessage: 'platform'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('consultType') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="consultType"
                    text={useIntl().formatMessage({
                      id: 'consultType',
                      defaultMessage: 'consult Type'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('promoCodeId') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="promoCodeId"
                    text={useIntl().formatMessage({
                      id: 'promoCodeId',
                      defaultMessage: 'promocode id'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('packageCallNum') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="packageCallNum"
                    text={useIntl().formatMessage({
                      id: 'packageCallNum',
                      defaultMessage: 'package call number'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('packageId') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="packageId"
                    text={useIntl().formatMessage({
                      id: 'packageId',
                      defaultMessage: 'package Id'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('remainingCallNum') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="remainingCallNum"
                    text={useIntl().formatMessage({
                      id: 'remainingCallNum',
                      defaultMessage: 'remaining call number'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('answeredCallNum') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="answeredCallNum"
                    text={useIntl().formatMessage({
                      id: 'answeredCallNum',
                      defaultMessage: 'answered call number'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}

              {isIncluded('date') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="orderTimeValue"
                    text={useIntl().formatMessage({
                      id: 'orderTimeValue',
                      defaultMessage: 'order time value'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('userCreationDate') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userCreationDate"
                    text={useIntl().formatMessage({
                      id: 'userCreationDate',
                      defaultMessage: 'user creation date'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {currentDashboard == 'jeras' && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userCreationDate"
                    text={useIntl().formatMessage({
                      id: 'chengeStatus',
                      defaultMessage: 'chenge status'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {currentDashboard == 'jeras' && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="userCreationDate"
                    text={useIntl().formatMessage({
                      id: 'activeAppointment',
                      defaultMessage: 'active Appointment'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
            </>
          )}
          onApply={(startDate, endDate) => {
            setStart(startDate);
            setEnd(endDate);
          }}
          buttonExport={() => ExportXlsx(data, customize_keys)}
          buttonExportAll={() => ExportAllDataXlsx()}
          pagination={{ ...pagination, search_key: filters.search }}
          onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
          onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
          ChangePerPageLimit={(e: any) => {
            if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
          }}
          searchProps={{
            onKeyDown(e: any) {
              if (e.key === 'Enter' && e.target.value) {
                performSearch(e.target.value);
              }
            }
          }}
        />
      </div>
      {
        <FormModel
          isFormModalOpen={isFormModalOpen}
          dashColor={dashColor}
          data={currentData}
          setIsFormModalOpen={setIsFormModalOpen}
        />
      }
    </div>
  );
}
function FormModel({ isFormModalOpen, setIsFormModalOpen, dashColor, data }) {
  const { width, height } = useWindowDimensions();
  const [price, setPrice] = useState(data?.price || 0);
  const [callPrice, setCallPrice] = useState(data?.callPrice);
  const [packageCallNum, setPackageCallNum] = useState(data?.packageCallNum || 0);
  const [remainingCallNum, setRemainingCallNum] = useState(data?.remainingCallNum || 0);
  const [answeredCallNum, setAnsweredCallNum] = useState(data?.answeredCallNum || 0);
  const [orderId, setOrderId] = useState(data?.orderId || 0);
  const [phone, setPhone] = useState(data?.consult?.phone || '');
  console.log('remainingCallNum', remainingCallNum);

  useEffect(() => {
    setPrice(data?.price || 0);
    setCallPrice(data?.callPrice || 0);
    setPackageCallNum(data?.packageCallNum || 0);
    setRemainingCallNum(data?.remainingCallNum || 0);
    setAnsweredCallNum(data?.answeredCallNum || 0);
    setPhone(data?.consult?.phone || '');
    setOrderId(data?.orderId || 0);
  }, [data]);

  const reset = () => {
    setPrice(data?.price || 0);
    setCallPrice(data?.callPrice || 0);
    setPackageCallNum(data?.packageCallNum || 0);
    setRemainingCallNum(data?.remainingCallNum || 0);
    setAnsweredCallNum(data?.answeredCallNum || 0);
    setPhone(data?.consult?.phone || '');
    setOrderId(data?.orderId || 0);
    setIsFormModalOpen(false);
  };

  useEffect(() => {
    if (packageCallNum && callPrice) {
      setPrice(packageCallNum * callPrice);
    }
  }, [packageCallNum, callPrice]);

  const showConfirmation = useConfirmationDialog();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = 'Are you sure you want to change Order Data?';

    showConfirmation(message, async () => {
      try {
        const reqBody = {
          price,
          callPrice,
          packageCallNum,
          remainingCallNum,
          answeredCallNum,
          phone,
          orderId,
          orderStatus: data.orderStatus
        };

        await axiosInstance.patch('/dashboard/editOrders/', reqBody);

        setIsFormModalOpen(false);
        reset();

        const successMessage = 'Order Data changed successfully';
        toast.success(successMessage);
      } catch (err) {
        toast.error('Something went wrong');
        console.log('error', err);
      }
    });
  };

  return (
    <>
      <Modal
        visible={isFormModalOpen}
        handleClose={reset}
        title={useIntl().formatMessage({
          id: 'Transformation',
          defaultMessage: 'Transformation'
        })}
      >
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-[24px] justify-start items-start bg-[#fff] flex-grow-0"
        >
          <div className="space-y-4">
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="price"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'price:',
                  defaultMessage: 'price:'
                })}{' '}
              </label>
              <input
                id="price"
                type="number"
                name="price"
                value={price}
                disabled
                onChange={(e) => setPrice(e.target.value)}
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter price"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              />
            </div>
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="callPrice"
                className="nowrap font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'callPrice:',
                  defaultMessage: 'Call Price:'
                })}{' '}
              </label>
              <input
                id="callPrice"
                type="number"
                name="callPrice"
                value={callPrice}
                onChange={(e) => setCallPrice(Number(e.target.value))}
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter Call Price"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              />
            </div>

            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="packageCallNum"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'packCallNum:',
                  defaultMessage: 'Package call number:'
                })}{' '}
              </label>
              <input
                id="packageCallNum"
                type="number"
                name="packageCallNum"
                value={packageCallNum}
                disabled
                onChange={(e) => setPackageCallNum(e.target.value)}
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter Package Call Number"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              />
            </div>

            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="answeredCallNum"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'answeredCall:',
                  defaultMessage: 'Answered Call:'
                })}{' '}
              </label>
              <input
                id="answeredCallNum"
                type="number"
                name="answeredCallNum"
                value={answeredCallNum}
                disabled
                onChange={(e) => setAnsweredCallNum(e.target.value)}
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter Answered Call Number"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              />
            </div>

            {
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
                style={{
                  width: `${Responsive.w(812, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="remainingCallNum"
                  className="font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'remainingCall:',
                    defaultMessage: 'Remaining Call:'
                  })}{' '}
                </label>
                <input
                  id="remainingCallNum"
                  type="number"
                  max={packageCallNum - answeredCallNum}
                  min={0}
                  disabled={packageCallNum == 1 && answeredCallNum == 0}
                  name="remainingCallNum"
                  value={remainingCallNum}
                  onChange={(e) => setRemainingCallNum(Number(e.target.value))}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  placeholder="Enter Remaining Call Number"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
            }

            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="phoneNumber"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'phoneNumber:',
                  defaultMessage: 'Phone Number or UID:'
                })}{' '}
              </label>
              <input
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter Phone Number or UID"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-4 w-full">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-[#7b6c96] px-4 py-2 text-sm font-medium text-white shadow-sm"
              style={{
                backgroundColor: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'update',
                defaultMessage: 'Update'
              })}
            </button>

            <button
              type="button"
              className="flex justify-center items-center gap-[18px] border-solid border-[1px] border-[#d91c1f] bg-[#ffe2e2] text-[#d91c1f] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
              onClick={reset}
            >
              <span>
                {useIntl().formatMessage({
                  id: 'cancel',
                  defaultMessage: 'Cancel'
                })}
              </span>
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
function TableCustomizeFilterModal({ SendPrams, params, setIsModalOpen, initialQueries }: any) {
  const { keys, customize_keys, filters, data } = useSelector((state: RootState) => state.Orders);

  const dispatch = useDispatch();
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const handleOpenModal = () => {
    // ChangeFilter('clearSorts', 'sorts');
    // Step 1: Open the modal and set sorts to empty
    SendPrams(initialQueries);
    setIsModalOpen(true);
    setVisible(true);
  };
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const [visible, setVisible] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   SendPrams([
            //     ['orderTimeValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
            //     ...initialQueries
            //   ])
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                SendPrams(initialQueries);
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              disabled={initialQueries.length == 0 && params.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            {/* <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => ExportXlsx(data, customize_keys)}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'exportExcel',
                  defaultMessage: 'Export Excel'
                })}
              </span>
            </button> */}
            <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={handleOpenModal}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'filterAndCustomizeTable',
                  defaultMessage: 'Filter & Customize table'
                })}
              </span>
            </button>
          </div>
        </div>
      </div>
      <SideModal
        title={useIntl().formatMessage({
          id: 'filterAndCustomizeTable',
          defaultMessage: 'Filter & Customize table'
        })}
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setIsModalOpen(false);
        }}
        size="max-w-screen-sm"
      >
        <div className="space-y-4 flex flex-col overflow-y-auto">
          <div className="border-b border-b-gray-200 overflow-x-auto grid">
            <ul className="inline-flex">
              {tabs.map((tab: any, index: number) => (
                <li key={index}>
                  <button
                    className={[
                      'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                      currentTab === tab.key
                        ? '!text-gray-800 border-b-2 border-b-gray-800'
                        : '!text-gray-500'
                    ].join(' ')}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 grid overflow-y-auto">
            {currentTab === 'customize' ? (
              <ul className="space-y-2">
                {keys.map((field: string, index: number) => (
                  <li key={index}>
                    <label
                      htmlFor={field + '-' + index}
                      className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        name={field + '-' + index}
                        id={field + '-' + index}
                        className="form-checkbox shrink-0"
                        value={field}
                        checked={customize_keys.some((key) => key === field)}
                        onChange={ChangeCustomizeKey}
                      />

                      <p className="text-sm font-medium capitalize flex-1">
                        {useIntl().formatMessage({
                          id: field,
                          defaultMessage: field
                        })}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <FilterFields
                setIsModalOpen={setIsModalOpen}
                onSubmit={SendPrams}
                filters={filters}
                onReset={() => {
                  SendPrams([]);
                  setIsModalOpen(false);
                }}
                handleClose={setVisible}
              />
            )}
          </div>
        </div>
      </SideModal>
    </>
  );
}

type Fields = 'price' | 'date' | 'orderStatus';
type DateType =
  | 'today'
  | 'last-day'
  | 'last-week'
  | 'this-week'
  | 'last-month'
  | 'last-3-month'
  | 'last-6-months'
  | 'this-year'
  | 'last-year';

 function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor
}: any) {
  const dispatch = useDispatch();
  const periods = usePeriods();
  const [filters, setFilters] = useState<any>(values.queries || []);
  console.log('Filters from filterfields', filters);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  const handleOnClearClick = () => {
    onReset();
    setFilters([]);
    setSelectedFields([]);
  };

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'price':
            // If "price" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;

          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'orderTimeValue', '==', from + '-' + to);

            break;
          default:
            result = findQuery(key, '==', value);

            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }
  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }
  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);
  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        // setIsModalOpen(false);
        onSubmit(query);
        // handleClose(false);
        // console.log('oooo');
      }}
    >
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="price"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('price')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="price-amount"
          >
            {useIntl().formatMessage({
              id: 'price',
              defaultMessage: 'price'
            })}
          </label>
          <input
            type="number"
            className="form-input"
            name="price-amount"
            id="price-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('price')}
            defaultValue={findValue('price')}
            disabled={!selectedFields.includes('price')}
            readOnly={!selectedFields.includes('price')}
          />
        </div>
      </div>

      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="orderStatus"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('orderStatus')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="order-status"
          >
            {useIntl().formatMessage({
              id: 'orderStatus',
              defaultMessage: 'order status'
            })}
          </label>
          <select
            name="order-status"
            id="order-status"
            className="form-select"
            onChange={ChangeFilters('orderStatus')}
            defaultValue={findValue('orderStatus') || ''}
            disabled={!selectedFields.includes('orderStatus')}
          >
            <optgroup label="Status">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'status',
                  defaultMessage: 'status'
                })}
              </option>

              <option value="open">
                {useIntl().formatMessage({
                  id: 'open',
                  defaultMessage: 'open'
                })}
              </option>
              <option value="cancel">
                {useIntl().formatMessage({
                  id: 'cancel',
                  defaultMessage: 'cancel'
                })}
              </option>
              <option value="closed">
                {useIntl().formatMessage({
                  id: 'closed',
                  defaultMessage: 'closed'
                })}
              </option>
              <option value="completed">
                {useIntl().formatMessage({
                  id: 'completed',
                  defaultMessage: 'completed'
                })}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="date"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('date')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="date"
          >
            {useIntl().formatMessage({
              id: 'date',
              defaultMessage: 'date'
            })}
          </label>
          <select
            name="date"
            id="date"
            className="form-select"
            onChange={ChangeFilters('date')}
            defaultValue={findValue('date') || ''}
            disabled={!selectedFields.includes('date')}
          >
            <optgroup label="Periods">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'selectPeriod',
                  defaultMessage: 'select period'
                })}
              </option>

              {usePeriods().map((period: any, index: number) => (
                <option value={period.value}>{period.label}</option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      <div className="flex gap-2 flex-wrap justify-between">
        <button
          className="btn-with-icon "
          style={{
            backgroundColor: dashColor
          }}
          type="submit"
        >
          <span>
            {' '}
            {useIntl().formatMessage({
              id: 'ApplyFilter',
              defaultMessage: 'Apply Filter'
            })}
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={() => {
            onReset();
            setFilters([]);
            setSelectedFields([]);
          }}
        >
          <span>
            {' '}
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
      </div>
    </form>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
