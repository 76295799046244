import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import SideModal from 'src/components/shared/SideModal';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import {
  ChangeCustomizeKeys,
  UpdateFilter,
  UpdateResult,
  AddPromoCode,
  UpdatePromoCodeById
} from 'src/reducers/promocodes';
import SortButton from 'src/components/shared/SortButton';
import voucherCodes from 'voucher-code-generator';
import { useAlert } from 'src/hooks/alerts';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import { useSearchParams } from 'react-router-dom';
import Modal from 'src/components/shared/Modal';
import RangePicker from 'src/components/shared/RangePicker';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDates } from 'src/helper/DateUtils';
import { Fields, IFilters } from 'src/types/types';
import { periods } from 'src/constants';
import usePeriods from 'src/hooks/usePeriods';
import { toast } from 'react-toastify';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/Edit.png';
import img_2_2 from '../images/Edit@2x.png';
import img_2_3 from '../images/Edit@3x.png';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { usePermissions } from 'src/hooks/usePeermissions';
import * as xlsx from 'exceljs';
function useHooks() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  let initialQueries: any = [];
  if (status == 'active') {
    initialQueries = [['promoCodeStatus', '==', 'true']];
  } else if (status == 'inactive') {
    [['promoCodeStatus', '==', 'false']];
  } else {
    [];
  }
  const { filters: userFilters, data: currentUsers } = useSelector(
    (state: RootState) => state.PromoCodes
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<IFilters>({
    ...userFilters,
    page: 1,
    perPage: 15,
    sorts: [],
    queries: initialQueries,
    search: undefined
  });

  useEffect(() => {
    GetPromoCodes();
  }, [filters, searchParams]);

  async function GetPromoCodes(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);
      const parameters = {
        ...filters,
        ...params,
        sorts: isModalOpen ? [] : filters.sorts // Initialize sorts array when modal is open
      };
      console.log('?', parameters);
      const { data } = await axiosInstance.get('promocodes', {
        params: parameters
      });
      console.log('pp', data.result.data[0]);

      dispatch(UpdateResult(data.result));
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 14,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }
      const sorts = isModalOpen ? [] : result.sorts;

      setFilters({ ...result, sorts });
      dispatch(UpdateFilter({ ...result, sorts }));
    },
    [filters, initialQueries, isModalOpen, dispatch]
  );

  return { isLoading, ChangeFilter, filters, setIsModalOpen, initialQueries };
}

export default function index() {
  const { width, height } = useWindowDimensions();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const intl = useIntl();
  const { isLoading, ChangeFilter, filters, setIsModalOpen, initialQueries } = useHooks();
  const [title, setTitle] = useState<any>('allPromoCodes');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isDeleting, setIsDeleting] = useState(false);
  const Permission = usePermissions();
  const [isloading, setIsloading] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [showTeachers, setShowTeachers] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.PromoCodes
  );
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  const toggleTeachers = () => {
    setShowTeachers((prevShowOptions) => !prevShowOptions);
  };
  const dispatch = useDispatch();
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);
  const [currentCode, setCurrentCode] = useState<any | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);

  const initialFormValues = {
    code: '',
    discount: 0,
    ownerName: '',
    promoCodeStatus: false,
    type: '',
    usedNumber: 0,
    promoType: '',
    consultUid: ''
  };
  const initialError = {
    code: '',
    discount: '',
    ownerName: '',
    type: '',
    usedNumber: '',
    promoType: '',
    consultUid: ''
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [error, setError] = useState<any>(initialError);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  console.log('isChecked', isChecked);

  // تزامن promoCodeStatus مع isChecked عند تغييره
  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, promoCodeStatus: isChecked }));
  }, [isChecked, isFormModalOpen]);
  useEffect(() => console.log('A', formValues), [formValues]);
  useEffect(() => console.log('current code', currentCode), [currentCode]);
  useEffect(() => {
    if (currentCode) {
      setIsChecked(currentCode.promoCodeStatus);
      setFormValues({
        code: currentCode?.code || '',
        discount: currentCode?.discount || 0,
        ownerName: currentCode?.ownerName || '',
        type: currentCode?.type || '',
        usedNumber: currentCode?.usedNumber || 0,
        promoCodeStatus: currentCode?.promoCodeStatus || false,
        promoType: currentCode?.promoType || '',
        consultUid: currentCode?.consultUid || ''
      });
    }
  }, [currentCode, isFormModalOpen]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };
  console.log('formValues', formValues);
  console.log('error', error);
  let initialConsult: any = [
    ['userType', '==', 'CONSULTANT'],
    ['accountStatus', '==', 'Active']
  ];
  async function getUsers() {
    try {
      setIsloading(true);
      const res = await axiosInstance.get(`/consultants`, {
        params: {
          queries: initialConsult
        }
      });
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('users not found');
    }
    setIsloading(false);
  }
  const validateForm = () => {
    let valid = true;
    let newErrors = { ...initialError }; // Start with an empty error object

    if (formValues.code.trim() === '') {
      newErrors.code = 'Promo code is required';
      valid = false;
    } else if (data.some((item) => formValues.code.trim() === item?.code && !currentCode)) {
      newErrors.code = 'Promo code is already used';
      valid = false;
    }

    if (formValues.ownerName.trim() === '') {
      newErrors.ownerName = 'Owner name is required';
      valid = false;
    }

    if (formValues.promoType.trim() === '') {
      newErrors.promoType = 'Discount type is required';
      valid = false;
    }

    if (formValues.discount === 0) {
      newErrors.discount = 'Discount is required and must be greater than 0';
      valid = false;
    }

    if (formValues.type.trim() === '') {
      newErrors.type = 'Type is required';
      valid = false;
    }

    if (formValues.type === 'newUserPromo' && formValues.consultUid.trim() === '') {
      newErrors.consultUid = 'Consultant is required for this promo type';
      valid = false;
    }

    setError(newErrors); // Update the error state with the new error messages
    return valid;
  };

  useEffect(() => {
    getUsers();
  }, [isFormModalOpen]);
  const handleSearch = async () => {
    try {
      setIsloading(true);

      let queryParams; // Define queryParams variable to store query parameters

      if (/^[+]?[0-9]+$/.test(searchQuery)) {
        // Check if the input is a phone number (only numbers and/or '+')
        queryParams = [['phoneNumber', '>=', searchQuery + '~']];
      } else {
        // Search in name field
        queryParams = [['name', '>=', searchQuery + '~']];
      }

      const res = await axiosInstance.get('/consultants', {
        params: {
          queries: queryParams // Pass queryParams as the value for the 'queries' parameter
        }
      });

      console.log('Users=====>', res.data.result.data);
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('Error fetching users:', error);
      // Handle error
    } finally {
      setIsloading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (Object.keys(formValues).length > 0) {
      try {
        if (!currentCode) {
          console.log('Form values on submit:', {
            ...formValues,
            discount: Number(formValues.discount),
            usedNumber: Number(formValues.usedNumber)
          });

          const body = {
            ...formValues,
            discount: Number(formValues.discount)
          };
          console.log('body', body);
          const response = await axiosInstance.post(`/promocodes`, body);
          dispatch(AddPromoCode(response.data.result.data));
          console.log('ADD success:', response.data.result.data);
          toast.success('Promo code has been added successfully');
        } else if (currentCode.promoCodeId) {
          console.log('Form values on submit:', formValues);
          const body = {
            ...formValues,
            discount: Number(formValues.discount),
            usedNumber: Number(formValues.usedNumber)
          };
          const response = await axiosInstance.patch(`/promocodes/${currentCode.promoCodeId}`, {
            ...formValues,
            discount: Number(formValues.discount),
            usedNumber: Number(formValues.usedNumber)
          });
          dispatch(UpdatePromoCodeById({ id: currentCode.promoCodeId, newData: body }));
          console.log('Update success:', response.data);
          toast.success('Promo code has been updated successfully');
        }

        closeModal();
        ChangeFilter(null, 'clear');
        // Handle any post-update logic here (e.g., refreshing consultant details)
      } catch (error) {
        console.error('Error updating consultant details:', error);
        toast.error('An error has occurred');

        // Handle error (e.g., display error message to the user)
      }
    } else {
      console.log('No changes to save.');
      // Optionally close the modal or notify the user that no changes were detected
    }
  };

  const closeModal = () => {
    setIsFormModalOpen(false);
    setCurrentCode(null);
    setFormValues(initialFormValues);
    setError(initialError);
  };

  const openFormModal = (code) => {
    setIsFormModalOpen(true);
    setCurrentCode(code);
    if (code) setFormValues(code);
  };
  const formatDate = (timestamp: { _seconds: number; _nanoseconds: number }): string => {
    const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
    const date = new Date(milliseconds);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const showConfirmation = useConfirmationDialog();
  const handleDeletePromo = async (promoCodeId) => {
    const confirmationMassage = intl.formatMessage({
      id: 'deletePromoCode',
      defaultMessage: 'Are you sure you want to delete this promo code?'
    });
    showConfirmation(confirmationMassage, async () => {
      try {
        setIsDeleting(true);
        await axiosInstance.delete(`/promocodes/${promoCodeId}`);
        const successMessage = intl.formatMessage({
          id: 'promoDeletedMsg',
          defaultMessage: 'Code has been deleted successfully'
        });
        toast.success(successMessage);
        ChangeFilter(null, 'clear');
        closeModal();
      } catch (error) {
        const errMessage = intl.formatMessage({
          id: 'error',
          defaultMessage: 'An Error has happened!'
        });
        toast.error(errMessage);
      } finally {
        setIsDeleting(false);
      }
    });
  };
  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        'promocodes/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  async function ExportAllDataXlsx() {
    try {
      setExportDisabled(true);

      // طلب جميع البيانات بدون تطبيق `perPage`
      const res = await axiosInstance.get('promocodes', {
        params: {
          ...filters,
          page: 1,
          perPage: count // طلب جميع البيانات
        }
      });

      // تحويل الكائنات إلى نصوص لتجنب [object Object]
      const processedData = res.data.result.data?.map((item: any) => {
        const newItem = { ...item };
        // تحويل أي كائنات داخل البيانات إلى نصوص JSON أو استخراج الحقول المطلوبة
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key] === 'object' && newItem[key] !== null) {
            // تحويل الكائنات إلى نصوص JSON
            newItem[key] = JSON.stringify(newItem[key]);
          }
        });
        return newItem;
      });

      // تحديد المفاتيح المطلوبة فقط بناءً على `customize_keys`
      const columnsToExport = customize_keys.map((key: string) => ({
        header: key,
        key: key,
        width: key === 'orderTimeValue' ? 10 : 20 // تقليل عرض orderTimeValue
      }));

      // إنشاء ملف Excel من البيانات المعالجة
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('All Data');

      // إضافة الأعمدة بناءً على المفاتيح في `customize_keys`
      worksheet.columns = columnsToExport;

      // إضافة الصفوف من البيانات المعالجة
      processedData.forEach((item: any) => {
        const timestamp =
          typeof item.promoCodeTimestamp === 'string'
            ? JSON.parse(item.promoCodeTimestamp)
            : item.promoCodeTimestamp;
        console.log('item.promoCodeTimestamp', formatDate(timestamp));

        const rowData = customize_keys.reduce((obj: any, key: string) => {
          if (key === 'promoCodeTimestamp') {
            if (item?.promoCodeTimestamp) {
              const timestamp =
                typeof item.promoCodeTimestamp === 'string'
                  ? JSON.parse(item.promoCodeTimestamp)
                  : item.promoCodeTimestamp;

              obj[key] = formatDate(timestamp);
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'usedNumber') {
            obj[key] = item?.usedNumber || 0;
          } else if (key === 'promoCodeStatus') {
            obj[key] = item.promoCodeStatus ? 'Active' : 'Not Active';
          } else {
            obj[key] = item[key] || 'N/A';
          }
          // إضافة القيم المطلوبة فقط

          return obj;
        }, {});
        worksheet.addRow(rowData);
      });

      // إرسال الملف كـ Excel
      const filename = new Date().getTime() + '_all_data.xlsx';
      await workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  const allPromoCodesMessage = 'allPromoCodes';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    let filterQueries = [...initialQueries]; // Start with initial queries

    // Check if the option is 'All Promo codes'
    if (option === 'All Promo codes') {
      // If 'All Promo codes', no date filtering is applied
      ChangeFilter(null, 'clear');
      return; // Exit the function early
    }

    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['promoCodeTimestamp', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const hasEditOrDeletePermission = (item) => {
    return item?.permission?.includes('Edit') || item?.permission?.includes('Delete');
  };
  const hasDeletePermission = (item) => {
    return item?.permission?.includes('Delete');
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  return (
    <>
      {isFormModalOpen && (
        <Modal
          visible={isFormModalOpen}
          handleClose={closeModal}
          title={currentCode ? 'Edit Promo Code' : 'Add Promo Code'}
        >
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-[24px]  justify-start items-start  ] bg-[#fff] flex-grow-0"
            // style={{
            //   width:`${Responsive.w(812,width)}px`,
            //   height:`${Responsive.h(708.4,height)}px`
            // }}
          >
            <div className="space-y-4">
              <>
                {/* code */}
                <div
                  className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                  style={{
                    width: `${Responsive.w(748, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                >
                  <label
                    htmlFor="callNum"
                    className=" font-[Montserrat] font-semibold text-left"
                    style={{
                      width: `${Responsive.w(64, width)}px`,
                      color: dashColor,
                      height: `${Responsive.h(29, height)}px`,
                      fontSize: `${Responsive.sp(24, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'code',
                      defaultMessage: 'code'
                    })}
                  </label>
                  <input
                    id="code"
                    type="text"
                    name="code"
                    value={formValues.code}
                    onChange={handleInputChange}
                    className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                    placeholder="Enter number of calls"
                    style={{
                      width: `${Responsive.w(532, width)}px`,
                      height: `${Responsive.h(63, height)}px`
                    }}
                  />
                </div>
                {formValues.code.trim() === '' && error.code && (
                  <p className="text-red-500">{error.code}</p>
                )}
                {/* usedNumber */}
                {/* <div
                  className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                  style={{
                    width: `${Responsive.w(748, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                >
                  <label
                    htmlFor="callNum"
                    className=" nowrap font-[Montserrat] font-semibold text-left"
                    style={{
                      width: `${Responsive.w(64, width)}px`,
                      color: dashColor,
                      height: `${Responsive.h(29, height)}px`,
                      fontSize: `${Responsive.sp(24, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'usedNumber',
                      defaultMessage: 'used number'
                    })}
                  </label>
                  <input
                    id="usedNumber"
                    type="text"
                    name="usedNumber"
                    value={formValues.usedNumber}
                    onChange={handleInputChange}
                    className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                    placeholder="Enter number of calls"
                    style={{
                      width: `${Responsive.w(532, width)}px`,
                      height: `${Responsive.h(63, height)}px`
                    }}
                  />
                </div>
                {error.usedNumber && <p className="text-red-500">{error.usedNumber}</p>} */}
              </>
              {/* ownerName  */}
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="callNum"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'owner',
                    defaultMessage: 'owner'
                  })}
                </label>
                <input
                  id="owner"
                  type="text"
                  name="ownerName"
                  value={formValues.ownerName}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  placeholder="Enter number of calls"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
              {formValues.ownerName.trim() === '' && error.ownerName && (
                <p className="text-red-500">{error.ownerName}</p>
              )}
              {/* promoType */}
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="type"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'discountType',
                    defaultMessage: 'Discount Type'
                  })}
                </label>
                <select
                  id="promoType"
                  name="promoType"
                  value={formValues.promoType}
                  onChange={handleInputChange}
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                >
                  <>
                    <option value="">
                      {useIntl().formatMessage({
                        id: 'selectpromoType',
                        defaultMessage: ' select Discount Type'
                      })}
                    </option>
                    <option value="discount">
                      {useIntl().formatMessage({
                        id: 'discount',
                        defaultMessage: 'discount'
                      })}
                    </option>
                    <option value="fixedPrice">
                      {useIntl().formatMessage({
                        id: 'FixdPrice',
                        defaultMessage: 'Fixd Price'
                      })}
                    </option>
                  </>
                </select>
              </div>
              {formValues.promoType.trim() === '' && error.promoType && (
                <p className="text-red-500">{error.promoType}</p>
              )}
              {/* discount  */}
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="discount"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'discount',
                    defaultMessage: 'discount'
                  })}
                </label>
                <input
                  id="discount"
                  type="number"
                  name="discount"
                  value={formValues.discount}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  placeholder="Enter discount percentage"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
              {formValues.discount === 0 && error.discount && (
                <p className="text-red-500">{error.discount}</p>
              )}
              {/* type */}
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="type"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'type',
                    defaultMessage: 'type'
                  })}
                </label>
                <select
                  id="type"
                  name="type"
                  value={formValues.type}
                  onChange={handleInputChange}
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                >
                  <>
                    <option value="">
                      {useIntl().formatMessage({
                        id: 'selectType',
                        defaultMessage: 'select type'
                      })}
                    </option>
                    <option value="default">
                      {useIntl().formatMessage({
                        id: 'default',
                        defaultMessage: 'default'
                      })}
                    </option>
                    <option value="primary">
                      {useIntl().formatMessage({
                        id: 'primary',
                        defaultMessage: 'primary'
                      })}
                    </option>
                    <option value="newUserPromo">
                      {useIntl().formatMessage({
                        id: 'consultant',
                        defaultMessage: 'Consultant'
                      })}
                    </option>
                  </>
                </select>
              </div>
              {formValues.type.trim() === '' && error.type && (
                <p className="text-red-500">{error.type}</p>
              )}
              {/* consultant */}
              {formValues.type == 'newUserPromo' && currentDashboard === 'dream' && (
                <div
                  className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                  style={{
                    width: `${Responsive.w(748, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                >
                  <label
                    htmlFor="consultUid"
                    className=" font-[Montserrat] font-semibold text-left"
                    style={{
                      width: `${Responsive.w(64, width)}px`,
                      color: dashColor,
                      height: `${Responsive.h(29, height)}px`,
                      fontSize: `${Responsive.sp(24, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'cousult',
                      defaultMessage: 'cousult'
                    })}
                  </label>
                  <select
                    id="consultUid"
                    name="consultUid"
                    value={formValues.consultUid}
                    onChange={handleInputChange}
                    style={{
                      width: `${Responsive.w(532, width)}px`,
                      height: `${Responsive.h(63, height)}px`
                    }}
                    className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  >
                    <>
                      <option value="">
                        {useIntl().formatMessage({
                          id: 'selectconsultant',
                          defaultMessage: 'select consultant'
                        })}
                      </option>
                      {users.map((user: any, index) => {
                        return (
                          <option
                            key={index}
                            value={user.uid}
                          >
                            {user.name}
                          </option>
                        );
                      })}
                    </>
                  </select>
                </div>
              )}

              {/* consultant */}
              {formValues.type == 'newUserPromo' && currentDashboard === 'jeras' && (
                <div
                  className="relative flex flex-row justify-between items-center p-0 flex-grow-0 "
                  style={{
                    width: `${Responsive.w(748, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                >
                  <label
                    htmlFor="consultUid"
                    className=" font-[Montserrat] font-semibold text-left"
                    style={{
                      width: `${Responsive.w(64, width)}px`,
                      color: dashColor,
                      height: `${Responsive.h(29, height)}px`,
                      fontSize: `${Responsive.sp(24, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'cousult',
                      defaultMessage: 'cousult'
                    })}
                  </label>
                  <button
                    type="button"
                    onClick={toggleTeachers}
                    style={{
                      width: `${Responsive.w(532, width)}px`,
                      height: `${Responsive.h(63, height)}px`
                    }}
                    className="relative flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  >
                    <p>
                      {isloading ? (
                        <Spinner />
                      ) : formValues.consultUid ? (
                        users.find((user: any) => user.uid === formValues.consultUid)?.name ||
                        useIntl().formatMessage({
                          id: 'selectconsultant',
                          defaultMessage: 'Select consultant'
                        })
                      ) : (
                        useIntl().formatMessage({
                          id: 'selectconsultant',
                          defaultMessage: 'Select consultant'
                        })
                      )}
                    </p>
                    <span className="absolute right-0 top-[10px] ">
                      <svg
                        className={
                          showTeachers
                            ? '  transform rotate-180 !text-[#7b6c96]'
                            : ' transform rotate-0 !text-[#7b6c96]'
                        }
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                  {showTeachers && (
                    <div
                      className="absolute top-[105%] left-[29%] rounded-[8px] overflow-y-auto overflow-x-hidden z-10 bg-[#fff]  p-2 flex flex-col justify-start items-stretch flex-grow "
                      style={{
                        width: `${Responsive.w(532, width)}px`,
                        height: `${Responsive.h(400, height)}px`,
                        gap: `${Responsive.sp(12, width)}px`,
                        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
                      }}
                    >
                      <div
                        className=" bg-[#f9fafb] h-[37px] inline-flex items-center  py-2  px-4  border border-neutral-200"
                        style={{
                          width: `${Responsive.w(400, width)}px`,
                          height: `${Responsive.h(37, height)}px`,
                          gap: `${Responsive.sp(10, width)}px`,
                          borderRadius: `${Responsive.sp(8, width)}px`
                        }}
                      >
                        <span className="shrink-0 inline text-neutral-500">
                          <Icon
                            icon="ri:search-line"
                            style={{
                              width: `${Responsive.w(13.3, width)}px`,
                              height: `${Responsive.h(13.3, height)}px`
                            }}
                          />
                        </span>

                        <input
                          type="text"
                          className="form-input !bg-transparent text-[14px] font-[Montserrat] border-none py-0"
                          style={{
                            width: `${Responsive.w(400, width)}px`,
                            height: `${Responsive.h(21, height)}px`,
                            fontSize: `${Responsive.sp(14, width)}px`
                          }}
                          placeholder={useIntl().formatMessage({
                            id: 'Search',
                            defaultMessage: 'Search Teacher'
                          })}
                          autoComplete="off"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && searchQuery.trim() !== '') {
                              handleSearch(); // Call handleSearch function when Enter key is pressed
                            }
                          }}
                        />
                        <span
                          className={`cursor-pointer bg-[${dashColor}]`}
                          style={{
                            borderRadius: ' 8px',
                            color: 'white',
                            paddingInline: '10px'
                          }}
                          onClick={(e) => {
                            if (searchQuery.trim() !== '') {
                              handleSearch(); // Call handleSearch function when Enter key is pressed
                            }
                          }}
                        >
                          search
                        </span>
                      </div>
                      {isloading ? (
                        <div>
                          <Spinner />
                        </div>
                      ) : (
                        <>
                          {users?.map((user: any, index: number) => (
                            <label
                              key={index}
                              className="flex  justify-between items-center p-0   cursor-pointer hover:bg-[#d4d7d8]"
                              style={{
                                height: `${Responsive.h(16, height)}px`,
                                fontSize: `${Responsive.sp(14, width)}px`
                              }}
                            >
                              {user && (
                                <>
                                  <span
                                    className="text-left text-[#111928] font-medium"
                                    style={{
                                      // height: `${Responsive.h(14, height)}px`,
                                      fontSize: `${Responsive.sp(14, width)}px`
                                    }}
                                  >
                                    {/* if  */}
                                    {user?.name}
                                  </span>
                                  <input
                                    className="checked:!bg-[#7b6c96] "
                                    type="checkbox"
                                    value={user?.uid}
                                    checked={formValues.consultUid === user?.uid}
                                    onChange={() =>
                                      setFormValues({ ...formValues, consultUid: user?.uid })
                                    }
                                  />
                                </>
                              )}
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {error.consultUid && <p className="text-red-500">{error.consultUid}</p>}
              {/* active */}
              <div className="flex justify-between">
                <label
                  htmlFor="type"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'active',
                    defaultMessage: 'active'
                  })}
                </label>
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-8 w-14 rounded-full ${
                        isChecked
                          ? currentDashboard === 'dream'
                            ? 'bg-[#a12f88]'
                            : 'bg-[#7b6c96]'
                          : 'bg-gray-200'
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                        isChecked ? 'translate-x-full' : ''
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>

            <div className="flex justify-between items-center mt-4 w-full">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-[#7b6c96] px-4 py-2 text-sm font-medium text-white shadow-sm "
                style={{
                  backgroundColor: dashColor
                }}
              >
                {currentCode
                  ? useIntl().formatMessage({
                      id: 'update',
                      defaultMessage: 'Update'
                    })
                  : useIntl().formatMessage({
                      id: 'addPromoCode',
                      defaultMessage: 'Add Promo Code'
                    })}
              </button>
              {currentCode && (
                <button
                  type="button"
                  onClick={() => handleDeletePromo(currentCode.promoCodeId)}
                  className="flex justify-center rounded-md itms-center gap-[18px] border-solid border-[1px] border-[#d91c1f]  bg-[#ffe2e2] text-[#d91c1f] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
                >
                  <img
                    src={img_1}
                    srcSet={`${img_1_2}, ${img_1_3}`}
                    className="object-contain p-0 w-[14] h-[14px] m-auto"
                    alt="Description of the image"
                  />
                  <span>
                    {useIntl().formatMessage({
                      id: 'delete',
                      defaultMessage: 'Delete'
                    })}
                  </span>
                </button>
              )}
            </div>
          </form>
        </Modal>
      )}

      <div className="p-8 pt-12 space-y-4">
        {/* <TableCustomizeFilterModal
          SendPrams={(val: any) => ChangeFilter(val, 'queries')}
          params={filters}
          setIsModalOpen={setIsModalOpen}
          initialQueries={initialQueries}
          openFormModal={openFormModal}
        /> */}
        <Table
          loading={isLoading || isDeleting}
          isEmpty={!data.length}
          count={count}
          searchAble={false}
          title={title}
          PromoAble={Permission.some(hasEditPermission)}
          TimeTable={(props) => (
            <div
              // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
              className={`absolute top-[35px] ${
                title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
              } bg-[#f5f3f8] p-[12px]`}
              style={{
                width: `${Responsive.w(572, width)}px`,
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
              }}
            >
              <ul
                className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
                aria-labelledby="dropdownDefaultButton"
                // style={{
                //   width: `${Responsive.w(572, width)}px`,
                // }}
              >
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full"
                    onClick={() => {
                      handleDateOptionClick('All User', allPromoCodesMessage);
                    }}
                  >
                    {
                      <FormattedMessage
                        id={allPromoCodesMessage}
                        defaultMessage={allPromoCodesMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full "
                    onClick={() => handleDateOptionClick('Today', todayMessage)}
                  >
                    {
                      <FormattedMessage
                        id={todayMessage}
                        defaultMessage={todayMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                    onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                  >
                    {
                      <FormattedMessage
                        id={last7DaysMessage}
                        defaultMessage={last7DaysMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                  >
                    {
                      <FormattedMessage
                        id={last30DaysMessage}
                        defaultMessage={last30DaysMessage}
                      />
                    }
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => {
                      handleDateOptionClick('Date range', dateRangeMessage);
                    }}
                  >
                    {
                      <FormattedMessage
                        id={dateRangeMessage}
                        defaultMessage={dateRangeMessage}
                      />
                    }
                  </a>
                </li>
              </ul>
            </div>
          )}
          CustomizeTable={() => (
            <>
              {
                <div
                  className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-30"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`,
                    borderRadius: `${Responsive.sp(16, width)}px`,
                    boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                  }}
                >
                  <div
                    className="space-y-4 flex flex-col z-[50]"
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    <div className="  grid">
                      <ul
                        className="flex items-start justify-center p-0 flex-row"
                        style={{
                          width: `${Responsive.w(335, width)}px`,
                          height: `${Responsive.h(40, height)}px`,
                          gap: `${Responsive.sp(24, width)}px`
                        }}
                      >
                        {tabs.map((tab: any, index: number) => (
                          <li key={index}>
                            <button
                              className={[
                                'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                                currentTab === tab.key
                                  ? ` ${
                                      currentDashboard === 'dream'
                                        ? '!text-[#a12f88] border-transparent border-b-[#a12f88]'
                                        : currentDashboard === 'jeras'
                                        ? '!text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                                        : currentDashboard === 'nikah'
                                        ? '!text-[#cf0036] border-transparent border-b-[#cf0036]'
                                        : '!text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                                    } border-b-2 `
                                  : '!text-gray-800'
                              ].join(' ')}
                              onClick={() => setCurrentTab(tab.key)}
                            >
                              <span>{tab.name}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="flex items-start justify-start flex-col overflow-y-auto "
                      style={{
                        width: `${Responsive.w(369, width)}px`,
                        height: `${Responsive.h(710, height)}px`
                      }}
                    >
                      {currentTab === 'customize' ? (
                        <ul
                          className="space-y-2 overflow-y-auto"
                          style={{
                            width: `${Responsive.w(369, width)}px`,
                            height: `${Responsive.h(710, height)}px`
                          }}
                        >
                          {keys.map((field: string, index: number) => (
                            <li
                              className="flex flex-row items-center justify-start py-0 px-4 "
                              key={index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(12, width)}px`
                              }}
                            >
                              <label
                                htmlFor={field + '-' + index}
                                style={{
                                  width: `${Responsive.w(345, width)}px`,
                                  height: `${Responsive.h(40, height)}px`,
                                  gap: `${Responsive.sp(8, width)}px`
                                }}
                                className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                              >
                                <input
                                  type="checkbox"
                                  name={field + '-' + index}
                                  id={field + '-' + index}
                                  className="form-checkbox shrink-0"
                                  style={{
                                    width: `${Responsive.w(20, width)}px`,
                                    height: `${Responsive.h(20, height)}px`
                                  }}
                                  value={field}
                                  checked={customize_keys.some((key) => key === field)}
                                  onChange={ChangeCustomizeKey}
                                />

                                <p
                                  className=" font-medium capitalize flex justify-center items-center"
                                  style={{
                                    fontSize: `${Responsive.sp(14, width)}px`
                                  }}
                                >
                                  {useIntl().formatMessage({
                                    id: field,
                                    defaultMessage: field
                                  })}
                                </p>
                              </label>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <FilterFields
                          onReset={() => ChangeFilter([...initialQueries], 'queries')}
                          onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                          filters={filters}
                          dashColor={dashColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }
            </>
          )}
          RenderBody={({ getRowColor }) => (
            <>
              {data?.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    style={{ backgroundColor: getRowColor(index) }}
                    className=" hover:bg-[#EAE0EB]"
                  >
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      {index + 1}
                    </td>
                    {isIncluded('code') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {item?.code || 'N/A'}
                      </td>
                    )}
                    {isIncluded('discount') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {item.promoType === 'fixedPrice'
                          ? item?.discount
                          : '%' + item?.discount || 0}
                      </td>
                    )}
                    {isIncluded('ownerName') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {item?.ownerName || 'N/A'}
                      </td>
                    )}
                    {isIncluded('promoCodeTimestamp') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {formatDate(item.promoCodeTimestamp)
                          ? formatDate(item.promoCodeTimestamp)
                          : 'N/A'}
                      </td>
                    )}
                    {isIncluded('type') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {item?.type || 'N/A'}
                      </td>
                    )}
                    {isIncluded('usedNumber') && (
                      <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                        {item?.usedNumber || 0}
                      </td>
                    )}
                    {isIncluded('promoCodeStatus') && (
                      <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch">
                        <p
                          className="flex items-center justify-center"
                          style={{
                            height: '30px',
                            border:
                              item?.promoCodeStatus === true
                                ? 'solid 1px #28c794'
                                : item?.promoCodeStatus === false
                                ? 'solid 1px #e84a4d'
                                : '',
                            backgroundColor:
                              item?.promoCodeStatus === true
                                ? '#ebfeef'
                                : item?.promoCodeStatus === false
                                ? '#ffe2e2'
                                : '',
                            color:
                              item?.promoCodeStatus === true
                                ? '#228176'
                                : item?.promoCodeStatus === false
                                ? '#e84a4d'
                                : ''
                          }}
                        >
                          {item ? (item.promoCodeStatus ? 'Active' : 'Not Active') : 'N/A'}
                        </p>
                      </td>
                    )}

                    {Permission.some(hasEditOrDeletePermission) && (
                      <>
                        {Permission.some(hasEditPermission) && (
                          <td className=" text-center nowrap   text-[14px]  cursor-pointer  font-medium text-[#515151] self-stretch">
                            <button
                              type="button"
                              className="flex items-center justify-center w-full"
                              onClick={() => openFormModal(item)}
                            >
                              <p className="flex items-center justify-center rounded-full bg-[#ede8f6] w-[29.7px]  h-[29.7px] p-0 ">
                                <Icon
                                  icon={'iconamoon:edit-fill'}
                                  className="object-contain p-0 w-[6.8] h-[7.8px] "
                                  style={{
                                    color: dashColor
                                  }}
                                />
                              </p>
                            </button>
                          </td>
                        )}

                        {Permission.some(hasDeletePermission) && (
                          <td className=" text-center nowrap   text-[14px]  font-medium text-[#515151] self-stretch cursor-pointer">
                            <button
                              type="button"
                              className="flex items-center justify-center w-full"
                              onClick={() => handleDeletePromo(item.promoCodeId)}
                            >
                              <p className=" flex items-center justify-center rounded-full bg-[#ffdbe3] w-[29.7px]  h-[29.7px] p-0">
                                <Icon
                                  icon={'material-symbols:delete-outline'}
                                  className="object-contain p-0 w-[6.8] h-[7.8px] "
                                  style={{
                                    color: '#c43636'
                                  }}
                                />
                              </p>
                            </button>
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                #
              </th>
              {isIncluded('code') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="code"
                    text={useIntl().formatMessage({
                      id: 'code',
                      defaultMessage: 'code'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('discount') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="discount"
                    text={useIntl().formatMessage({
                      id: 'discount',
                      defaultMessage: 'discount'
                    })}
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('ownerName') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="ownerName"
                    text={useIntl().formatMessage({
                      id: 'ownerName',
                      defaultMessage: 'owner name'
                    })}
                    sortAble
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('promoCodeTimestamp') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="promoCodeTimestamp"
                    text={useIntl().formatMessage({
                      id: 'creationDate',
                      defaultMessage: 'creation date'
                    })}
                    sortAble
                    handler={ChangeFilter}
                  />
                </th>
              )}
              {isIncluded('type') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="type"
                    text={useIntl().formatMessage({
                      id: 'type',
                      defaultMessage: 'type'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('usedNumber') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="usedNumber"
                    text={useIntl().formatMessage({
                      id: 'usedNumber',
                      defaultMessage: 'used number'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}
              {isIncluded('promoCodeStatus') && (
                <th
                  className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                  style={{
                    height: `${Responsive.h(24, height)}px`,
                    backgroundColor: dashColor
                  }}
                >
                  <SortButton
                    filters={filters}
                    sortKey="promoCodeStatus"
                    text={useIntl().formatMessage({
                      id: 'promoCodeStatus',
                      defaultMessage: 'promo code status'
                    })}
                    handler={ChangeFilter}
                    sortAble
                  />
                </th>
              )}

              {Permission.some(hasEditOrDeletePermission) && (
                <>
                  {Permission.some(hasEditPermission) && (
                    <th
                      className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                      style={{
                        height: `${Responsive.h(24, height)}px`,
                        backgroundColor: dashColor
                      }}
                    >
                      {useIntl().formatMessage({
                        id: 'edit',
                        defaultMessage: 'Edit'
                      })}
                    </th>
                  )}

                  {Permission.some(hasDeletePermission) && (
                    <th
                      className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                      style={{
                        height: `${Responsive.h(24, height)}px`,
                        backgroundColor: dashColor
                      }}
                    >
                      {useIntl().formatMessage({
                        id: 'delete',
                        defaultMessage: 'Delete'
                      })}
                    </th>
                  )}
                </>
              )}
            </>
          )}
          onApply={(startDate, endDate) => {
            setStart(startDate);
            setEnd(endDate);
          }}
          onPromoClick={() => openFormModal(null)}
          buttonExport={() => ExportXlsx(data, customize_keys)}
          buttonExportAll={() => ExportAllDataXlsx()}
          pagination={{ ...pagination, search_key: filters.search }}
          onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
          onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
          ChangePerPageLimit={(e: any) => {
            if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
          }}
          searchProps={{
            onKeyDown(e: any) {
              if (e.key === 'Enter' && e.target.value) {
                // Check if the input is a phone number (only numbers and/or '+')
                const isPhoneNumber = /^[+]?[0-9]+$/.test(e.target.value);

                if (isPhoneNumber) {
                  // Search in phoneNumber field
                  return ChangeFilter([['phoneNumber', '>=', e.target.value + '~']], 'queries');
                } else {
                  // Search in name field
                  return ChangeFilter([['name', '>=', e.target.value + '~']], 'queries');
                }
              }
            }
          }}
        />
      </div>
    </>
  );
}

function TableCustomizeFilterModal({
  SendPrams,
  params,
  setIsModalOpen,
  initialQueries,
  openFormModal
}: any) {
  const { keys, customize_keys, filters, data } = useSelector(
    (state: RootState) => state.PromoCodes
  );
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const { ChangeFilter } = useHooks();

  const handleOpenModal = () => {
    // ChangeFilter('clearSorts', 'sorts');
    // Step 1: Open the modal and set sorts to empty
    SendPrams(initialQueries);
    setIsModalOpen(true);
    setVisible(true);
  };
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const [currentTab, setCurrentTab] = useState<string>('filter');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   SendPrams([
            //     [
            //       'promoCodeTimestamp',
            //       '==',
            //       `${formatStartDay(startDate)}-${formatEndDay(endDate)}`
            //     ],
            //     ...initialQueries
            //   ])
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                openFormModal();
              }}
            >
              <Icon icon="gridicons:add-outline" />

              {useIntl().formatMessage({
                id: 'addPromoCode',
                defaultMessage: 'Add promo code'
              })}
            </button>
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                SendPrams(initialQueries);
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              disabled={initialQueries.length == 0 && params.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            {/* <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => ExportXlsx(data, customize_keys)}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'exportExcel',
                  defaultMessage: 'Export Excel'
                })}
              </span>
            </button> */}
            <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={handleOpenModal}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'filterAndCustomizeTable',
                  defaultMessage: 'Filter & Customize table'
                })}
              </span>
            </button>
          </div>
        </div>
      </div>
      <SideModal
        title={useIntl().formatMessage({
          id: 'filterAndCustomizeTable',
          defaultMessage: 'Filter & Customize table'
        })}
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setIsModalOpen(false);
        }}
        size="max-w-screen-sm"
      >
        <div className="space-y-4 flex  overflow-y-auto">
          <div className="border-b border-b-gray-200 overflow-x-auto grid">
            <ul className="inline-flex">
              {tabs.map((tab: any, index: number) => (
                <li key={index}>
                  <button
                    className={[
                      'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                      currentTab === tab.key
                        ? '!text-gray-800 border-b-2 border-b-gray-800'
                        : '!text-gray-500'
                    ].join(' ')}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 grid overflow-y-auto">
            {currentTab === 'customize' ? (
              <ul className="space-y-2">
                {keys.map((field: string, index: number) => (
                  <li key={index}>
                    <label
                      htmlFor={field + '-' + index}
                      className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        name={field + '-' + index}
                        id={field + '-' + index}
                        className="form-checkbox shrink-0"
                        value={field}
                        checked={customize_keys.some((key) => key === field)}
                        onChange={ChangeCustomizeKey}
                      />

                      <p className="text-sm font-medium capitalize flex-1">
                        {useIntl().formatMessage({
                          id: field,
                          defaultMessage: field
                        })}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <FilterFields
                setIsModalOpen={setIsModalOpen}
                onSubmit={SendPrams}
                filters={filters}
                onReset={() => {
                  SendPrams([]);
                  setIsModalOpen(false);
                }}
                handleClose={setVisible}
              />
            )}
          </div>
        </div>
      </SideModal>
    </>
  );
}

function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor
}: any) {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  const [filters, setFilters] = useState<any>(values.queries || []);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  const handleOnClearClick = () => {
    onReset();
    setFilters([]);
    setSelectedFields([]);
  };

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'discount':
            // If "price" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'usedNumber':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'promoCodeStatus':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '==', value === 'true');
            break;
          case 'type':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;
          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'promoCodeTimestamp', '==', from + '-' + to);

            break;
          default:
            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }

  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }
  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);
  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);
  return (
    //here
    <form
      className="space-y-3 z-20"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(query);
      }}
    >
      <div
        className="flex flex-col items-start justify-start p-0 "
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(88, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="discount"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('discount')}
          />

          <label
            className="font-[Montserrat] text-[#262626]"
            style={{
              width: `${Responsive.w(57, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="discount-amount"
          >
            {useIntl().formatMessage({
              id: 'discount',
              defaultMessage: 'discount'
            })}
          </label>
        </div>
        <input
          type="number"
          name="discount-amount"
          id="discount-amount"
          placeholder="0"
          min={0}
          onChange={ChangeFilters('discount')}
          defaultValue={findValue('discount')}
          disabled={!selectedFields.includes('discount')}
          readOnly={!selectedFields.includes('discount')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        />
      </div>

      <div
        className="flex flex-col items-start justify-start p-0 "
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="usedNumber"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('usedNumber')}
          />

          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="usedNumber"
          >
            {useIntl().formatMessage({
              id: 'usageCount',
              defaultMessage: 'usage count'
            })}
          </label>
        </div>
        <input
          type="number"
          name="usedNumber"
          id="usedNumber"
          placeholder="0"
          min={0}
          onChange={ChangeFilters('usedNumber')}
          disabled={!selectedFields.includes('usedNumber')}
          readOnly={!selectedFields.includes('usedNumber')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        />
      </div>

      <div
        className="flex flex-col items-start justify-start p-0 "
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="promoCodeStatus"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('promoCodeStatus')}
          />

          <label
            className="font-[Montserrat] text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="promocode-status"
          >
            {useIntl().formatMessage({
              id: 'PromoCodeStatus',
              defaultMessage: 'Promo code status'
            })}
          </label>
        </div>
        <select
          name="promocode-status"
          id="promocode-status"
          onChange={ChangeFilters('promoCodeStatus')}
          defaultValue={findValue('promoCodeStatus') || ''}
          disabled={!selectedFields.includes('promoCodeStatus')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        >
          <optgroup label="Status">
            <option
              value=""
              disabled
            >
              {useIntl().formatMessage({
                id: 'selectStatus',
                defaultMessage: 'Select status'
              })}
            </option>

            <option value="true">
              {useIntl().formatMessage({
                id: 'active',
                defaultMessage: 'active'
              })}
            </option>
            <option value="false">
              {useIntl().formatMessage({
                id: 'inactive',
                defaultMessage: 'inactive'
              })}
            </option>
          </optgroup>
        </select>
      </div>

      <div
        className="flex flex-col items-start justify-start p-0 "
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="type"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('type')}
          />

          <label
            className="font-[Montserrat] text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="promocode-type"
          >
            {useIntl().formatMessage({
              id: 'promoCodeType',
              defaultMessage: 'Promo code type'
            })}
          </label>
        </div>
        <select
          name="promocode-type"
          id="promocode-type"
          onChange={ChangeFilters('type')}
          defaultValue={findValue('type') || ''}
          disabled={!selectedFields.includes('type')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        >
          <optgroup label="Status">
            <option
              value=""
              disabled
            >
              {useIntl().formatMessage({
                id: 'selectType',
                defaultMessage: 'Select type'
              })}
            </option>

            <option value="primary">
              {useIntl().formatMessage({
                id: 'primary',
                defaultMessage: 'primary'
              })}
            </option>
            <option value="default">
              {useIntl().formatMessage({
                id: 'default',
                defaultMessage: 'default'
              })}
            </option>
          </optgroup>
        </select>
      </div>

      <div
        className="flex flex-col items-start justify-start p-0 "
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="date"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('date')}
          />

          <label
            className="font-[Montserrat] text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="date"
          >
            {useIntl().formatMessage({
              id: 'date',
              defaultMessage: 'date'
            })}
          </label>
        </div>
        <select
          name="date"
          id="date"
          onChange={ChangeFilters('date')}
          defaultValue={findValue('date') || ''}
          disabled={!selectedFields.includes('date')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        >
          <optgroup label="Periods">
            <option
              value=""
              disabled
            >
              {useIntl().formatMessage({
                id: 'selectPeriod',
                defaultMessage: 'Select period'
              })}
            </option>

            {usePeriods().map((period: any, index: number) => (
              <option value={period.value}>{period.label}</option>
            ))}
          </optgroup>
        </select>
      </div>

      <div
        className="flex   justify-between items-center"
        style={{
          width: `${Responsive.w(303, width)}px`,
          height: `${Responsive.h(42, height)}px`,
          gap: `${Responsive.sp(10, width)}px`,
          borderRadius: `${Responsive.sp(4, width)}px`,
          padding: `${Responsive.sp(10, width)}px`
        }}
      >
        <button
          className="btn-with-icon  font-semibold nowrap"
          type="submit"
          style={{
            width: `${Responsive.w(104, width)}px`,
            height: `${Responsive.h(42, height)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`,
            fontSize: `${Responsive.sp(14, width)}px`,
            backgroundColor: dashColor
          }}
        >
          <span
            style={{
              width: `${Responsive.w(84, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'applyfilter',
              defaultMessage: 'Apply Filter'
            })}
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={onReset}
        >
          <span>
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
      </div>
    </form>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}

