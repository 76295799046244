import { Icon } from '@iconify/react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import Modal from 'src/components/shared/Modal';
import { toast } from 'react-toastify';
import useFetch from 'src/hooks/useFetch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactStars from 'react-rating-stars-component';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import DownloadInvoicePDF from 'src/components/InvoicePDF/PDFGenerator';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import InvoicePdfDoc from 'src/components/shared/InvoicePdfDoc';
import { FormattedMessage, useIntl } from 'react-intl';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';

import vector from '../images/Vector+.png';
import { set } from 'firebase/database';
import Table from 'src/components/shared/tables';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/import.png';
import img_2_2 from '../images/import@2x.png';
import img_2_3 from '../images/import@3x.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import SortButton from 'src/components/shared/SortButton';
import SharedTime from 'src/components/shared/SharedTime';
import { Fields, IFilters } from 'src/types/types';
import { ChangeCustomizeKeys, UpdateFilter, UpdateResult } from 'src/reducers/orders';

import * as xlsx from 'exceljs';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { getDates } from 'src/helper/DateUtils';
import usePeriods from 'src/hooks/usePeriods';
import  {AppointmentsClosed}  from './appointmentClosedConsultant';

function consultantDetailsPage() {
  const { id } = useParams();
  const [currentConsult, setCurrentConsult] = useState<any | null>(null);
  const [consultPackagesData, setConsultPackagesData] = useState<any | null>([]);
  const [loading, setLoading] = useState(true);
  const [isPaymentInfoModalOpen, setIsPaymentInfoModalOpen] = useState(false);
  const [isReviewsModalOpen, setIsReviewsModalOpen] = useState(false);
  const [isPaymentHistoryModalOpen, setIsPaymentHistoryModalOpen] = useState(false);
  const [consultId, setConsultId] = useState(null);
  const { width, height } = useWindowDimensions();
  const [auth, setAuth] = useState<any>(null);
  const [title, setTitle] = useState<any | null>('Personal');
  const [activeItem, setActiveItem] = useState('Personal');
  console.log('id', id);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const {
    data: paymentInfoData,
    isDataLoading: isPaymentDataLoading,
    error: loadingPaymentError
  } = useFetch(`/paymentInfo/${id}`, id ? true : false);

  const openPaymentInfoModal = () => {
    setConsultId(currentConsult?.uid);
  };

  const {
    data: reviewsData,
    isDataLoading: isReviewsDataLoading,
    error: loadingReviewsError
  } = useFetch(`/consultReviews/${id}`, id ? true : false);

  const openReviewsModal = () => {
    setConsultId(currentConsult?.uid);
    setIsReviewsModalOpen(true);
  };

  const {
    data: paymentHistoryData,
    isDataLoading: isPaymentHistoryDataLoading,
    error: loadingPaymentHistoryError
  } = useFetch(`/paymentHistory/${id}`, id ? true : false);

  const openPaymentHistoryModal = () => {
    setConsultId(currentConsult?.uid);
    setIsPaymentHistoryModalOpen(true);
  };
  const handleClick = (title) => {
    setActiveItem(title);
    setTitle(title);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const consultRes = await axiosInstance.get(`/users/${id}`);
        const consultData = consultRes.data.result.data;
        setCurrentConsult(consultData);
        // console.log('consult', consultData);
        setAuth(consultRes.data.result?.providers);
        const packagesRes = await axiosInstance.get(`/allConsultPackages/${id}`);
        const packagesData = packagesRes.data.result.data;
        setConsultPackagesData(packagesData);
        // console.log('packages', packagesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      } finally {
        console.log('5554', consultPackagesData);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className="p-[8px]  bg-[#fafafa] min-h-screen justify-between flex-col flex gap-7 overflow-x-hidden"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
        className="flex mt-[30px] h-auto items-start justify-start gap-[24px] rounded-[16px] p-[16px] bg-white"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        {/* img */}
        <div
          className="flex flex-col h-auto  p-0 flex-grow-0 items-start justify-start self-stretch"
          style={{
            width: `${Responsive.w(324, width)}px`,

            borderRadius: `${Responsive.sp(16, width)}px`,
            gap: `${Responsive.sp(10, width)}px`
          }}
        >
          <img
            className="rounded-[16px] h-auto   flex flex-grow items-start justify-start self-stretch "
            style={{
              width: `${Responsive.w(324, width)}px`,
              objectFit: 'cover'
            }}
            src={currentConsult?.photoUrl || '/images/placeholder.png'}
            alt=""
          />
        </div>

        <div
          className="flex flex-col items-start justify-start p-0 self-stretch"
          style={{
            width: `${Responsive.w(1161, width)}px`,
            gap: `${Responsive.sp(24, width)}px`
          }}
        >
          <div
            className="flex items-start justify-start  p-0 self-stretch"
            style={{
              width: `${Responsive.w(1161, width)}px`,
              gap: `${Responsive.sp(24, width)}px`
            }}
          >
            {/* details */}
            <div
              className="flex flex-col items-start justify-start  self-stretch flex-grow-0 border-solid border-[#afafaf] border bg-white"
              style={{
                width: `${Responsive.w(423.5, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`,
                padding: `${Responsive.sp(16, width)}px`
              }}
            >
              <h1
                className="font-[Montserrat] font-semibold text-left text-[#1e1e1e]"
                style={{
                  width: `${Responsive.w(391, width)}px`,

                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {currentConsult?.name}
              </h1>
              <p
                className={`font-medium text-left font-[Montserrat] flex-grow-0 text-[${dashColor}]  `}
                style={{
                  width: `${Responsive.w(124, width)}px`,

                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {currentConsult?.uid}
              </p>
              <div
                className="flex align-start justify-between  flex-grow-0 mt-[8px] "
                style={{
                  width: `${Responsive.w(391.5, width)}px`
                }}
              >
                <div className="flex items-center space-x-1">
                  <p>{currentConsult?.ordersNumbers}</p>
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`,
                      color: dashColor
                    }}
                    icon="majesticons:phone-incoming"
                  />
                </div>
                <div className="flex items-center space-x-1">
                  <p>{currentConsult?.rating}</p>
                  <Icon
                    className="shrink-0 text-amber-300"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="material-symbols:star"
                  />
                </div>
                <div className="flex items-center space-x-1">
                  <p>
                    {currentConsult?.price}{' '}
                    <span
                      style={{
                        color: dashColor
                      }}
                    >
                      $
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Time Of Work */}
            <div
              className="flex flex-col items-start justify-start  self-stretch flex-grow-0  border-solid border-[#afafaf] border bg-white"
              style={{
                width: `${Responsive.w(423.5, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`,
                padding: `${Responsive.sp(16, width)}px`
              }}
            >
              <h1
                className="font-[Montserrat] font-semibold nowrap text-left text-[#1e1e1e]"
                style={{
                  width: `${Responsive.w(171, width)}px`,
                  height: `${Responsive.h(25, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'timesOfWork',
                  defaultMessage: 'Times of work'
                })}
              </h1>
              <div className=" m-auto  flex-col items-center justify-center">
                <div
                  className="flex my-2 items-center justify-start  gap-[8px] p-0  flex-grow "
                  style={{
                    width: `${Responsive.w(391, width)}px`,
                    height: `${Responsive.h(54, height)}px`,
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="uim:calender"
                  />
                  <p>
                    {currentConsult?.workDays
                      ? getWorkdaysString(currentConsult?.workDays)
                      : useIntl().formatMessage({
                          id: 'noWorkDays',
                          defaultMessage: 'No specific work days.'
                        })}
                  </p>
                </div>

                <div
                  className="flex my-2 items-center justify-start nowrap gap-[8px] p-0 flex-grow "
                  style={{
                    width: `${Responsive.w(118, width)}px`,
                    height: `${Responsive.h(23, height)}px`,
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="gala:clock"
                  />
                  <p>
                    {currentConsult?.workTimes?.length > 0 && currentConsult?.workTimes[0]
                      ? formatTimeRange(currentConsult.workTimes[0])
                      : useIntl().formatMessage({
                          id: 'noWorkDays',
                          defaultMessage: 'No specific work days.'
                        })}
                  </p>
                </div>
              </div>
            </div>
            {/* orders && appointments */}
            <div
              className="flex flex-col items-stretch justify-between self-stretch flex-grow-0 border border-solid border-[#afafaf]  bg-white"
              style={{
                width: `${Responsive.w(266, width)}px`,
                gap: `${Responsive.sp(16, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`,
                padding: `${Responsive.sp(16, width)}px`
              }}
            >
              <Card
                className="flex  items-center justify-between   self-stretch flex-grow py-[8px] px-[16px] border-[1px] border-solid border-[#afafaf] bg-white"
                style={{
                  width: `${Responsive.w(234, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              >
                <Link
                  to={`/orders?consultid=${currentConsult?.uid}`}
                  className={`text-[${dashColor}]  font-bold flex items-center  justify-between`}
                  style={{
                    width: `${Responsive.w(234, width)}px`,
                    gap: `${Responsive.sp(8, width)}px`,
                    borderRadius: `${Responsive.sp(12, width)}px`,
                    paddingTop: `${Responsive.sp(8, width)}px`,
                    borderBottom: `${Responsive.sp(16, width)}px`,
                    borderLeft: `${Responsive.sp(16, width)}px`,
                    borderRight: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <h1
                    className={`text-[${dashColor}]  font-medium text-center `}
                    style={{
                      width: `${Responsive.w(59, width)}px`,
                      // height: `${Responsive.h(24, height)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'orders',
                      defaultMessage: 'orders'
                    })}
                  </h1>
                  <span className="flex items-center justify-center">
                    <Icon
                      icon="bytesize:arrow-right"
                      className="text-[#000] mr-auto w-[14px] h-[14px]"
                    />
                  </span>
                </Link>
              </Card>
              <Card
                className="flex  items-center justify-between py-[8px] px-[16px]  self-stretch flex-grow  border-solid border-[#afafaf] border bg-white"
                style={{
                  width: `${Responsive.w(234, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              >
                <Link
                  className={`text-[${dashColor}]  font-bold flex items-center  justify-between`}
                  style={{
                    width: `${Responsive.w(234, width)}px`,
                    gap: `${Responsive.sp(8, width)}px`,
                    borderRadius: `${Responsive.sp(12, width)}px`,
                    paddingTop: `${Responsive.sp(8, width)}px`,
                    borderBottom: `${Responsive.sp(16, width)}px`,
                    borderLeft: `${Responsive.sp(16, width)}px`,
                    borderRight: `${Responsive.sp(16, width)}px`
                  }}
                  to={`/appointments?consultid=${currentConsult?.uid}`}
                >
                  <h1
                    className={`text-[${dashColor}]  font-medium text-center `}
                    style={{
                      width: `${Responsive.w(59, width)}px`,
                      // height: `${Responsive.h(24, height)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'appointments',
                      defaultMessage: 'appointments'
                    })}
                  </h1>
                  <span className="flex items-center justify-center">
                    <Icon
                      icon="bytesize:arrow-right"
                      className="text-[#000] mr-auto w-[14px] h-[14px]"
                    />
                  </span>
                </Link>
              </Card>
            </div>
          </div>
          {/* Auth Details */}
          <div
            className="flex items-start justify-start flex-grow-0 p-0 self-stretch"
            style={{
              width: `${Responsive.w(1161, width)}px`,
              gap: `${Responsive.sp(24, width)}px`
            }}
          >
            {/* Apple */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(423.3, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'devicon:apple'}
                className="w-6 h-6"
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(214, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {auth?.find((provider: any) => provider.type === 'apple')?.value ||
                  'Not Registered with Apple'}
              </p>
            </div>
            {/* Google */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(423.3, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'devicon:google'}
                className="w-6 h-6"
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(214, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {auth?.find((provider: any) => provider.type === 'google')?.value ||
                  'Not Registered with Google'}
              </p>
            </div>
            {/* Phone */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(266, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'majesticons:phone-incoming'}
                className="w-6 h-6"
                style={{
                  color: `${dashColor}`
                }}
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(124, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {currentConsult?.phoneNumber || 'Not  Phone'}
              </p>
            </div>
          </div>

          {/* Form && bio */}
          <div
            className="flex items-start justify-start p-0 self-stretch flex-grow"
            style={{
              width: `${Responsive.w(1161, width)}px`,
              gap: `${Responsive.sp(24, width)}px`
            }}
          >
            {currentConsult?.bio && <BioCard currentConsult={currentConsult} />}
            <FormCard
              currentConsult={currentConsult}
              color={dashColor}
            />
          </div>
        </div>
      </div>

      <div
        className="flex flex-col items-start justify-start gap-[24px] rounded-[16px] p-[16px] bg-white"
        style={{
          width: `${Responsive.w(1560, width)}px`
          // height: `${Responsive.h(447, height)}px`
        }}
      >
        <div
          className="flex  items-start justify-start gap-[20px] rounded-[16px] p-0 bg-white flex-row"
          style={{
            width: `${Responsive.w(1528, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <ul
            className="consultants flex  items-start justify-start gap-[20px] rounded-[16px] p-0 bg-white flex-row"
            style={{
              width: `${Responsive.w(1528, width)}px`,
              height: `${Responsive.h(90, height)}px`
            }}
          >
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'Personal'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('Personal')}
            >
              <button
                className=" cursor-pointer active text-center font-semibold font-[Montserrat] flex-grow-1 nowrap active:!text-[#7b6c96]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="personalInformation"
                    defaultMessage="Personal Information"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'Banking'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('Banking')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="bankingInfo"
                    defaultMessage="Banking Information"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'packages'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('packages')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="packages"
                    defaultMessage="packages"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'order'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('order')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="orders"
                    defaultMessage="orders"
                  />
                }
              </button>
            </li>
          {currentDashboard === 'jeras' &&  <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'AppointmentsClosed'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('AppointmentsClosed')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="tabAppointmentsClosed"
                    defaultMessage="Appointments Closed"
                  />
                }
              </button>
            </li>}
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'payment'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width:`${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('payment')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width:`${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                   fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="paymentHistory"
                    defaultMessage="payment history"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[1px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'Document'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('Document')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${currentDashboard === 'jeras' ? Responsive.w(200, width) : Responsive.w(235, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${currentDashboard === 'jeras' ? Responsive.sp(16, width) : Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="document"
                    defaultMessage=" Document"
                  />
                }
              </button>
            </li>
          </ul>
        </div>
        <div
          className="flex flex-col  items-stretch justify-start gap-[24px] rounded-[16px] p-0 bg-white "
          style={{
            width: `${Responsive.w(1560, width)}px`
            // height: `${Responsive.h(318, height)}px`
          }}
        >
          {title === 'Personal' && !isPaymentDataLoading && (
            <PaymentInfoModal
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              color={dashColor}
            />
          )}
          {title === 'Banking' && !isPaymentDataLoading && (
            <BankInfoModal
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              color={dashColor}
            />
          )}
          {title === 'packages' && !isPaymentDataLoading && (
            <PackagesCard
              consultPackagesData={consultPackagesData}
              setConsultPackagesData={setConsultPackagesData}
              color={dashColor}
            />
          )}
          {title === 'payment' && !isPaymentDataLoading && (
            <PaymentHistoryModal
              isOpen={isPaymentHistoryModalOpen}
              setIsOpen={setIsPaymentHistoryModalOpen}
              error={loadingPaymentHistoryError}
              currentConsult={currentConsult}
              color={dashColor}
            />
          )}
          {title === 'order' && !isPaymentDataLoading && (
            <OrderHistory
              isOpen={isPaymentHistoryModalOpen}
              setIsOpen={setIsPaymentHistoryModalOpen}
              error={loadingPaymentHistoryError}
              currentConsult={currentConsult}
              color={dashColor}
            />
          )}
         {title === 'AppointmentsClosed' && !isPaymentDataLoading && currentDashboard === 'jeras' && (
    <AppointmentsClosed />
)}
          {title === 'Document' && !isPaymentDataLoading && (
            <DocumentInfoModal
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              image={currentConsult}
              color={dashColor}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function formatTimeRange(timeRange) {
  const from = parseInt(timeRange.from);
  const to = parseInt(timeRange.to);

  const fromLabel = from < 12 ? `${from} AM` : `${from - 12} PM`;
  const toLabel = to < 12 ? `${to} AM` : `${to - 12} PM`;

  return `${fromLabel} - ${toLabel}`;
}

function getWorkdaysString(dayNumbers) {
  // Define an array of day names
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Map the day numbers to their corresponding day names
  const workdays = dayNumbers.map((dayNumber) => {
    const dayIndex = parseInt(dayNumber, 10);
    return days[dayIndex % 7]; // Ensure it's within the valid range (0-6)
  });

  // Join the day names into a string with commas and spaces
  return workdays.join(', ');
}

const BioCard = ({ currentConsult }) => {
  // console.log('Bio', currentConsult.bio);
  const { width, height } = useWindowDimensions();
  const truncatedBio = currentConsult.bio.substring(0, 300);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    nameAr: '',
    nameEn: '',
    nameIn: '',
    nameFr: '',
    bioAr: '',
    bioEn: '',
    bioFr: '',
    bioIn: '',
    languages: []
  });
  const [selectedLanguages, setSelectedLanguages] = useState({
    ar: false,
    en: false,
    fr: false,
    id: false
  });

  useEffect(() => {
    if (currentConsult) {
      setFormValues({
        nameAr: currentConsult?.consultName?.nameAr || '',
        nameEn: currentConsult?.consultName?.nameEn || '',
        nameFr: currentConsult?.consultName?.nameFr || '',
        nameIn: currentConsult?.consultName?.nameIn || '',
        bioAr: currentConsult?.consultBio?.bioAr || '',
        bioEn: currentConsult?.consultBio?.bioEn || '',
        bioFr: currentConsult?.consultBio?.bioFr || '',
        bioIn: currentConsult?.consultBio?.bioIn || '',
        languages: currentConsult?.languages || []
      });
    }
  }, [currentConsult]);

  useEffect(() => {
    if (currentConsult) {
      setSelectedLanguages({
        ar: formValues.languages.includes('ar'),
        en: formValues.languages.includes('en'),
        id: formValues.languages.includes('id'),
        fr: formValues.languages.includes('fr')
      });
    }
  }, [formValues]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };

  // Function to compare initial and current form values to determine what has changed
  const getChangedValues = (initialValues, currentValues) => {
    return Object.keys(initialValues).reduce((changes, key) => {
      if (initialValues[key] !== currentValues[key]) {
        // for nested objects like "consultBio.bioFr", you may need to transform the key
        // to use the dot notation
        const newKey = key.includes('bio') ? `consultBio.${key}` : `consultName.${key}`;
        changes[newKey] = currentValues[key];
      }
      return changes;
    }, {});
  };

  // Initialize selectedLanguages with the values derived from currentConsult
  const handleLanguageChange = (e) => {
    const { name, checked } = e.target;
    const currentlySelectedCount = Object.values(selectedLanguages).filter(Boolean).length;

    // If trying to uncheck and it's the last selected language, prevent it
    if (!checked && currentlySelectedCount === 1) {
      toast.error('At least one language must be selected.');
      return;
    }

    // Otherwise, proceed to update the state
    setSelectedLanguages((prev) => ({ ...prev, [name]: checked }));
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedLangs = Object.entries(selectedLanguages)
      .filter(([key, value]) => value) // Keep only the entries with a value of true
      .map(([key]) => key);

    const initialFormValues = {
      nameAr: currentConsult.consultName.nameAr,
      nameEn: currentConsult.consultName.nameEn,
      nameFr: currentConsult.consultName.nameFr,
      nameIn: currentConsult.consultName.nameIn,
      bioAr: currentConsult.consultBio.bioAr,
      bioEn: currentConsult.consultBio.bioEn,
      bioFr: currentConsult.consultBio.bioFr,
      bioIn: currentConsult.consultBio.bioIn,
      languages: currentConsult.languages
    };
    const changedValues = getChangedValues(initialFormValues, formValues);

    try {
      const response = await axiosInstance.patch(`/consultants/${currentConsult.uid}`, {
        ...changedValues,
        languages: selectedLangs
      });
      console.log('Update success:', response.data);
      toast.success('Changes have been saved!');
      closeModal();
      // Handle any post-update logic here (e.g., refreshing consultant details)
    } catch (error) {
      console.error('Error updating consultant details:', error);
      // Handle error (e.g., display error message to the user)
    }
  };

  return (
    <Card
      className="flex flex-col items-start justify-start  flex-grow  self-stretch   border-solid border-[#afafaf] border-[1px] bg-white"
      style={{
        width: `${Responsive.w(871, width)}px`,
        gap: `${Responsive.sp(16, width)}px`,
        borderRadius: `${Responsive.sp(16, width)}px`,
        padding: `${Responsive.sp(16, width)}px`
      }}
    >
      <h1
        className="font-[Montserrat] font-semibold nowrap text-left text-[#1e1e1e]"
        style={{
          width: `${Responsive.w(41, width)}px`,
          height: `${Responsive.h(29, height)}px`,
          fontSize: `${Responsive.sp(24, width)}px`
        }}
      >
        {useIntl().formatMessage({
          id: 'Bio',
          defaultMessage: 'Bio'
        })}
      </h1>
      {/* <button
        onClick={openModal}
        className="absolute top-0 right-0 p-2"
      >
        <Icon
          icon="eva:edit-fill"
          className="text-[#C145A6] hover:text-gray-800"
        />
      </button> */}

      <p className="text-right">{isExpanded ? currentConsult.bio : truncatedBio}</p>
      {currentConsult.bio.length > 300 && (
        <button
          onClick={toggleExpand}
          className="text-[#7b6c96] underline"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}

      <Modal
        visible={isModalOpen}
        handleClose={closeModal}
        title="Edit Consultant info"
      >
        <form
          onSubmit={handleSubmit}
          className="mt-2 space-y-6"
        >
          {/* NameAr Input */}
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameAr',
                defaultMessage: 'name in Arabic'
              })}
            </span>
            <input
              type="text"
              name="nameAr"
              value={formValues.nameAr}
              onChange={handleInputChange}
              className="mt-1 block w-full text-right rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter Arabic Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameEn',
                defaultMessage: 'name in English'
              })}
            </span>
            <input
              type="text"
              name="nameEn"
              value={formValues.nameEn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter English Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameFr',
                defaultMessage: 'name in French'
              })}
            </span>
            <input
              type="text"
              name="nameFr"
              value={formValues.nameFr}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter French Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameIn',
                defaultMessage: 'name in Indonesian'
              })}
            </span>
            <input
              type="text"
              name="nameIn"
              value={formValues.nameIn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder={useIntl().formatMessage({
                id: 'enterNameInIndo',
                defaultMessage: 'Enter Name in Indonesian'
              })}
            />
          </label>
          <div className="flex justify-between">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'languages',
                defaultMessage: 'languages'
              })}
              :
            </span>
            <label>
              <input
                type="checkbox"
                name="ar"
                checked={selectedLanguages.ar}
                onChange={handleLanguageChange}
              />
              Arabic
            </label>
            <label>
              <input
                type="checkbox"
                name="en"
                checked={selectedLanguages.en}
                onChange={handleLanguageChange}
              />
              English
            </label>
            <label>
              <input
                type="checkbox"
                name="id"
                checked={selectedLanguages.id}
                onChange={handleLanguageChange}
              />
              Indonesian
            </label>
            <label>
              <input
                type="checkbox"
                name="fr"
                checked={selectedLanguages.fr}
                onChange={handleLanguageChange}
              />
              French
            </label>
          </div>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioAr',
                defaultMessage: 'Bio in Arabic'
              })}
            </span>
            <textarea
              name="bioAr"
              value={formValues.bioAr}
              onChange={handleInputChange}
              className="mt-1 block w-full text-right rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter Arabic Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioEn',
                defaultMessage: 'Bio in English'
              })}
            </span>
            <textarea
              name="bioEn"
              value={formValues.bioEn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter English Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioFr',
                defaultMessage: 'Bio in French'
              })}
            </span>
            <textarea
              name="bioFr"
              value={formValues.bioFr}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter French Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioIn',
                defaultMessage: 'Bio in Indonesian'
              })}
            </span>
            <textarea
              name="bioIn"
              value={formValues.bioIn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter Indian Bio"
              rows={4}
            ></textarea>
          </label>

          {/* Add other inputs similarly... */}
          {/* Submit Button */}
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            {useIntl().formatMessage({
              id: 'saveChanges',
              defaultMessage: 'Save changes'
            })}
          </button>
        </form>
      </Modal>
    </Card>
  );
};

const FormCard = ({ currentConsult, color }) => {
  const { width, height } = useWindowDimensions();
  const [isAccountActive, setIsAccountActive] = useState(currentConsult?.accountStatus == 'Active');
  const [isConvertToUserActive, setisConvertToUserActive] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(currentConsult?.order || 0);

  const handleCheckboxChange = () => {
    setIsAccountActive(!isAccountActive);
  };
  const handleConvertToUserChange = () => {
    setisConvertToUserActive(!isConvertToUserActive);
  };

  const handleDisplayOrderChange = (e) => {
    setDisplayOrder(Number(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance.patch(`/consultants/${currentConsult.uid}`, {
      accountStatus: isAccountActive ? 'Active' : 'NotActive',
      userType: isConvertToUserActive ? 'USER' : 'CONSULTANT',
      order: displayOrder
    });
    toast.success('data has been modified successfully ✔');
  };
  return (
    <Card
      className="flex flex-col items-start justify-between   self-stretch flex-grow-0  border-solid border-[#afafaf] border-[1px] bg-white"
      style={{
        width: `${Responsive.w(266, width)}px`,
        gap: `${Responsive.sp(16, width)}px`,
        borderRadius: `${Responsive.sp(16, width)}px`,
        padding: `${Responsive.sp(16, width)}px`
      }}
    >
      <form
        style={{
          width: `${Responsive.w(266, width)}px`,
          gap: `${Responsive.sp(16, width)}px`
        }}
        className=" w-full flex flex-col  justify-between items-start flex-grow "
        onSubmit={handleSubmit}
      >
        {/* Display Order Input Row */}
        <div
          className="flex items-center justify-between p-0 self-stretch"
          style={{
            width: `${Responsive.w(234, width)}px`
          }}
        >
          <label
            className={` text-center text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,

              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'displayOrder',
              defaultMessage: 'Display order'
            })}
          </label>
          <div
            className=" flex justify-center items-center py-0 px-[16px] rounded-[8px] border-solid border-[#939393] border-[1px] bg-white"
            style={{
              width: `${Responsive.w(63, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            <input
              className="w-11 h-6 text-center py-0 px-[16px] rounded-[8px] border-solid border-[#939393] border-[1px] bg-white"
              value={displayOrder}
              style={{
                width: `${Responsive.w(63, width)}px`,
                // height: `${Responsive.h(30, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
              onChange={handleDisplayOrderChange}
            ></input>
          </div>
        </div>
        {/* Active Toggle Row */}

        <div
          className="flex items-center justify-between p-0 self-stretch"
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(30, height)}px`
          }}
        >
          <label
            className={` text-center text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'active',
              defaultMessage: 'active'
            })}
          </label>
          <div
            className=" flex justify-center "
            style={{
              width: `${Responsive.w(63, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            <ToggleSlider
              onClick={handleCheckboxChange}
              checked={isAccountActive}
            />
          </div>
        </div>
        {/* Convert to User Toggle Row */}
        <div
          className="flex items-center justify-between p-0 self-stretch"
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(30, height)}px`
          }}
        >
          <label
            className={` text-center nowrap text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'convertToUser',
              defaultMessage: 'convert to user'
            })}
          </label>
          <div className=" flex justify-center">
            <ToggleSlider
              onClick={handleConvertToUserChange}
              checked={isConvertToUserActive}
            />
          </div>
        </div>

        {/* Save Button */}
        <div
          className="flex items-center justify-center p-0 self-stretch"
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(46, height)}px`
          }}
        >
          <button
            className="   flex items-center justify-center  text-white font-semibold py-[5px] px-[16px] rounded-[8px] "
            style={{
              width: `${Responsive.w(234, width)}px`,
              // height: `${Responsive.h(46, height)}px`,
              backgroundColor: color
            }}
            type="submit"
          >
            {useIntl().formatMessage({
              id: 'save',
              defaultMessage: 'save'
            })}
          </button>
        </div>
      </form>
    </Card>
  );
};

const PackagesCard = ({ consultPackagesData, setConsultPackagesData, color }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const consultUid: string = consultPackagesData[0]?.consultUid || '';
  const { width, height } = useWindowDimensions();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  console.log('deee', selectedPackageId);
  interface FormValues {
    callNum: number;
    discount: number;
    price: number;
    type: string;
    active: boolean;
  }
  const initialFormValues: FormValues = {
    callNum: 0,
    discount: 0,
    price: 0,
    type: 'chat',
    active: false
  };
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const selectedPackage = consultPackagesData.find((p) => p.Id === selectedPackageId);
    if (consultPackagesData && selectedPackageId != null) {
      if (selectedPackage) {
        setIsChecked(selectedPackage.active);
        setFormValues({
          callNum: selectedPackage.callNum,
          discount: selectedPackage.discount,
          price: selectedPackage.price,
          type: selectedPackage.type,
          active: selectedPackage.active
        });
      }
    }
  }, [consultPackagesData, selectedPackageId, isModalOpen]);

  const openModal = (id) => {
    setSelectedPackageId(id);
    // console.log('packid', id);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        // Check if the input is a checkbox, and if so, use the 'checked' value; otherwise, use 'value'
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };

  useEffect(
    () => setFormValues((prevValues) => ({ ...prevValues, active: isChecked })),
    [isChecked]
  );
  // useEffect(() => console.log('A', formValues), [formValues]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(formValues).length > 0) {
      try {
        setIsloading(true);
        let response;
        if (selectedPackageId !== 'addPack') {
          response = await axiosInstance.patch(`/consultPackage/${selectedPackageId}`, {
            ...formValues,
            callNum: Number(formValues.callNum),
            discount: Number(formValues.discount),
            price: Number(formValues.price)
          });

          setConsultPackagesData((prev) =>
            prev.map((item) => (item.Id === selectedPackageId ? response.data.result.data : item))
          );
        } else {
          const body = {
            ...formValues,
            callNum: Number(formValues.callNum),
            discount: Number(formValues.discount),
            price: Number(formValues.price),
            consultUid
          };

          response = await axiosInstance.post(`/consultPackage`, body);

          setConsultPackagesData((prev) => [...prev, response.data.result.data]);
        }

        // window.location.reload();
        setIsloading(false);
        closeModal();
      } catch (error) {
        console.error('Error updating consultant details:', error);
      }
    } else {
      console.log('No changes to save.');
    }
  };

  const handleDeletePackage = async () => {
    if (selectedPackageId) {
      await axiosInstance.delete(`/consultPackage/${selectedPackageId}`);
      setConsultPackagesData(consultPackagesData.filter((item) => item.Id != selectedPackageId));
      toast.success('Package has been deleted successfully!');
      closeModal();
    }
  };
  return (
    <div>
      <Card
        className="grid grid-cols-4    p-0 bg-white mb-20"
        style={{
          width: `${Responsive.w(1528, width)}px`,
          gap: `${Responsive.sp(24, width)}px`,
          borderRadius: `${Responsive.sp(16, width)}px`
          // height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="grid grid-cols-4    p-0 bg-white"
          style={{
            width: `${Responsive.w(1528, width)}px`,
            gap: `${Responsive.sp(24, width)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`
          }}
        >
          {isloading ? (
            <Spinner />
          ) : (
            <>
              {consultPackagesData.map((pack) => (
                <ul
                  className="flex  flex-col   justify-start items-stretch border-solid border-[1px] border-[#afafaf] flex-grow"
                  key={pack.Id}
                  style={{
                    width: `${Responsive.w(286.4, width)}px`,
                    height: `${Responsive.h(136.7, height)}px`,
                    gap: `${Responsive.sp(8, width)}px`,
                    borderRadius: `${Responsive.sp(16, width)}px`,
                    padding: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <div
                    className="flex-grow-0 relative flex items-center justify-between p-0 self-stretch"
                    style={{
                      width: `${Responsive.w(254.4, width)}px`,
                      height: `${Responsive.h(43, height)}px`
                    }}
                  >
                    <li
                      className=" flex nowrap justify-center items-center font-semibold font-[Montserrat] flex-grow-0 self-stretch"
                      style={{
                        width: `${Responsive.w(127.2, width)}px`,
                        height: `${Responsive.h(29, height)}px`,
                        fontSize: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {pack?.type === 'chat' ? pack?.callNum + ' Chats' : pack?.callNum + ' Calls'}
                    </li>
                    <li
                      className="before:border-solid before:border-[1px] bg-[#afafaf] before:border-[#afafaf] before:w-[10] left-[50%] absolute flex nowrap justify-center items-center font-semibold font-[Montserrat] flex-grow-0 self-stretch"
                      style={{
                        // width: `${Responsive.w(127.2, width)}px`,
                        height: `${Responsive.h(29, height)}px`,
                        fontSize: `${Responsive.sp(24, width)}px`
                      }}
                    ></li>

                    <li
                      className=" flex nowrap justify-center items-center font-semibold font-[Montserrat] flex-grow-0 self-stretch"
                      style={{
                        width: `${Responsive.w(127.2, width)}px`,
                        height: `${Responsive.h(29, height)}px`,
                        fontSize: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {pack.price.toFixed(2)} <span className={`text-[${color}]`}>$</span>
                    </li>
                  </div>
                  <button
                    onClick={() => openModal(pack.Id)}
                    className={`flex items-center justify-center gap-2 p-0 rounded-xl ${
                      currentDashboard === 'dream' ? 'bg-[#fff7fd]' : 'bg-[#ece6f5]'
                    } flex-grow-0`}
                    style={{
                      width: `${Responsive.w(254.4, width)}px`,
                      height: `${Responsive.h(53.7, height)}px`
                    }}
                  >
                    <Icon
                      icon="eva:edit-fill"
                      className={`text-[${color}] hover:text-gray-800`}
                    />
                  </button>
                </ul>
              ))}
            </>
          )}

          <div
            className="flex items-center justify-center p-4 rounded-[16px] border-solid border-[1px] border-[#afafaf] flex-grow"
            style={{
              width: `${Responsive.w(286.4, width)}px`,
              height: `${Responsive.h(136.7, height)}px`
            }}
          >
            <button
              className=" cursor-pointer sm:w-auto bg-[#f3f3f3] border-[1px] border-solid    inline-flex items-center rounded-lg "
              style={{
                width: `${Responsive.w(286.4, width)}px`,
                height: `${Responsive.h(136.7, height)}px`,
                borderColor: color
              }}
              onClick={() => {
                openModal('addPack');
                setFormValues(initialFormValues);
              }}
            >
              <p
                className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#262626] flex items-center mx-auto justify-center gap-[16px]"
                style={{
                  width: `${Responsive.w(286.4, width)}px`,
                  height: `${Responsive.h(136.7, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                <Icon
                  icon={'iconamoon:sign-plus-bold'}
                  className="w-[25px] h-[25px]"
                  style={{
                    color: color
                  }}
                />
                <span>
                  {useIntl().formatMessage({
                    id: 'addPackage',
                    defaultMessage: 'Add Package'
                  })}
                </span>
              </p>
            </button>
          </div>
        </div>

        <Modal
          visible={isModalOpen}
          handleClose={closeModal}
          title={
            selectedPackageId == 'addPack'
              ? useIntl().formatMessage({
                  id: 'addPackage',
                  defaultMessage: 'Add Package'
                })
              : useIntl().formatMessage({
                  id: 'updatePackage',
                  defaultMessage: 'Update Package'
                })
          }
        >
          <form
            onSubmit={handleSubmit}
            className="flex flex-col  items-center justify-center"
          >
            <div className="flex-col space-y-4  w-full">
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="callNum"
                  className={`text-[${color}] font-[Montserrat] nowrap font-semibold text-left`}
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'numberOfCalls',
                    defaultMessage: 'Number of calls'
                  })}
                </label>
                <input
                  id="callNum"
                  type="number"
                  name="callNum"
                  value={formValues.callNum}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                  placeholder="Enter number of calls"
                />
              </div>

              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="discount"
                  className={`text-[${color}] font-[Montserrat] nowrap font-semibold text-left`}
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'discount',
                    defaultMessage: 'discount'
                  })}
                  (%)
                </label>
                <input
                  id="discount"
                  type="number"
                  name="discount"
                  value={formValues.discount}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                  placeholder="Enter discount percentage"
                />
              </div>
              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="price"
                  className={`text-[${color}] font-[Montserrat] nowrap font-semibold text-left`}
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'price',
                    defaultMessage: 'price'
                  })}
                </label>
                <input
                  id="price"
                  type="number"
                  name="price"
                  value={formValues.price}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                  placeholder="Enter discount percentage"
                />
              </div>

              <div
                className="flex flex-row justify-between items-center p-0 flex-grow-0 "
                style={{
                  width: `${Responsive.w(748, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
              >
                <label
                  htmlFor="type"
                  className={`text-[${color}] font-[Montserrat] nowrap font-semibold text-left`}
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'packageType',
                    defaultMessage: 'package type'
                  })}
                </label>
                <select
                  id="type"
                  name="type"
                  value={formValues.type}
                  onChange={handleInputChange}
                  className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                >
                  <option value={formValues.type}>{formValues.type}</option>
                  <option value={formValues.type == 'voice' ? 'chat' : 'voice'}>
                    {formValues.type == 'voice' ? 'chat' : 'voice'}
                  </option>
                </select>
              </div>

              <div className="flex items-center justify-between">
                <label
                  htmlFor="type"
                  className={`text-[${color}] font-[Montserrat] nowrap font-semibold text-left`}
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'active',
                    defaultMessage: 'active'
                  })}
                </label>
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-8 w-14 rounded-full ${
                        isChecked ? `bg-[${color}]` : 'bg-gray-500'
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                        isChecked ? 'translate-x-full' : ''
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>

            <div
              className="flex justify-between mt-2"
              style={{
                width: `${Responsive.w(748, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <button
                type="submit"
                className={`inline-flex justify-center items-center rounded-md border border-transparent bg-[${color}] px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2`}
              >
                {selectedPackageId == 'addPack'
                  ? useIntl().formatMessage({
                      id: 'addPackage',
                      defaultMessage: 'Add Package'
                    })
                  : useIntl().formatMessage({
                      id: 'updatePackage',
                      defaultMessage: 'Update Package'
                    })}
              </button>

              {selectedPackageId != 'addPack' && (
                <button
                  onClick={handleDeletePackage}
                  type="button"
                  className="flex justify-center items-center rounded-md itms-center gap-[18px] border-solid border-[1px] border-[#d91c1f]  bg-[#ffe2e2] text-[#d91c1f] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
                >
                  <img
                    src={img_1}
                    srcSet={`${img_1_2}, ${img_1_3}`}
                    className="object-contain p-0 w-[14] h-[14px] m-auto"
                    alt="Description of the image"
                  />
                  <span>Delete</span>
                </button>
              )}
            </div>
          </form>
        </Modal>
      </Card>
      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={`  bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'Update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </div>
  );
};

const PaymentInfoModal = ({ isOpen, setIsOpen, data, error, color }) => {
  // console.log('aa', data);

  const [image, setImage] = useState(null);
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    title: data?.title,
    fullNameAr: data?.fullNameAr,
    fullNameEn: data?.fullNameEn,
    email: data?.email,
    countryCode: data?.countryCode,
    phone: data?.phone,
    personalFrontUrl: data?.personalFrontUrl,
    personalBackUrl: data?.personalBackUrl,
    startDate: data?.startDate,
    endDate: data?.endDate,
    bankName: data?.bankName,
    bankAccountNumber: data?.bankAccountNumber,
    iban: data?.iban,
    swift: data?.swift,
    address1: data?.address1,
    address2: data?.address2,
    district: data?.district,
    zip_code: data?.zip_code,
    siteUrl: data?.siteUrl
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-4   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start  p-0 bg-white "
          style={{
            width: `${Responsive.w(266, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] nowrap flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'nameAr',
              defaultMessage: 'Name ( AR )'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.fullNameAr}
            onChange={handleChange}
            name="fullNameAr"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] nowrap flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'nameEn',
              defaultMessage: 'Name ( EN )'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.fullNameEn}
            onChange={handleChange}
            name="fullNameEn"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'title',
              defaultMessage: 'Title'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.title}
            onChange={handleChange}
            name="title"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.email}
            onChange={handleChange}
            name="email"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}] nowrap`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'countryCode',
              defaultMessage: 'Country code'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.countryCode}
            onChange={handleChange}
            name="countryCode"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'phone',
              defaultMessage: 'Phone'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.phone}
            onChange={handleChange}
            name="phone"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'address1',
              defaultMessage: 'Address1'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.address1}
            onChange={handleChange}
            name="address1"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'address2',
              defaultMessage: 'Address2'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.address2}
            onChange={handleChange}
            name="address2"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'district',
              defaultMessage: 'District'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.district}
            onChange={handleChange}
            name="district"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'zip_Code',
              defaultMessage: ' Zip_Code'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.zip_code}
            onChange={handleChange}
            name="zip_code"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'site_Url',
              defaultMessage: ' Site_Url'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.siteUrl}
            onChange={handleChange}
            name="siteUrl"
          />
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={` bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};
const BankInfoModal = ({ isOpen, setIsOpen, data, error, color }) => {
  // console.log('aa', data);

  const [image, setImage] = useState(null);
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    title: data?.title,
    fullNameAr: data?.fullNameAr,
    fullNameEn: data?.fullNameEn,
    email: data?.email,
    countryCode: data?.countryCode,
    phone: data?.phone,
    personalFrontUrl: data?.personalFrontUrl,
    personalBackUrl: data?.personalBackUrl,
    startDate: data?.startDate,
    endDate: data?.endDate,
    bankName: data?.bankName,
    bankAccountNumber: data?.bankAccountNumber,
    iban: data?.iban,
    swift: data?.swift,
    address1: data?.address1,
    address2: data?.address2,
    district: data?.district,
    zip_code: data?.zip_code,
    siteUrl: data?.siteUrl
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-4   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`,
          height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'bankName',
              defaultMessage: 'Bank Name'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.bankName}
            onChange={handleChange}
            name="bankName"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'bankAccountNumber',
              defaultMessage: 'Bank account number'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.bankAccountNumber}
            onChange={handleChange}
            name="bankAccountNumber"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'iban',
              defaultMessage: 'IBAN'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.iban}
            onChange={handleChange}
            name="iban"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'swiftCode',
              defaultMessage: 'Swift Code'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.swift}
            onChange={handleChange}
            name="swift"
          />
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={`  bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};
const DocumentInfoModal = ({ isOpen, setIsOpen, data, error, image, color }) => {
  // console.log('aa', data);

  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    title: data?.title,
    fullNameAr: data?.fullNameAr,
    fullNameEn: data?.fullNameEn,
    email: data?.email,
    countryCode: data?.countryCode,
    phone: data?.phone,
    personalFrontUrl: data?.personalFrontUrl,
    personalBackUrl: data?.personalBackUrl,
    startDate: data?.startDate,
    endDate: data?.endDate,
    bankName: data?.bankName,
    bankAccountNumber: data?.bankAccountNumber,
    iban: data?.iban,
    swift: data?.swift,
    address1: data?.address1,
    address2: data?.address2,
    district: data?.district,
    zip_code: data?.zip_code,
    siteUrl: data?.siteUrl
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-3   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`,
          height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start   p-0 bg-white "
          style={{
            width: `${Responsive.w(493.3, width)}px`,
            height: `${Responsive.h(305, height)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'idFront',
              defaultMessage: 'id (Front)'
            })}
          </label>
          <div
            className="flex items-center justify-center self-stretch flex-grow border-[1px] border-[#939393] border-[solid]"
            style={{
              width: `${Responsive.w(493.3, width)}px`,
              height: `${Responsive.h(265, height)}px`,
              padding: ` 10px 16px 13px`,
              borderRadius: `${Responsive.sp(8, width)}px`
            }}
          >
            <img
              className="flex items-center object-cover "
              style={{
                width: `${Responsive.w(313, width)}px`,
                height: `${Responsive.h(205, height)}px`
              }}
              src={data?.personalFrontUrl}
            />
          </div>
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(493.3, width)}px`,
            height: `${Responsive.h(305, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'idBack',
              defaultMessage: 'id (back)'
            })}
          </label>
          <div
            className="flex items-center justify-center self-stretch flex-grow border-[1px] border-[#939393] border-[solid]"
            style={{
              width: `${Responsive.w(493.3, width)}px`,
              height: `${Responsive.h(265, height)}px`,
              padding: ` 10px 16px 13px`,
              borderRadius: `${Responsive.sp(8, width)}px`
            }}
          >
            <img
              className="flex items-center object-cover "
              style={{
                width: `${Responsive.w(313, width)}px`,
                height: `${Responsive.h(205, height)}px`
              }}
              src={data?.personalBackUrl}
            />
          </div>
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(493.3, width)}px`,
            height: `${Responsive.h(305, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold nowrap text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'photo',
              defaultMessage: 'Photo'
            })}
          </label>
          <div
            className="flex items-center justify-center self-stretch flex-grow border-[1px] border-[#939393] border-[solid]"
            style={{
              width: `${Responsive.w(493.3, width)}px`,
              height: `${Responsive.h(265, height)}px`,
              padding: ` 10px 16px 13px`,
              borderRadius: `${Responsive.sp(8, width)}px`
            }}
          >
            <img
              className="flex items-center object-cover "
              style={{
                width: `${Responsive.w(313, width)}px`,
                height: `${Responsive.h(205, height)}px`
              }}
              src={image?.photoUrl || '/images/placeholder.png'}
            />
          </div>
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={`  bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};

const ReviewsModal = ({ isOpen, setIsOpen, data, error }) => {
  return (
    <Modal
      visible={isOpen}
      handleClose={() => setIsOpen(false)}
      title="Reviews"
    >
      <>
        {data?.reviews.map((review) => (
          <ReviewCard
            review={review}
            key={review.uid}
          />
        ))}
      </>
    </Modal>
  );
};

const PaymentHistoryModal = ({ isOpen, setIsOpen, error, currentConsult, color }) => {
  const [data, setData] = useState<any>();
  const formatDate = (date: any) => {
    const [isloading, setIsloding] = useState('false');
    const dateObj = new Date(date._seconds * 1000);
    const formattedDateTime = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return formattedDateTime;
  };

  const formatTime = (timestamp) => {
    console.log('timestamp ', timestamp);

    if (!timestamp || typeof timestamp._seconds !== 'number') {
      return 'Invalid date';
    }

    const date = new Date(timestamp._seconds * 1000);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState<any>('paymentHistory');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);
  const [sortValue, setSortValue] = useState<any>('');
  const [value, setValue] = useState(true);
  const [sortKey, setSortKey] = useState<any>('');
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };
  const applySorting = (key: string) => {
    setSortValue(value === false ? 'desc' : 'asc');
    setValue(!value);
    setSortKey(key);
  };
  const PaymentData = async (sortValue, sortKey) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/paymentHistory/${id}?sortValue=${sortValue}&sortKey=${sortKey}`
      );
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoading(false);
  };
  const PaymentExport = async (invoiceNumber) => {
    const consultId = id;
    try {
      const { data } = await axiosInstance.get(
        `/paymentHistoryInvoiceExport/${consultId}/${invoiceNumber}`,
        {
          responseType: 'blob' // Set responseType to blob to handle binary data
        }
      );

      // Create a blob URL for the response data
      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // Save the Blob as a file using FileSaver.js
      // saveAs(blob, `${invoiceNumber}_invoice.xlsx`);

      toast.success('Payment Download success ✔');
    } catch (error) {
      console.error('Error fetching data by date:', error);
      toast.error('Payment Download error ✔');
    }
  };
  const paymentHistoryMessage = 'paymentHistory';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);
  const resetValues = () => {
    setSortKey('');
    setSortValue('');
  };
  const handleDateOptionClick = async (dateOption, title) => {
    setIsLoading(true);

    try {
      setTitle(title);
      let startDate;
      let endDate;

      if (dateOption === 'Payment History') {
        PaymentData('', '');
        resetValues();
        return;
      }
      if (dateOption === 'Today') {
        startDate = new Date();
        endDate = new Date();
      } else if (dateOption === 'Last 7 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
      } else if (dateOption === 'Last 30 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 30);
      } else if (dateOption === 'Date range') {
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
      }

      const response = await axiosInstance.get(
        `/paymentHistory/${id}?startDate=${startDate}&endDate=${endDate} &sortValue=${sortValue}&sortKey=${sortKey}`
      );
      setData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    PaymentData(sortValue, sortKey);
  }, [sortValue, sortKey]);
  // if(data){
  //   data.map((e)=>{

  //     console.log("payTime", e.payTime);

  //   })
  // }

  return (
    <>
      <Table
        isEmpty={!data?.length}
        loading={isLoading}
        searchAble={false}
        notCountable={true}
        customizeAble={false}
        title={title}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('Payment History', paymentHistoryMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={paymentHistoryMessage}
                      defaultMessage={paymentHistoryMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        CustomizeTable={() => <></>}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
              >
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {index + 1}
                </td>
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {item?.balance.toFixed(2)}$
                </td>
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {formatDay(item?.payDate)}
                </td>
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {formatTime(item?.payTime)}
                </td>
                <td className="text-center flex items-center justify-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  <button
                    className="flex items-center justify-center"
                    onClick={() => {
                      PaymentExport(item.invoiceNumber);
                    }}
                  >
                    <img
                      src={img_2}
                      srcSet={`${img_2_2}, ${img_2_3}`}
                      className="object-contain p-0 w-[17px] h-[17px] m-auto"
                      alt="Description of the image"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              #
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="amount"
                  defaultMessage="Amount"
                />
              }
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              <div className="flex items-center justify-center gap-2">
                <button
                  className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                  style={{
                    backgroundColor: color
                  }}
                  onClick={() => {
                    applySorting('payTime');
                  }}
                  type="button"
                >
                  <Icon
                    icon="fa6-solid:sort"
                    width="12"
                    height="12"
                    className="text-gray-600"
                  />
                </button>
                {
                  <FormattedMessage
                    id="paymentDate"
                    defaultMessage="payment date"
                  />
                }
              </div>
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="paymentTime"
                  defaultMessage="payment Time"
                />
              }
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="download"
                  defaultMessage="Download"
                />
              }
            </th>
          </>
        )}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        // buttonExport={() => ExportXlsx(data, customize_keys)}
      />
      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={`  bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};
const OrderHistory = ({ isOpen, setIsOpen, error, currentConsult, color }) => {
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const dispatch = useDispatch();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState<any>('allOrders');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);
  const [sortValue, setSortValue] = useState<any>('');
  const [value, setValue] = useState(true);
  const [sortKey, setSortKey] = useState<any>('');
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };
  let initialQueries: any = [['consultId', '==', id]];
  const [filters, setFilters] = useState<IFilters>({
    page: 1,
    perPage: 13,
    sorts: [],
    queries: initialQueries,
    search: undefined
  });
  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 14,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }

      setFilters({ ...result });
      dispatch(UpdateFilter({ ...result }));
    },
    [filters, initialQueries, dispatch]
  );

  const orderData = async (params?: Partial<IFilters>) => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(`/orders`, {
        params: {
          ...params,
          ...filters
        }
      });
      console.log('Data', data.result.data);
      dispatch(UpdateResult(data.result));
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoading(false);
  };

  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Orders
  );
  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  const allOrdersMessage = 'allOrders';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);
  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    // Start with initial queries

    // Check if the option is 'All User'
    if (option === 'All Orders') {
      // If 'All Orders', no date filtering is applied
      ChangeFilter(null, 'clear');

      // setIsModalOpen(true);
      return; // Exit the function early
    }
    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['orderTimeValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };
  useEffect(() => {
    orderData();
  }, [filters]);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  async function ExportAllDataXlsx() {
    try {
      setExportDisabled(true);

      // طلب جميع البيانات بدون تطبيق `perPage`
      const res = await axiosInstance.get('orders', {
        params: {
          ...filters,
          page: 1,
          perPage: count // طلب جميع البيانات
        }
      });

      // If userCreationDate is included in customize_keys, fetch it for each order

      // Fetch userCreationDate for all orders in parallel
      const processedData = await Promise.all(
        res.data.result.data.map(async (order) => {
          try {
            const userCreationRes = await axiosInstance.get(`/userCreationDate/${order.user.uid}`, {
              timeout: 240000 // Set timeout to 120 seconds if needed
            });
            const userCreationDate = userCreationRes.data.result.data?.utcTime || 'N/A';
            return { ...order, userCreationDate };
          } catch (error) {
            console.error('Error fetching user creation date:', error);
            return { ...order, userCreationDate: 'Error fetching date' };
          }
        })
      );

      const finalData = processedData.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key] === 'object' && newItem[key] !== null) {
            newItem[key] = JSON.stringify(newItem[key]);
          }
        });
        return newItem;
      });
      // تحديد المفاتيح المطلوبة فقط بناءً على `customize_keys`
      const columnsToExport = customize_keys.map((key: string) => ({
        header: key,
        key: key,
        width: key === 'orderTimeValue' ? 10 : 20 // تقليل عرض orderTimeValue
      }));

      // إنشاء ملف Excel من البيانات المعالجة
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('All Data');

      // إضافة الأعمدة بناءً على المفاتيح في `customize_keys`
      worksheet.columns = columnsToExport;

      // إضافة الصفوف من البيانات المعالجة
      finalData.forEach((item: any) => {
        console.log('item?.userCreationDate', item?.userCreationDate);

        // إنشاء صف فقط مع المفاتيح التي تتوافق مع `customize_keys`
        const rowData = customize_keys.reduce((obj: any, key: string) => {
          if (key === 'userName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;
              console.log('consultName', consultName);
              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.user) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.user === 'string' ? JSON.parse(item.user) : item.user;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantName') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.name || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultNumber') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.phone || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantUid') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.uid || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantISOCode') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryISOCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantCountry') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.countryCode || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'consultantPhoto') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consult) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName =
                typeof item.consult === 'string' ? JSON.parse(item.consult) : item.consult;

              obj[key] = consultName.image || 'N/A';
            } else {
              obj[key] = 'N/A';
            }
          } else if (key === 'userCreationDate') {
            const createdDate = item?.userCreationDate ? new Date(item.userCreationDate) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderDate' || key === 'date') {
            // تحويل utcTime إلى تاريخ والتأكد من أن له قيمة صالحة
            const createdDate = item?.utcTime ? new Date(item.utcTime) : null;
            obj[key] =
              createdDate && !isNaN(createdDate.getTime())
                ? createdDate.toLocaleDateString()
                : 'N/A';
          } else if (key === 'orderId') {
            obj[key] = item?.orderId || 'N/A';
          } else if (key === 'answeredCallNum') {
            obj[key] =
              item?.answeredCallNum || item?.answeredCallNum === 0 ? item?.answeredCallNum : 'N/A';
          } else if (key === 'remainingCallNum') {
            obj[key] =
              item?.remainingCallNum || item?.remainingCallNum === 0
                ? item?.remainingCallNum
                : 'N/A';
          } else {
            obj[key] = item[key] || 'N/A'; // إضافة القيم المطلوبة فقط
          }
          return obj;
        }, {});
        worksheet.addRow(rowData);
      });

      // إرسال الملف كـ Excel
      const filename = new Date().getTime() + '_all_data.xlsx';
      await workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/orders/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  return (
    <div className="h-full">
      <Table
        loading={isLoading}
        searchAble={false}
        isEmpty={data.length === 0}
        count={count}
        customizeAble={true}
        title={title}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px]  overflow-hidden"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '!text-[#7b6c96] border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customize_keys.some((key) => key === field)}
                                onChange={ChangeCustomizeKey}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                {useIntl().formatMessage({
                                  id: field,
                                  defaultMessage: field
                                })}
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <FilterFields
                        onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                        filters={filters}
                        onReset={() => ChangeFilter([...initialQueries], 'queries')}
                        dashColor={dashColor}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All Orders', allOrdersMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allOrdersMessage}
                      defaultMessage={allOrdersMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                onClick={(e) => {
                  const selection = window.getSelection();
                }}
                className="cursor-pointer hover:bg-[#EAE0EB]"
                style={{ backgroundColor: getRowColor(index) }}
              >
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {index + 1}
                </td>
                {isIncluded('orderId') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.orderId || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantUid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.uid || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div>
                      <img
                        src={item?.consult?.image || '/images/placeholder.png'}
                        // onError={(e) => (e.target.src = '/images/placeholder.png')}
                        alt={item?.consult?.name}
                        className="w-8 h-8 rounded-full object-cover mx-auto"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}
                {isIncluded('consultantName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('consultNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantCountry') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.countryCode || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantISOCode') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.countryISOCode || 'N/A'}
                  </td>
                )}

                {isIncluded('userUid') && <td>{item?.user?.uid || 'N/A'}</td>}
                {isIncluded('userPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div>
                      <img
                        src={item?.user?.image || '/images/placeholder.png'}
                        // onError={(e) => (e.target.src = '/images/placeholder.png')}
                        alt={item?.user?.name}
                        className="w-8 h-8 rounded-full object-cover mx-auto"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}
                {isIncluded('userName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('userNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('userCountry') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.countryCode || 'N/A'}
                  </td>
                )}
                {isIncluded('userISOCode') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.countryISOCode || 'N/A'}
                  </td>
                )}

                <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch rounded-[6px]">
                  <p
                    className="flex items-center justify-center"
                    style={{
                      height: '30px',
                      border:
                        item?.orderStatus === 'cancel'
                          ? 'solid 1px #e84a4d'
                          : item?.orderStatus === 'completed'
                          ? 'solid 1px #28c794'
                          : item?.orderStatus === 'closed'
                          ? `solid 1px ${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                          : 'solid 1px #1e1e1e',
                      backgroundColor:
                        item?.orderStatus === 'cancel'
                          ? '#ffe2e2'
                          : item?.orderStatus === 'completed'
                          ? '#ebfeef'
                          : item?.orderStatus === 'closed'
                          ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                          : '',
                      color:
                        item?.orderStatus === 'cancel'
                          ? ' #d91c1f'
                          : item?.orderStatus === 'completed'
                          ? '#228176'
                          : item?.orderStatus === 'closed'
                          ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                          : '#1e1e1e'
                    }}
                  >
                    {item?.orderStatus || 'N/A'}
                  </p>
                </td>

                {isIncluded('price') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.price || 0) || 'N/A'}
                  </td>
                )}
                {isIncluded('callPrice') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.callPrice || 0) || 'N/A'}
                  </td>
                )}

                {isIncluded('payWith') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.payWith || 'N/A'}
                  </td>
                )}
                {isIncluded('platform') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.platform || 'N/A'}
                  </td>
                )}
                {isIncluded('consultType') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consultType || 'N/A'}
                  </td>
                )}
                {isIncluded('promoCodeId') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.promoCodeId || 'N/A'}
                  </td>
                )}
                {isIncluded('packageCallNum') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.packageCallNum || 'N/A'}
                  </td>
                )}
                {isIncluded('packageId') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.packageId || 'N/A'}
                  </td>
                )}
                {isIncluded('remainingCallNum') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.remainingCallNum || item?.remainingCallNum === 0
                      ? item?.remainingCallNum
                      : 'N/A'}
                  </td>
                )}
                {isIncluded('answeredCallNum') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.answeredCallNum || item?.answeredCallNum === 0
                      ? item?.answeredCallNum
                      : 'N/A'}
                  </td>
                )}

                {isIncluded('date') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <SharedTime date={new Date(item?.utcTime)} />
                  </td>
                )}
                {isIncluded('userCreationDate') && (
                  <th>
                    <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                      <SharedTime date={new Date(item?.userCreationDate)} />
                    </td>
                  </th>
                )}
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: dashColor
              }}
            >
              #
            </th>
            {isIncluded('orderId') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="orderId"
                  text={useIntl().formatMessage({
                    id: 'orderId',
                    defaultMessage: 'order ID'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}

            {isIncluded('consultantUid') && (
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantUid"
                  text={useIntl().formatMessage({
                    id: 'consultantUid',
                    defaultMessage: 'consultant id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantPhoto"
                  text={useIntl().formatMessage({
                    id: 'TeacherPhoto',
                    defaultMessage: 'Teacher photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.name"
                  text={useIntl().formatMessage({
                    id: 'TeacherName',
                    defaultMessage: 'Teacher Name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('consultNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.number"
                  text={useIntl().formatMessage({
                    id: 'consultNumber',
                    defaultMessage: 'consultant number'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantCountry') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.countryCode"
                  text={useIntl().formatMessage({
                    id: 'consultantCountry',
                    defaultMessage: 'consultant Country'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantISOCode') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.countryISOCode"
                  text={useIntl().formatMessage({
                    id: 'consultantISOCode',
                    defaultMessage: 'consultant ISOCode'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userUid') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userUid"
                  text={useIntl().formatMessage({
                    id: 'userUid',
                    defaultMessage: 'user id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userPhoto"
                  text={useIntl().formatMessage({
                    id: 'userPhoto',
                    defaultMessage: 'user photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.name"
                  text={useIntl().formatMessage({
                    id: 'userName',
                    defaultMessage: 'user name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userNumber"
                  text={useIntl().formatMessage({
                    id: 'userNumber',
                    defaultMessage: 'user number'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userCountry') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.countryCode"
                  text={useIntl().formatMessage({
                    id: 'userCountry',
                    defaultMessage: 'user country'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userISOCode') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.countryISOCode"
                  text={useIntl().formatMessage({
                    id: 'userISOCode',
                    defaultMessage: 'user ISOCode'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('orderStatus') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="orderStatus"
                  text={useIntl().formatMessage({
                    id: 'orderStatus',
                    defaultMessage: 'order status'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('price') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="price"
                  text={useIntl().formatMessage({
                    id: 'price',
                    defaultMessage: 'price'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('callPrice') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="callPrice"
                  text={useIntl().formatMessage({
                    id: 'callPrice',
                    defaultMessage: 'call Price'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}

            {isIncluded('payWith') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="payWith"
                  text={useIntl().formatMessage({
                    id: 'paymentmethos',
                    defaultMessage: 'payment methos'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('platform') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="platform"
                  text={useIntl().formatMessage({
                    id: 'platform',
                    defaultMessage: 'platform'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('consultType') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultType"
                  text={useIntl().formatMessage({
                    id: 'consultType',
                    defaultMessage: 'consult Type'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('promoCodeId') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="promoCodeId"
                  text={useIntl().formatMessage({
                    id: 'promoCodeId',
                    defaultMessage: 'promocode id'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('packageCallNum') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="packageCallNum"
                  text={useIntl().formatMessage({
                    id: 'packageCallNum',
                    defaultMessage: 'package call number'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('packageId') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="packageId"
                  text={useIntl().formatMessage({
                    id: 'packageId',
                    defaultMessage: 'package Id'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('remainingCallNum') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="remainingCallNum"
                  text={useIntl().formatMessage({
                    id: 'remainingCallNum',
                    defaultMessage: 'remaining call number'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('answeredCallNum') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="answeredCallNum"
                  text={useIntl().formatMessage({
                    id: 'answeredCallNum',
                    defaultMessage: 'answered call number'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}

            {isIncluded('date') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="orderTimeValue"
                  text={useIntl().formatMessage({
                    id: 'orderTimeValue',
                    defaultMessage: 'order time value'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userCreationDate') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userCreationDate"
                  text={useIntl().formatMessage({
                    id: 'userCreationDate',
                    defaultMessage: 'user creation date'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
          </>
        )}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        buttonExport={() => ExportXlsx(data, customize_keys)}
        buttonExportAll={() => ExportAllDataXlsx()}
        pagination={{ ...pagination, search_key: filters.search }}
        onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
        onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
        }}
      />
    </div>
  );
};

function ReviewCard({ review }) {
  // Format the date from review time assuming it's a timestamp in milliseconds
  const reviewDate = new Date(review?.reviewTime?.seconds * 1000).toLocaleDateString('en-US');

  return (
    <div className="flex justify-center max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="w-1/5 p-2 m-2">
        <img
          className="rounded-full "
          width={100}
          height={100}
          src={review?.image ? review.image : '/images/placeholder.png'}
        />
      </div>
      <div className="p-8 w-4/5">
        <div className="flex items-center mb-4 justify-between">
          <h1>{review?.name}</h1>
          <div className="flex items-center space-x-1">
            <ReactStars
              count={5}
              value={review.rating}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              edit={false}
            />
            <span className="text-sm text-gray-500">({review?.rating.toFixed(1)})</span>
          </div>
        </div>
        <p className="text-gray-700 text-base text-right">{review?.review}</p>
      </div>
    </div>
  );
}
function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor
}: any) {
  const dispatch = useDispatch();
  const periods = usePeriods();
  const [filters, setFilters] = useState<any>(values.queries || []);
  console.log('Filters from filterfields', filters);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  const handleOnClearClick = () => {
    onReset();
    setFilters([]);
    setSelectedFields([]);
  };

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'price':
            // If "price" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;

          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'orderTimeValue', '==', from + '-' + to);

            break;
          default:
            result = findQuery(key, '==', value);

            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }
  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }
  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);
  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        // setIsModalOpen(false);
        onSubmit(query);
        // handleClose(false);
        // console.log('oooo');
      }}
    >
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="price"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('price')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="price-amount"
          >
            {useIntl().formatMessage({
              id: 'price',
              defaultMessage: 'price'
            })}
          </label>
          <input
            type="number"
            className="form-input"
            name="price-amount"
            id="price-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('price')}
            defaultValue={findValue('price')}
            disabled={!selectedFields.includes('price')}
            readOnly={!selectedFields.includes('price')}
          />
        </div>
      </div>

      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="orderStatus"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('orderStatus')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="order-status"
          >
            {useIntl().formatMessage({
              id: 'orderStatus',
              defaultMessage: 'order status'
            })}
          </label>
          <select
            name="order-status"
            id="order-status"
            className="form-select"
            onChange={ChangeFilters('orderStatus')}
            defaultValue={findValue('orderStatus') || ''}
            disabled={!selectedFields.includes('orderStatus')}
          >
            <optgroup label="Status">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'status',
                  defaultMessage: 'status'
                })}
              </option>

              <option value="open">
                {useIntl().formatMessage({
                  id: 'open',
                  defaultMessage: 'open'
                })}
              </option>
              <option value="cancel">
                {useIntl().formatMessage({
                  id: 'cancel',
                  defaultMessage: 'cancel'
                })}
              </option>
              <option value="closed">
                {useIntl().formatMessage({
                  id: 'closed',
                  defaultMessage: 'closed'
                })}
              </option>
              <option value="completed">
                {useIntl().formatMessage({
                  id: 'completed',
                  defaultMessage: 'completed'
                })}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="date"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('date')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="date"
          >
            {useIntl().formatMessage({
              id: 'date',
              defaultMessage: 'date'
            })}
          </label>
          <select
            name="date"
            id="date"
            className="form-select"
            onChange={ChangeFilters('date')}
            defaultValue={findValue('date') || ''}
            disabled={!selectedFields.includes('date')}
          >
            <optgroup label="Periods">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'selectPeriod',
                  defaultMessage: 'select period'
                })}
              </option>

              {usePeriods().map((period: any, index: number) => (
                <option value={period.value}>{period.label}</option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      <div className="flex gap-2 flex-wrap justify-between">
        <button
          className="btn-with-icon "
          style={{
            backgroundColor: dashColor
          }}
          type="submit"
        >
          <span>
            {' '}
            {useIntl().formatMessage({
              id: 'ApplyFilter',
              defaultMessage: 'Apply Filter'
            })}
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={() => {
            onReset();
            setFilters([]);
            setSelectedFields([]);
          }}
        >
          <span>
            {' '}
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
      </div>
    </form>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
export default consultantDetailsPage;

